export default {
  isGameActive: true,
  errorMessage: '',
  shouldContinueInit: true,
  localGameConfig: {},
  config: {},
  eventTime: 0,
  eventDisplayId: '-',
  eventName: '',
  betInputData: {
    value: {},
    future: 1,
  },
  selectedBet: {},
  additionalInputs: [],
  betslipBets: [],
  betslipFooterValues: {},
  activeNotifications: [],
  connectionActive: true,
  bettingDisabled: false,
  countdownInterval: null,
  countdownWorker: null,
  quickBetslipModeActive: false,
};
