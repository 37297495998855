export default {
  // id: 1,
  gameColor: '#209bde',
  futureBet: false,
  systemBet: false,
  additionalBetslipConfig: true,
  betslipConfiguration: {},
  adapters: [
    {
      name: 'GcmAdapter',
      type: 'Product',
      channels: [],
    },
  ],
  bettingType: 'draw',
  ticketType: 'ngsTicket',
  bettingInputs: [
    {
      content: 'bettingInput', // if we've type of component (like input, select, text display...)
      enabled: true, // from config/bets value
      label: '',
      betId: 0, // from config/bets ID
      clientId: 1,
      betType: 'normal', // standard, bet type
      betDisplayName: 'standard', // displayName, from config/bets name
      shortcut: '+',
      inputType: 'text',
      inputFields: 6,
      additionalInputs: ['future'],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 48,
        },
        {
          type: 'pattern',
          expression: /^[1-9][0-9]?$/, // only numbers, must be integer
        },
      ],
      betValidations: [
        {
          type: 'unique',
        },
        {
          type: 'length',
          max: 6,
          min: 6,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput', // if we've type of component (like input, select, text display...)
      enabled: true, // from config/bets value
      label: '',
      betId: 0, // from config/bets ID
      clientId: 2, // CLIENT INDENTIFIER DIFFERENT FOR EACH BET INSIDE A GAME
      betType: 'normal', // standard, bet type
      betDisplayName: 'random', // displayName, from config/bets name
      shortcut: 'r',
      inputType: 'text',
      inputFields: 6,
      additionalInputs: ['future'],
      outcomeShortcuts: [
        {
          outcome: null,
          shortcut: 'r',
          action: {
            name: 'random',
            value: 6,
          },
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 48,
        },
        {
          type: 'pattern',
          expression: /^[1-9][0-9]?$/, // only numbers, must be integer
        },
      ],
      betValidations: [
        {
          type: 'unique',
        },
        {
          type: 'length',
          max: 6,
          min: 6,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput', // if we've type of component (like input, select, text display...)
      enabled: true, // from config/bets value
      label: '',
      betId: 0,
      clientId: 3,
      betType: 'normal', // standard, bet type
      betSubType: 'color', // basicaly normal bet type with 6 numbers with same color, but need something to diff it from other betTypes
      betDisplayName: 'color', // displayName, from config/bets name
      outcomes: ['red', 'green', 'blue', 'purple', 'brown', 'yellow', 'orange', 'black'],
      shortcut: 'c',
      inputType: 'text',
      inputFields: 1,
      additionalInputs: ['future'],
      bettingPreview: {
        betDisplayName: 'color',
        betType: 'normal',
        isColorBet: true,
        outcomes: [
          { outcome: 1, color: 'red', shortcut: '1' },
          { outcome: 2, color: 'green', shortcut: '2' },
          { outcome: 3, color: 'blue', shortcut: '3' },
          { outcome: 4, color: 'purple', shortcut: '4' },
          { outcome: 5, color: 'brown', shortcut: '5' },
          { outcome: 6, color: 'yellow', shortcut: '6' },
          { outcome: 7, color: 'orange', shortcut: '7' },
          { outcome: 8, color: 'black', shortcut: '8' },
        ],
      },
      outcomeShortcuts: [
        {
          outcome: 1,
          shortcut: '1',
          action: {
            name: 'color',
            value: 1,
          },
        },
        {
          outcome: 2,
          shortcut: '2',
          action: {
            name: 'color',
            value: 2,
          },
        },
        {
          outcome: 3,
          shortcut: '3',
          action: {
            name: 'color',
            value: 3,
          },
        },
        {
          outcome: 4,
          shortcut: '4',
          action: {
            name: 'color',
            value: 4,
          },
        },
        {
          outcome: 5,
          shortcut: '5',
          action: {
            name: 'color',
            value: 5,
          },
        },
        {
          outcome: 6,
          shortcut: '6',
          action: {
            name: 'color',
            value: 6,
          },
        },
        {
          outcome: 7,
          shortcut: '7',
          action: {
            name: 'color',
            value: 7,
          },
        },
        {
          outcome: 8,
          shortcut: '8',
          action: {
            name: 'color',
            value: 8,
          },
        },
        {
          outcome: 9,
          shortcut: '9',
          action: {
            name: 'color',
            value: 9,
          },
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 1,
        },
        {
          type: 'range',
          min: 1,
          max: 9,
        },
        {
          type: 'pattern',
          expression: /^[1-9]$/, // only numbers, must be integer
        },
      ],
      betValidations: [
        {
          type: 'length',
          min: 1,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput', // if we've type of component (like input, select, text display...)
      enabled: true, // from config/bets value
      label: '',
      betId: 1, // from config/bets ID // Each system has separate but we have only one system input
      clientId: 4,
      betType: 'system', // standard, bet type
      betDisplayName: 'system', // displayName, from config/bets name
      shortcut: 's',
      inputType: 'text',
      inputFields: 10,
      additionalInputs: ['future'],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 48,
        },
        {
          type: 'pattern',
          expression: /^[1-9][0-9]?$/,
        },
      ],
      betValidations: [
        {
          type: 'unique',
        },
        {
          type: 'length',
          max: 10,
          min: 6,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput', // if we've type of component (like input, select, text display...)
      enabled: true,
      label: '',
      clientId: 5, // TODO
      betType: 'system', // standard, bet type
      betDisplayName: 'random_system', // displayName, from config/bets name
      shortcut: 'w',
      inputType: 'text',
      inputFields: 10,
      additionalInputs: ['future'],
      bettingPreview: {
        betDisplayName: 'random_system',
        betType: 'system',
        outcomes: [
          { outcome: 'system', shortcut: '1' },
          { outcome: 'system', shortcut: '2' },
          { outcome: 'system', shortcut: '3' },
          { outcome: 'system', shortcut: '4' },
        ], // Add any additional info if required
      },
      outcomeShortcuts: [
        // Shortcuts that have special action if pressed on this bet
        {
          outcome: 1,
          shortcut: '1',
          action: {
            name: 'random',
            value: 7,
          },
        },
        {
          outcome: 2,
          shortcut: '2',
          action: {
            name: 'random',
            value: 8,
          },
        },
        {
          outcome: 3,
          shortcut: '3',
          action: {
            name: 'random',
            value: 9,
          },
        },
        {
          outcome: 4,
          shortcut: '4',
          action: {
            name: 'random',
            value: 10,
          },
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 48,
        },
        {
          type: 'pattern',
          expression: /^[^0]$|^[1-9]$|^[1-3][0-9]$|^4[0-8]$/, // only numbers, must be integer
        },
      ],
      betValidations: [
        {
          type: 'unique',
        },
        {
          type: 'length',
          max: 10,
          min: 6,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput', // if we've type of component (like input, select, text display...)
      enabled: true, // from config/bets value
      label: '',
      betId: 5, // from config/bets ID
      clientId: 6,
      betType: 'special', // standard, bet type
      betDisplayName: 'first_ball_color', // displayName, from config/bets name
      outcomes: ['red', 'green', 'blue', 'purple', 'brown', 'yellow', 'orange', 'black'],
      shortcut: 'k',
      inputType: 'text',
      inputFields: 4,
      additionalInputs: ['future'],
      bettingPreview: {
        betDisplayName: 'first_ball_color',
        betType: 'special',
        isColorBet: true,
        outcomes: [
          { outcome: 1, color: 'red', shortcut: '1' },
          { outcome: 2, color: 'green', shortcut: '2' },
          { outcome: 3, color: 'blue', shortcut: '3' },
          { outcome: 4, color: 'purple', shortcut: '4' },
          { outcome: 5, color: 'brown', shortcut: '5' },
          { outcome: 6, color: 'yellow', shortcut: '6' },
          { outcome: 7, color: 'orange', shortcut: '7' },
          { outcome: 8, color: 'black', shortcut: '8' },
        ],
      },
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 1,
        },
        {
          type: 'range',
          min: 1,
          max: 8,
        },
        // maybe add 'allowed lengths' [2, 4],
        {
          type: 'pattern',
          expression: /^[1-8]$/, // only numbers, must be integer
        },
      ],
      betValidations: [
        {
          type: 'unique',
        },
        {
          type: 'length',
          min: 1,
        },
        {
          type: 'allowedLengths',
          value: [1, 2, 4],
          max: 4,
          message: 'general_first_ball_color_error',
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput', // if we've type of component (like input, select, text display...)
      enabled: true, // from config/bets value
      label: '',
      betId: 6, // from config/bets ID
      clientId: 7,
      betType: 'special', // standard, bet type
      betDisplayName: 'pre_numbers_sum', // displayName, from config/bets name
      limit: 122.5,
      outcomes: ['under', 'over'],
      shortcut: 'p',
      inputType: 'text',
      inputFields: 1,
      additionalInputs: ['future'],
      outcomeShortcuts: [
        // Shortcuts that have special action if pressed on this bet
        {
          outcome: 1,
          shortcut: '1',
        },
        {
          outcome: 2,
          shortcut: '2',
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 1,
        },
        {
          type: 'range',
          min: 1,
          max: 2,
        },
        {
          type: 'pattern',
          expression: /^[1,2]$/, // only numbers, must be integer
        },
      ],
      betValidations: [
        {
          type: 'length',
          min: 1,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput', // if we've type of component (like input, select, text display...)
      enabled: true, // from config/bets value
      label: '',
      betId: 7, // from config/bets ID
      clientId: 8,
      betType: 'special', // standard, bet type
      betDisplayName: 'first_number_even_odd', // displayName, from config/bets name
      outcomes: ['even', 'odd'],
      shortcut: 'o',
      inputType: 'text',
      inputFields: 1,
      additionalInputs: ['future'],
      outcomeShortcuts: [
        // Shortcuts that have special action if pressed on this bet
        {
          outcome: 1,
          shortcut: '1',
        },
        {
          outcome: 2,
          shortcut: '2',
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 1,
        },
        {
          type: 'range',
          min: 1,
          max: 2,
        },
        {
          type: 'pattern',
          expression: /^[1,2]$/, // only numbers, must be integer
        },
      ],
      betValidations: [
        {
          type: 'length',
          min: 1,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput', // if we've type of component (like input, select, text display...)
      enabled: true, // from config/bets value
      label: '',
      betId: 8, // from config/bets ID
      clientId: 9,
      betType: 'special', // standard, bet type
      betDisplayName: 'first_number_under_over', // displayName, from config/bets name
      limit: 24.5,
      outcomes: ['under', 'over'],
      shortcut: 'h',
      inputType: 'text',
      inputFields: 1,
      additionalInputs: ['future'],
      outcomeShortcuts: [
        // Shortcuts that have special action if pressed on this bet
        {
          outcome: 1,
          shortcut: '1',
        },
        {
          outcome: 2,
          shortcut: '2',
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 1,
        },
        {
          type: 'range',
          min: 1,
          max: 2,
        },
        {
          type: 'pattern',
          expression: /^[1,2]$/, // only numbers, must be integer
        },
      ],
      betValidations: [
        {
          type: 'length',
          min: 1,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput', // if we've type of component (like input, select, text display...)
      enabled: true, // from config/bets value
      label: '',
      betId: 10, // from config/bets ID
      clientId: 10,
      betType: 'special', // standard, bet type
      betDisplayName: 'pre_balls_number', // displayName, from config/bets name
      shortcut: 'g',
      inputType: 'text',
      inputFields: 1,
      additionalInputs: ['future'],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 48,
        },
        {
          type: 'pattern',
          expression: /^[1-9][0-9]?$/, // only numbers, must be integer
        },
      ],
      betValidations: [
        {
          type: 'length',
          min: 1,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput', // if we've type of component (like input, select, text display...)
      enabled: true, // from config/bets value
      label: '',
      betId: 11, // from config/bets ID
      clientId: 11,
      betType: 'special', // standard, bet type
      betDisplayName: 'pre_numbers_even_odd', // displayName, from config/bets name
      outcomes: ['even', 'odd'],
      shortcut: 'd',
      inputType: 'text',
      inputFields: 1,
      additionalInputs: ['future'],
      outcomeShortcuts: [
        // Shortcuts that have special action if pressed on this bet
        {
          outcome: 1,
          shortcut: '1',
        },
        {
          outcome: 2,
          shortcut: '2',
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 1,
        },
        {
          type: 'range',
          min: 1,
          max: 2,
        },
        {
          type: 'pattern',
          expression: /^[1,2]$/, // only numbers, must be integer
        },
      ],
      betValidations: [
        {
          type: 'length',
          min: 1,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput', // if we've type of component (like input, select, text display...)
      enabled: false, // from config/bets value
      label: '',
      clientId: 12,
      betDisplayName: 'macro_shortcuts', // displayName, from config/bets name
      shortcut: 'l',
      inputType: 'text',
      inputFields: 1,
      additionalInputs: [],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'pattern',
          expression: /^(?:[1-9]|\d{2}|\d{3})$/, // only numbers, must be integer
        },
      ],
      betValidations: [],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
  ],
  additionalBettingInputs: [
    {
      content: 'bettingInput', // if we've type of component (like input, select, text display...)
      label: 'future_bet',
      clientId: 'future',
      enabled: true,
      betType: 'future', // standard, bet type
      betDisplayName: 'future_bet', // displayName, from config/bets name
      shortcut: 'f',
      inputType: 'text',
      inputFields: 1,
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 10,
        },
        {
          type: 'pattern',
          expression: /^[1-9]$|^10$/, // only numbers, must be integer
        },
      ],
      betValidations: [],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
        {
          name: 'blur',
          handler: {},
        },
      ],
    },
  ],
  shortcuts: {
    '+': {
      id: 1, // bettingInput.clientId
      action: 'ticket',
      active: true,
      name: 'new_ticket',
      shortcut: '+',
      order: 1,
    },
    '/': {
      action: 'resetTicket',
      active: true,
      name: 'reset_ticket',
      shortcut: '/',
      order: 2,
    },
    q: {
      action: 'results',
      active: true,
      name: 'results',
      shortcut: 'q',
      order: 3,
    },
    'ctrl + m': {
      action: 'focusFirstBet',
      active: true,
      name: 'focus_first_bet',
      shortcut: 'ctrl + m',
      order: 4,
    },
    'ctrl + s': {
      action: 'editStakePerBet',
      active: true,
      name: 'edit_stake_per_bet',
      shortcut: 'ctrl + s',
      order: 5,
    },
    'ctrl + f': {
      action: 'editFuturePerTicket',
      active: true,
      name: 'edit_future_per_ticket',
      shortcut: 'ctrl + f',
      order: 6,
    },
    'ctrl + b': {
      action: 'focusFirstInput',
      active: true,
      name: 'focus_first_input',
      shortcut: 'ctrl + b',
      order: 7,
    },
    'ctrl + e': {
      action: 'toggleEditMode',
      active: false,
      name: 'toggle_delete_bet_mode',
      shortcut: 'ctrl + e',
      order: 8,
    },
    delete: {
      action: 'deleteBet',
      active: false,
      name: 'delete_bet',
      shortcut: 'delete',
      order: 9,
    },
    r: {
      id: 2,
      action: 'random',
      active: true,
      name: 'random',
      shortcut: 'r',
      order: 10,
    },
    c: {
      id: 3,
      action: 'color',
      active: true,
      name: 'color',
      shortcut: 'c',
      order: 11,
    },
    s: {
      id: 4,
      action: 'system',
      active: true,
      name: 'system',
      shortcut: 's',
      order: 12,
    },
    w: {
      id: 5,
      action: 'randomSystem',
      active: true,
      name: 'random_system',
      shortcut: 'w',
      order: 13,
    },
    k: {
      id: 6,
      action: 'firstBallColor',
      active: true,
      name: 'first_ball_color',
      shortcut: 'k',
      order: 14,
    },
    p: {
      id: 7,
      action: 'preNumbersSum',
      active: true,
      name: 'pre_numbers_sum',
      shortcut: 'p',
      order: 15,
    },
    o: {
      id: 8,
      action: 'firstNumberEvenOdd',
      active: true,
      name: 'first_number_even_odd',
      shortcut: 'o',
      order: 16,
    },
    h: {
      id: 9,
      action: 'firstNumberUnderOver',
      active: true,
      name: 'first_number_under_over',
      shortcut: 'h',
      order: 17,
    },
    g: {
      id: 10,
      action: 'preballsNumber',
      active: true,
      name: 'pre_balls_number',
      shortcut: 'g',
      order: 18,
    },
    d: {
      id: 11,
      action: 'preNumbersEvenOdd',
      active: true,
      name: 'pre_numbers_even_odd',
      shortcut: 'd',
      order: 19,
    },
    l: {
      id: 12,
      action: 'macroShortcuts',
      active: false,
      name: 'macro_shortcuts',
      shortcut: 'l',
      order: 20,
    },
    f: {
      id: 'future',
      action: 'future',
      active: true,
      name: 'future_bet',
      shortcut: 'f',
      order: 21,
    },
    m: {
      action: 'quickBetslipMode',
      active: false,
      name: 'enable_quick_betslip_mode',
      shortcut: 'm',
      order: 22,
    },
  },
  availableTicketActions: [
    {
      status: 'accepted',
      action: ['rebet', 'check', 'copy'],
    },
    {
      status: 'canceled',
      action: ['rebet'],
    },
    {
      status: 'paidout',
      action: ['rebet'],
    },
    {
      status: 'pending',
      action: ['checkStatus'],
    },
    {
      status: 'unknown',
      action: ['checkStatus'],
    },
    {
      status: 'failed',
      action: ['checkStatus'],
    },
    // 7Shop sets this local status, it should be paidout. This will be fixed in the future
    {
      status: 'payedout',
      action: ['rebet'],
    },
  ],
  actionTypes: {
    add: 'TicketPayIn',
    payout: 'TicketPayout',
    cancel: 'TicketCancel',
    copy: 'TicketCopy',
  },
  printLayoutType: {
    add: 'ticketLuckySix',
    payout: 'ticketLuckySixPayout',
    cancel: 'ticketCancel',
    results: 'resultsLuckySix',
  },
};
