import { eventBus, betslipUtility, errorTicketHandler, ticketCheck, busService } from '../../../utility';
import types from './mutationTypes';
import { isEqual, isNil, cloneDeep } from 'lodash';
import { ticketAPI, upcomingEventsAPI } from '@/api';

export default {
  /**
   * @param {Object} config
   *
   * If any additional config per game is required it can be set here.
   */
  setAdditionalGameConfig({ dispatch }, config = {}) {
    dispatch('updateOddsRules', config.rules);
    dispatch('getUpcomingEvents');
  },
  // game specific messages and gravity gateway messages per game
  async handleBusMessages({ state, getters, dispatch, commit, rootState }, payload) {
    // console.log('handleBusMessages CRASH CASH ::: ', payload);
    const { eventName, data } = payload;

    switch (eventName) {
      // WebSocket game events
      case 'connection':
        await dispatch(
          'setBusLoaded',
          {
            game: state.name,
            busLoaded: true,
          },
          { root: true },
        );
        break;
      case 'upcomingEvents':
        dispatch('setUpcomingEvents', data);
        break;
      case 'invalid':
      case 'stop':
      case 'StopGame':
        dispatch('setConnectionStatus', false);
        break;
      case 'SetIdle':
        // Handle countdown
        dispatch('handleSetIdle', payload);
        break;
      case 'SetStep':
        dispatch('handleEvents', payload);
        break;
      case 'StartEvent':
        dispatch('removeFirstEvent');
        dispatch(
          'updateBetEvent',
          { game: state.name, roundNumber: parseInt(+data.displayEventId + 1) },
          { root: true },
        );
        dispatch('handleEvents', payload);
        break;
      case 'FinishEvent':
        dispatch('handleEvents', payload);
        break;
      case 'TicketUpdate': {
        if (!payload.data.error) {
          ticketCheck.stopTicketChecker(betslipUtility.prepareSevenTicketData(payload.data.ticket));
          const { data } = payload;
          // TODO: Refactor for serbian taxId which will be on ticket level
          if (data.ticket.bets[0].additionalDetails?.taxId) await dispatch('mapBetGuids', data.ticket);

          if (!getters.directPayin) {
            // Send message over GGateway event 'Tickets.Update' to update state of ticket on platform
            const ticketData = {
              action: state.ticketActionMap[data.action] || data.action, // available values: Add, Cancel, Payout
              ticket: betslipUtility.prepareSevenTicketData(data.ticket),
            };
            eventBus.$emit('ticketUpdate', ticketData);
            // CHECK IF THE TICKET SHOULD BE PRINTED
            if (
              rootState.ticketChecksInProgress.some(
                (ticket) =>
                  ticket.id === payload.data.ticket.codes[0].id || ticket.requestUuid === payload.data.ticket.requestId,
              )
            ) {
              commit('REMOVE_TICKET_CHECK_IN_PROGRESS', payload.data.ticket.codes[0].id, { root: true });
            } else if (
              rootState.printedTicketsByTicketCheck.some((ticket) => ticket.id === payload.data.ticket.codes[0].id)
            ) {
              commit('REMOVE_PRINTED_TICKET_BY_TICKET_CHECK', payload.data.ticket.codes[0].id, { root: true });
            } else {
              eventBus.$emit('printTemplate', ticketData);
            }
          }
        } else {
          errorTicketHandler(payload.data);
          ticketCheck.stopTicketChecker({ requestId: payload.data.requestId });
        }
        break;
      }
      case 'ConnectionReconnect':
        dispatch('setReconnectState', true);
        eventBus.$emit('reInitSocketConnection', payload.subscriptionId);
        break;
      case 'ConnectionLost':
      case 'ConnectionSuccess':
        dispatch('setReconnectState', false);
        dispatch('setSocketReInitInProgress', false);
        break;
      default:
    }
  },
  getUpcomingEvents({ dispatch }) {
    upcomingEventsAPI
      .getUpcomingEvents()
      .then(async (upcomingEvents) => {
        await dispatch('setUpcomingEvents', upcomingEvents);
      })
      .catch((error) => {
        console.error('Error getting upcoming events', error);
      });
  },
  setUpcomingEvents({ commit }, upcomingEvents) {
    commit(types.SET_UPCOMING_EVENTS, upcomingEvents);
  },
  removeFirstEvent({ commit, getters }) {
    const { upcomingEvents } = getters;
    commit(types.SET_UPCOMING_EVENTS, upcomingEvents.slice(1));
  },
  handleSetIdle({ state, dispatch }, payload) {
    const { time, displayEventId, isBettingDisabled } = payload.data;
    // convert a time in milliseconds to seconds
    let eventTime = time >= 1000 ? parseInt(time / 1000, 10) : time;
    dispatch('setEventTime', eventTime);
    dispatch('setEventDisplayId', displayEventId);
    if (!isEqual(displayEventId, state.eventDisplayId)) {
      dispatch('updateBetEvent', { game: state.name, roundNumber: +displayEventId }, { root: true });
    }
    dispatch('setEventName', payload.event);
    dispatch('setBettingDisabled', isBettingDisabled);
  },
  handleEvents({ dispatch }, payload) {
    const { displayEventId } = payload.data;

    dispatch('setBettingDisabled', false);
    dispatch('setEventDisplayId', displayEventId);
    dispatch('setEventName', payload.event);
  },
  updateOddsRules({ commit }, rules) {
    if (!rules.maxPossibleMultiplier) return;
    commit(types.UPDATE_ODDS_RULES, rules.maxPossibleMultiplier.value);
  },
  handleGameShortcuts({ state, dispatch, getters }, payload) {
    const { bettingInputs, additionalBettingInputs } = state.localGameConfig;
    const { quickBetslipModeAllowed, quickBetslipModeShortcut } = getters;
    // TOGGLE QUICK BETSLIP MODE
    if (
      quickBetslipModeAllowed &&
      !payload.ctrlKey &&
      payload.key.toLowerCase() === quickBetslipModeShortcut.shortcut.toLowerCase()
    ) {
      dispatch('toggleQuickBetslipMode');
    }
    // FIND IF A THE GAME HAS A BET WITH THE SHORTCUT THAT IS CALLED
    const selectedBet = bettingInputs.filter(
      (bettingInput) => bettingInput.shortcut === String(payload.key.trim()).toLowerCase() && !payload.ctrlKey,
    );
    if (selectedBet.length) {
      dispatch('setSelectedBet', selectedBet[0]);
      dispatch('clearAdditionalInputs');
      eventBus.$emit('resetInput');
      eventBus.$emit('focusFirstBettingInput');
    }
    // FIND IF THERE ARE ANY ADDITIONAL INPUTS THAT SHOULD BE SHOWN ON SHORTCUT ( Future, system )
    const additionalInput = additionalBettingInputs.filter(
      (bettingInput) =>
        bettingInput.shortcut === String(payload.key).toLowerCase() && !payload.ctrlKey && bettingInput.enabled,
    );
    if (additionalInput.length && !getters.isBettingDisabled) {
      dispatch('setAdditionalInput', additionalInput[0]);
    }
  },
  updateBetslipTicket({ commit }, payload) {
    commit(types.UPDATE_BETSLIP_TICKET, payload.roundNumber);
  },
  updateFuture({ commit }, value) {
    commit(types.UPDATE_FUTURE, value);
  },
  // eslint-disable-next-line no-empty-pattern
  mapBetGuids({}, ticket) {
    ticket.bets = ticket.bets.map((bet) => {
      return { ...bet, guid: bet.additionalDetails.taxId };
    });
  },
  async handleTicketsCheckEvent({ dispatch, getters, rootState }, payload) {
    // TODO: After 7Shop releases the flag for ticket check strategies, it is necessary to make changes.
    // 'seven' strategy for ticket check
    if (payload.data?.ticket?.bets) {
      // MAP BOTH TICKETS
      dispatch('showTicketPreview', { ticket: payload.data?.ticket, ticketVersion: '2' }, { root: true });
      busService.sendMessage('UI.Hide', { name: ['All'] });
    } else {
      // 'slave' (new) strategy for ticket check
      const ticketId = payload.data?.ticket?.id;
      const { ticketType } = getters.localGameConfig;
      const ticketCheckTicket = await ticketAPI
        .checkTicketBarcode(ticketId, ticketType, getters.name)
        .then((response) => response)
        .catch((error) => {
          errorTicketHandler(error);
        });
      // TODO: Temp solution for ticket PIN see above TODO
      // add ticket pin if it exists
      if (isNil(ticketCheckTicket)) return;
      if (ticketCheckTicket.productTicket.bets[0].additionalDetails?.taxId)
        await dispatch('mapBetGuids', ticketCheckTicket.productTicket);

      ticketCheckTicket.productTicket.ticketPin = payload.data?.ticket.ticketPin
        ? payload.data?.ticket.ticketPin
        : null;
      if (ticketCheckTicket.status.value.toLowerCase() === 'won' && rootState.autoPayout) {
        const action = 'Tickets.Payout';
        const ticketData = {
          action,
          data: {
            ticket: betslipUtility.prepareSevenTicketData(ticketCheckTicket.productTicket),
          },
        };
        busService.sendMessageAsync(ticketData.action, ticketData.data);
      } else {
        dispatch('showTicketPreview', { ticket: ticketCheckTicket?.productTicket, ticketVersion: '2' }, { root: true });
        busService.sendMessage('UI.Hide', { name: ['All'] });
      }
    }
    // Checked the ticket and forwards ticket data to product ticket preview (popup)
  },
  rebetTicket({ dispatch }, bets) {
    const betsForRebet = cloneDeep(bets);
    const filteredBets = [];
    betsForRebet.forEach((bet) => {
      bet.numEvents = 1;
      if (!filteredBets.length) {
        filteredBets.push(bet);
        return;
      }
      const lastFilteredBet = filteredBets.at(-1);

      const betSelection = bet.selections[0];
      const lastSelection = lastFilteredBet.selections[0];

      if (
        lastSelection.outcomeId === betSelection.outcomeId &&
        lastSelection.eventDisplayId < betSelection.eventDisplayId
      ) {
        lastFilteredBet.numEvents += 1;
      } else {
        filteredBets.push(bet);
      }
    });
    filteredBets.forEach((bet) => {
      const data = {
        value: { 1: bet?.selections?.[0]?.outcomeId || bet?.outcomeId },
        payment: bet?.payment.real.value,
        future: bet.numEvents,
      };
      const bets = betslipUtility.createBet(data);
      for (let i = 0; i < bets.length; i++) {
        dispatch('gamesBetslip/addBet', bets[i], { root: true });
      }
    });
  },
  setReconnectState({ commit }, value) {
    commit(types.SET_RECONNECT_STATE, value);
  },
  reInitSocketConnection({ getters, dispatch }, game) {
    if (!getters.socketReInitInProgress) {
      busService.stop(game, 'Connection closed due to reconnection attempt');
      busService.init(game);
      dispatch('getUpcomingEvents');
    } else {
      setTimeout(() => {
        dispatch('reInitSocketConnection', game);
      }, 100);
    }
  },
  setSocketReInitInProgress({ commit }, value) {
    commit(types.SET_SOCKET_REINIT_IN_PROGRESS, value);
  },
};
