import store from '../store';
import { each } from 'lodash';
import { capitalizeFirstLetter } from './miscUtility';

const formatFirstBallColor = (bet) => {
  const colors = [];
  let colorsString = '';
  let colorIndex = '';
  const colorIndexArray = [];
  let outcomes = store.getters.localGameConfig.bettingInputs.find((input) => input.betId === 5)?.outcomes;
  each([0, 1, 2, 3, 4, 5, 6, 7], (n) => {
    if (parseInt(bet.value, 10) & (2 ** n)) {
      colorIndex = n === 0 ? 7 : n - 1;
      colorIndexArray.push(colorIndex);
    }
  });
  colorIndexArray.sort((a, b) => a - b);
  for (let i = 0; i < colorIndexArray.length; i += 1) {
    const colorTranslation = capitalizeFirstLetter(store.getters.getTranslation(outcomes[colorIndexArray[i]]));
    colors.push(colorTranslation);
    colorsString = colors.join(' ');
  }
  return colorsString;
};

export { formatFirstBallColor };
