import store from '../store';
/**
 * Function that returns the number of forecast combinations
 * @param {Object} inputs object of inputs that contain input values
 * @returns {validCombinations: Array} Array with all valid combinations;
 */
const forecastCombinations = (inputs) => {
  const localGameConfig = store.getters.getGameGetter({ getter: 'localGameConfig' });
  const selectedBet = localGameConfig.bettingInputs.filter((bettingInput) => bettingInput.clientId === 11)[0];
  const { inputFields, inputRows } = selectedBet;
  const inputsInRow = inputFields / inputRows;
  const firstInputRow = [];
  const secondInputRow = [];
  const validCombinations = [];
  // Loop through the expected keys based on the provided structure
  for (let i = 1; i <= inputFields; i++) {
    const key = i.toString();
    if (Object.prototype.hasOwnProperty.call(inputs, key)) {
      if (i <= inputsInRow) {
        firstInputRow.push(inputs[key]);
      } else {
        secondInputRow.push(inputs[key]);
      }
    }
  }
  firstInputRow.forEach((firstInput) => {
    secondInputRow.forEach((secondInput) => {
      const combination = firstInput + '-' + secondInput;
      if (
        firstInput === '' ||
        secondInput === '' ||
        firstInput === secondInput ||
        validCombinations.some((validCombination) => validCombination === combination)
      )
        return;
      validCombinations.push(combination);
    });
  });
  return validCombinations;
};

/**
 * Function that returns the number of forecast combinations
 * @param {Object} inputs object of inputs that contain input values
 * @returns {validCombinations: Array} Array with all valid combinations;
 */
const reverseForecastCombinations = (inputs) => {
  const firstInputRow = Object.values(inputs).filter((value) => value !== '');
  const secondInputRow = Object.values(inputs).filter((value) => value !== '');
  const validCombinations = [];

  firstInputRow.forEach((firstInput) => {
    secondInputRow.forEach((secondInput) => {
      const combination = firstInput + '-' + secondInput;
      if (
        firstInput === '' ||
        secondInput === '' ||
        firstInput === secondInput ||
        validCombinations.some((validCombination) => validCombination === combination)
      )
        return;
      validCombinations.push(combination);
    });
  });
  return validCombinations;
};
/**
 * Function that returns the number of forecast combinations
 * @param {Object} inputs object of inputs that contain input values
 * @returns {validCombinations: Array} Array with all valid combinations;
 */
const tricastCombinations = (inputs) => {
  const localGameConfig = store.getters.getGameGetter({ getter: 'localGameConfig' });
  const selectedBet = localGameConfig.bettingInputs.filter((bettingInput) => bettingInput.clientId === 12)[0];
  const { inputFields, inputRows } = selectedBet;
  const inputsInRow = inputFields / inputRows;
  // Split input to two arrays
  const firstInputRow = [];
  const secondInputRow = [];
  const thirdInputRow = [];
  const validCombinations = [];
  // Loop through the expected keys based on the provided structure
  for (let i = 1; i <= inputFields; i++) {
    const key = i.toString();
    if (Object.prototype.hasOwnProperty.call(inputs, key)) {
      if (i <= inputsInRow) {
        firstInputRow.push(inputs[key]);
      } else if (i <= inputsInRow * 2) {
        secondInputRow.push(inputs[key]);
      } else {
        thirdInputRow.push(inputs[key]);
      }
    }
  }
  firstInputRow.forEach((firstInput) => {
    secondInputRow.forEach((secondInput) => {
      thirdInputRow.forEach((thirdInput) => {
        const combination = firstInput + '-' + secondInput + '-' + thirdInput;
        if (
          firstInput === '' ||
          secondInput === '' ||
          thirdInput === '' ||
          firstInput === secondInput ||
          firstInput === thirdInput ||
          secondInput === thirdInput ||
          validCombinations.some((validCombination) => validCombination === combination)
        )
          return;
        validCombinations.push(combination);
      });
    });
  });
  return validCombinations;
};
/**
 * Function that returns the number of forecast combinations
 * @param {Object} inputs object of inputs that contain input values
 * @returns {validCombinations: Array} Array with all valid combinations;
 */
const reverseTricastCombinations = (inputs) => {
  const firstInputRow = Object.values(inputs).filter((value) => value !== '');
  const secondInputRow = Object.values(inputs).filter((value) => value !== '');
  const thirdInputRow = Object.values(inputs).filter((value) => value !== '');

  const validCombinations = [];

  firstInputRow.forEach((firstInput) => {
    secondInputRow.forEach((secondInput) => {
      thirdInputRow.forEach((thirdInput) => {
        const combination = firstInput + '-' + secondInput + '-' + thirdInput;
        if (
          firstInput === '' ||
          secondInput === '' ||
          thirdInput === '' ||
          firstInput === secondInput ||
          firstInput === thirdInput ||
          secondInput === thirdInput ||
          validCombinations.some((validCombination) => validCombination === combination)
        )
          return;
        validCombinations.push(combination);
      });
    });
  });
  return validCombinations;
};
const splitForecastCombinations = (combinations) => {
  const firstSet = new Set();
  const secondSet = new Set();

  combinations.forEach((combination) => {
    const [first, second] = combination.split('-');
    firstSet.add(first);
    secondSet.add(second);
  });
  const firstArray = Array.from(firstSet).sort();
  const secondArray = Array.from(secondSet).sort();

  return [firstArray, secondArray];
};
const splitTricastCombinations = (combinations) => {
  const firstSet = new Set();
  const secondSet = new Set();
  const thirdSet = new Set();

  combinations.forEach((combination) => {
    const [first, second, third] = combination.split('-');
    firstSet.add(first);
    secondSet.add(second);
    thirdSet.add(third);
  });
  const firstArray = Array.from(firstSet).sort();
  const secondArray = Array.from(secondSet).sort();
  const thirdArray = Array.from(thirdSet).sort();

  return [firstArray, secondArray, thirdArray];
};
// USED ONLY BY PREMIER
const findOddsRange = (combinations) => {
  const odds = combinations
    .map((combination) => {
      return combination.odd.toFixed(2);
    })
    .sort((a, b) => a - b);
  return `od ${odds[0]} do ${odds[odds.length - 1]}`;
};
export {
  forecastCombinations,
  reverseForecastCombinations,
  tricastCombinations,
  reverseTricastCombinations,
  splitForecastCombinations,
  splitTricastCombinations,
  findOddsRange,
};
