import {
  getTimeDelay,
  eventBus,
  ticketCheck,
  errorTicketHandler,
  busService,
  capitalizeFirstLetter,
  betslipUtility,
  inputHelpers,
} from '../../../utility';
import types from './mutationTypes';
import { ticketAPI } from '@/api';
import { isNil, range } from 'lodash';

export default {
  /**
   * @param {Object} config
   *
   * If any additional config per game is required it can be set here.
   */
  // eslint-disable-next-line no-empty-pattern
  setAdditionalGameConfig({}) {},
  async handleBusMessages({ getters, dispatch, state, commit, rootState }, eventData) {
    // eslint-disable-next-line no-console
    // console.log('handleBusMessages GREYHOUND RACES ::: ', eventData);

    const { eventName, eventId } = eventData;
    const eventTime = eventData.time;
    const sentTime = eventData.sentTime || Date.now();
    let countdownTime;
    const eventType = eventData.type;
    dispatch('setEventDisplayId', eventId);
    switch (eventName) {
      case 'SetState':
        await dispatch(
          'setBusLoaded',
          {
            game: state.name,
            busLoaded: true,
          },
          { root: true },
        );
        dispatch('setEventName', eventType);
        if (eventData.offer?.length) {
          dispatch('setOffers', eventData.offer);
          dispatch('setActiveOffer', getters.offerList[0]);
          dispatch('setEventId', getters.activeOffer.eventId);
          dispatch('setRacerOdds');
        }

        switch (eventType) {
          case 'StartEvent':
          case 'new':
          case 'bettingDisabled':
          case 'StopBetting': {
            const delay = eventData.countdown || eventData.delay;
            countdownTime = getTimeDelay(eventTime, sentTime, delay);
            dispatch('setEventTime', countdownTime);
            dispatch('setCountdownInterval');
            if (eventType === 'bettingDisabled' || eventType === 'StopBetting') {
              dispatch('setBettingDisabled', true);
            }
            break;
          }
          case 'results':
          case 'SetResults': {
            break;
          }
          case 'race':
          case 'StartRace': {
            dispatch('setBettingDisabled', false);
            break;
          }
        }
        break;
      case 'race':
      case 'StartRace': {
        dispatch('setBettingDisabled', false);
        dispatch('setEventName', eventName);
        if (getters.offerList.length) {
          dispatch('setActiveOffer', getters.offerList[1]);
          dispatch('setEventId', getters.activeOffer.eventId);
          dispatch('setRacerOdds');
        }
        break;
      }
      case 'results':
      case 'SetResults': {
        dispatch('setEventName', eventName);
        break;
      }
      case 'new':
      case 'StartEvent': {
        dispatch('setEventName', eventName);
        countdownTime = eventData.countdown;
        if (eventData.offer?.length) {
          dispatch('setOffers', eventData.offer);
          dispatch('setActiveOffer', getters.offerList[0]);
          dispatch('setEventId', getters.activeOffer.eventId);
          dispatch('setRacerOdds');
        }
        dispatch('setEventTime', countdownTime);
        dispatch('setCountdownInterval');
        break;
      }
      case 'bettingDisabled':
      case 'StopBetting': {
        dispatch('setEventName', eventName);
        dispatch('setBettingDisabled', true);
        dispatch('lockExpiredBets');
        break;
      }
      case 'TicketUpdate': {
        if (!eventData.code && !eventData.httpCode) {
          ticketCheck.stopTicketChecker(eventData);
          // TODO: Refactor for serbian taxId which will be on ticket level
          // TODO: IMPLMENENT BET GUIDS
          // if (data.ticket.bets[0].additionalDetails?.taxId) await dispatch('mapBetGuids', data);
          // dispatch('betslip/disablePayinButton', false, { root: true });

          // Send message over GGateway event 'Tickets.Update' to update state of ticket on platform
          const ticketData = {
            action: capitalizeFirstLetter(eventData.action), // available values: Add, Cancel, Payout
            ticket: eventData,
          };
          eventBus.$emit('ticketUpdate', ticketData);
          // CHECK IF THE TICKET SHOULD BE PRINTED
          if (
            rootState.ticketChecksInProgress.some(
              (ticket) => ticket.id === eventData.id || ticket.requestUuid === eventData.requestUuid,
            )
          ) {
            commit('REMOVE_TICKET_CHECK_IN_PROGRESS', eventData.id, { root: true });
          } else if (rootState.printedTicketsByTicketCheck.some((ticket) => ticket.id === eventData.id)) {
            commit('REMOVE_PRINTED_TICKET_BY_TICKET_CHECK', eventData.id, { root: true });
          } else {
            eventBus.$emit('printTemplate', ticketData);
          }
        } else {
          errorTicketHandler(eventData);
          ticketCheck.stopTicketChecker({ requestId: eventData.requestUuid });
        }
        break;
      }
      case 'game.deactivated':
        dispatch('setConnectionStatus', false);
        break;
      case 'game.activated':
        dispatch('setConnectionStatus', true);
        break;
    }
  },
  updateSystem({ commit }, systemValue) {
    commit(types.SET_SYSTEM_VALUE, systemValue);
  },
  async mapFastModeBets({ getters, dispatch, rootGetters }) {
    const inputValue = getters.betInputData.value['1'];
    if (!inputValue) return;
    const system = getters.betInputData?.system;
    const mappedBetInputData = { value: {} };
    for (let i = 0; i < inputValue.length; i += 1) {
      mappedBetInputData.value[i + 1] = inputValue[i];
    }
    if (system) {
      for (let i = 0; i < system.length; i += 1) {
        mappedBetInputData.value[i + 7] = system[i];
      }
    }
    if (getters.isSystemBetActive) {
      mappedBetInputData.betId = 10;
      mappedBetInputData.betDisplayName = rootGetters.getTranslation('forecast');
    } else {
      mappedBetInputData.betId = 0;
      mappedBetInputData.betDisplayName = rootGetters.getTranslation('winner');
    }
    await dispatch(
      'runAction',
      {
        action: 'setBetInputData',
        payload: mappedBetInputData,
      },
      { root: true },
    );
  },
  async handleGameShortcuts({ state, dispatch, getters }, payload) {
    const { bettingInputs, additionalBettingInputs } = state.localGameConfig;
    const { quickBetslipModeAllowed, quickBetslipModeShortcut } = getters;
    // TOGGLE QUICK BETSLIP MODE
    if (
      quickBetslipModeAllowed &&
      !payload.ctrlKey &&
      payload.key.toLowerCase() === quickBetslipModeShortcut.shortcut.toLowerCase()
    ) {
      dispatch('toggleQuickBetslipMode');
    }

    const selectedBet = bettingInputs.filter(
      (bettingInput) =>
        bettingInput.shortcut === String(payload.key.trim()).toLowerCase() && bettingInput.enabled && !payload.ctrlKey,
    );
    if (selectedBet.length) {
      await dispatch('resetBetInputValue');
      await dispatch('setSelectedBet', selectedBet[0]);
      await dispatch('clearAdditionalInputs');
      eventBus.$emit('focusFirstBettingInput');
    }
    // FIND IF THERE ARE ANY ADDITIONAL INPUTS THAT SHOULD BE SHOWN ON SHORTCUT ( Future, system )
    const additionalInput = additionalBettingInputs.filter(
      (bettingInput) =>
        bettingInput.shortcut === String(payload.key.trim()).toLowerCase() && bettingInput.enabled && !payload.ctrlKey,
    );
    const isAdditionalInputAllowed =
      additionalInput[0] && getters.selectedBet.additionalInputs?.includes(additionalInput[0].clientId);
    if (additionalInput.length && isAdditionalInputAllowed && !getters.isBettingDisabled) {
      dispatch('setAdditionalInput', additionalInput[0]);
    }

    if (getters.selectedBet.outcomeShortcuts) {
      await dispatch('handleOutcomeShortcuts', { data: payload });
    }
  },
  async handleOutcomeShortcuts({ getters, dispatch }, payload) {
    const { key } = payload.data;
    const { outcomeShortcuts } = getters.selectedBet;
    const outcomeShortcut = outcomeShortcuts.filter((shortcuts) => shortcuts.shortcut === key.toLowerCase())[0];
    const { quickBetslipModeActive, isBettingDisabled } = getters;
    const shortcutClasses = ['input-field', 'shortcut', 'games-client-shop'];
    const activeElementClasses = Array.from(document.activeElement.classList);

    const activateShortcut = shortcutClasses.some((item) => activeElementClasses.includes(item));

    if (!outcomeShortcut || isBettingDisabled || !activateShortcut) return;
    if (
      outcomeShortcut &&
      outcomeShortcut.action?.name === 'auto_fill' &&
      !getters.isBettingDisabled &&
      !payload.data.ctrlKey
    ) {
      await dispatch('autoFill');
    } else if (quickBetslipModeActive) {
      document.activeElement.blur();
      dispatch('setSingleBetInputValue', { inputId: 1, inputValue: outcomeShortcut.shortcut });
    }
    if (!quickBetslipModeActive || (outcomeShortcut && outcomeShortcut.action?.name === 'auto_fill')) return;

    // Prevent bet with invalid odds from being added to betslip
    if (quickBetslipModeActive) {
      const validateBet = inputHelpers.validateBet(getters.betInputData.value, getters.selectedBet.betValidations);
      if (!validateBet.isValid) {
        dispatch('sendGGMessage', { message: validateBet.message, delay: 3000 }, { root: true });
        setTimeout(() => {
          eventBus.$emit('focusFirstBettingInput');
        }, 0);
        return;
      }
    }

    setTimeout(async () => {
      if (getters.selectedBet.clientId === 15) await dispatch('mapFastModeBets');
      await dispatch('addToBetslip', null, { root: true });
      eventBus.$emit('focusTotalPayment');
    }, 0);
  },
  autoFill({ state, getters, dispatch }) {
    const { maxRacerNumber } = state;
    const numbersRange = range(1, maxRacerNumber + 1);
    const selectedBet = getters.selectedBet;
    const allowedInputs = ['system'];

    let newBetValues = {};
    let activeInput = document.activeElement.id || '';

    // Determine correct input based on maxRacerNumber
    const firstRowLastInput = maxRacerNumber;
    const secondRowFirstInput = maxRacerNumber + 1;
    const secondRowLastInput = maxRacerNumber * 2;
    const thirdRowFirstInput = secondRowLastInput + 1;
    const thirdRowLastInput = maxRacerNumber * 3;

    // allowed inputs have ids ("1", "2", "system")
    // if active input is on betslip or somewhere else set it to "1" to auto fill first input
    if (!Number(activeInput) && !allowedInputs.includes(activeInput)) {
      activeInput = '1';
    }
    // Determine which inputs to fill based on activeInput id
    // After they are filled, last input of the row should be focused
    let minimumRowIndex, nextActiveInput;
    if (activeInput === 'system') {
      minimumRowIndex = 'system';
      nextActiveInput = 'system';
    } else if (selectedBet.clientId === 15) {
      minimumRowIndex = 1;
      nextActiveInput = 1;
    } else if (Number(activeInput) <= firstRowLastInput) {
      // First row
      [minimumRowIndex, nextActiveInput] = [1, firstRowLastInput];
    } else if (Number(activeInput) <= secondRowLastInput) {
      // Second row
      [minimumRowIndex, nextActiveInput] = [secondRowFirstInput, secondRowLastInput];
    } else {
      // Third row
      [minimumRowIndex, nextActiveInput] = [thirdRowFirstInput, thirdRowLastInput];
    }
    // Blur to prevent input event on field
    document.activeElement.blur();

    // Create additional bet data to fill the row
    if (!isNil(minimumRowIndex)) {
      if (selectedBet.clientId === 15 || activeInput === 'system') {
        if (activeInput === 'system') {
          dispatch('updateSystem', numbersRange.join(''));
        } else {
          // Set the input fields to new data
          newBetValues['1'] = numbersRange.join('');
          Object.entries(newBetValues).forEach(([key, value]) => {
            dispatch('setSingleBetInputValue', { inputId: Number(key), inputValue: value });
          });
        }
      } else {
        numbersRange.forEach((number, index) => {
          newBetValues[minimumRowIndex + index] = String(number);
        });
        // Set the input fields to new data
        Object.entries(newBetValues).forEach(([key, value]) => {
          dispatch('setSingleBetInputValue', { inputId: Number(key), inputValue: value });
        });
      }
    }

    setTimeout(() => {
      const element = document.getElementById(String(nextActiveInput));
      if (element) {
        element.dispatchEvent(new Event('input', { bubbles: true }));
        element.focus();
        element.setSelectionRange(1, 1);
      }
    }, 0);
  },
  toggleQuickBetslipMode({ commit }) {
    commit(types.TOGGLE_QUICK_BETSLIP_MODE);
  },
  setOffers({ commit }, offerList) {
    commit(types.SET_OFFER_LIST, offerList);
  },
  setActiveOffer({ commit }, offer) {
    commit(types.SET_ACTIVE_OFFER, offer);
  },
  setEventId({ commit }, eventId) {
    commit(types.SET_EVENT_ID, eventId);
  },
  async handleTicketsCheckEvent({ dispatch, getters, rootState }, payload) {
    // TODO: After 7Shop releases the flag for ticket check strategies, it is necessary to make changes.
    // 'seven' strategy for ticket check
    if (payload.data?.ticket?.bets) {
      // MAP BOTH TICKETS
      dispatch('showTicketPreview', { ticket: payload.data?.ticket, ticketVersion: '1' }, { root: true });
      busService.sendMessage('UI.Hide', { name: ['All'] });
    } else {
      // 'slave' (new) strategy for ticket check
      const ticketId = payload.data?.ticket?.id;
      const { ticketType } = getters.localGameConfig;
      const ticketCheckTicket = await ticketAPI
        .checkTicketBarcode(ticketId, ticketType)
        .then((response) => response)
        .catch((error) => {
          errorTicketHandler(error);
        });
      // TODO: Temp solution for ticket PIN see above TODO
      // add ticket pin if it exists
      if (isNil(ticketCheckTicket)) return;
      ticketCheckTicket.ticketPin = payload.data?.ticket.ticketPin ? payload.data?.ticket.ticketPin : null;
      if (ticketCheckTicket.status.value.toLowerCase() === 'won' && rootState.autoPayout) {
        const action = 'Tickets.Payout';
        const ticketData = {
          action,
          data: {
            ticket: ticketCheckTicket,
          },
        };
        busService.sendMessageAsync(ticketData.action, ticketData.data);
      } else {
        dispatch('showTicketPreview', { ticket: ticketCheckTicket, ticketVersion: '1' }, { root: true });
        busService.sendMessage('UI.Hide', { name: ['All'] });
      }
    }
    // Checked the ticket and forwards ticket data to product ticket preview (popup)
  },
  lockExpiredBets({ dispatch, rootState, rootGetters, state }) {
    if (state.name === rootState.currentActiveGame) {
      const ticket = rootGetters['gamesBetslip/ticket'];
      if (ticket.length) {
        dispatch(
          'sendGGMessage',
          {
            message: rootGetters.getTranslation('invalid_bets_accept'),
            type: 'warning',
            delay: 3000,
          },
          { root: true },
        );

        ticket.forEach((bet) => {
          dispatch(
            'gamesBetslip/updateBetValue',
            {
              ...bet,
              locked: true,
              permanentlyLock: true,
            },
            { root: true },
          );
        });
      }
    } else {
      const storedTicket = rootGetters.getGameGetter({ getter: 'betslipBets', specificGame: state.name });
      if (storedTicket.length) {
        // Avoid using updateBetValue to prevent updated bets from beeing added to current game betslip
        storedTicket.forEach((bet) => {
          bet.locked = true;
          bet.permanentlyLock = true;
        });
      }
    }
  },
  async setRacerOdds({ getters }) {
    const { odds } = getters;
    const marketOdds = Object.keys(odds);
    const { bettingInputs } = getters.localGameConfig;

    // For all active markets, create betting previews
    for (let i = 0; i < marketOdds.length; i++) {
      const market = marketOdds[i];
      const bettingInput = bettingInputs.find((input) => input.betName === market);

      if (!bettingInput) return;

      let { betDisplayName, betType } = bettingInput;
      let outcomes = [];
      let betLimit;

      // Extract odds for current market
      const currentOdds = odds[market];

      // Create different outcomes depending on market
      switch (market) {
        case 'winner':
        case 'place':
        case 'show':
        case 'out2':
        case 'out3':
        case 'notWinner':
          outcomes = currentOdds.map((marketOdd) => ({
            outcome: marketOdd.racers[0],
            odd: marketOdd.value.toFixed(2),
          }));
          break;
        case 'sum2':
        case 'sum3':
        case 'higherLower': {
          const { lower, higher, limit } = currentOdds;
          betLimit = limit;
          outcomes = [
            { outcome: 'under', odd: lower, shortcut: '1' },
            { outcome: 'over', odd: higher, shortcut: '2' },
          ];
          break;
        }
        case 'evenOdd': {
          const { odd, even } = currentOdds;
          outcomes = [
            { outcome: 'even', odd: odd, shortcut: '1' },
            { outcome: 'odd', odd: even, shortcut: '2' },
          ];
          break;
        }
        case 'headToHead': {
          const raceOdds = {};

          currentOdds.forEach(({ racers, value }) => {
            raceOdds[`${racers[0]}-${racers[1]}`] = Number(value) ? value : '-';
          });

          let index = 0;
          const racers = [1, 2, 3, 4];
          for (let i = 0; i < racers.length; i++) {
            for (let j = i + 1; j < racers.length; j++) {
              const racer1 = racers[i];
              const racer2 = racers[j];
              index++;
              outcomes.push({
                racers: [racer1, racer2],
                odds: [raceOdds[`${racer1}-${racer2}`], raceOdds[`${racer2}-${racer1}`]],
                outcomeIndex: index,
              });
            }
          }
          break;
        }
        default:
          continue;
      }

      bettingInput.bettingPreview = {
        ...bettingInput.bettingPreview,
        betDisplayName,
        betType,
        outcomes,
        betLimit,
      };
    }
  },
  async rebetTicket({ rootGetters, state, getters, dispatch }, bets) {
    dispatch('clearBetslipTicket');
    await dispatch('clearBetslip', null, { root: true });

    const newBets = [];

    bets.forEach((bet) => {
      const betData = { value: {} };
      const { id } = bet.bet;
      switch (id) {
        case 0:
        case 1:
        case 2:
        case 5:
        case 6:
        case 7: {
          betData.value[1] = String(bet.outcome.value);
          break;
        }
        case 3:
        case 4:
        case 8:
        case 9: {
          betData.value[1] = bet.outcome.value ? '2' : '1';
          break;
        }
        case 10:
        case 11: {
          betData.value = bet.outcome.value.reduce((acc, array, arrayIndex) => {
            const startIndex = arrayIndex * state.maxRacerNumber + 1;
            array.forEach((element, index) => {
              acc[startIndex + index] = element;
            });
            return acc;
          }, {});
          break;
        }
        case 12:
        case 13: {
          betData.value = bet.outcome.value.flat().reduce((acc, value, index) => {
            acc[index + 1] = value;
            return acc;
          }, {});
          break;
        }
        case 14: {
          const raceCombination = bet.combinations[0].value.split('-');
          const raceIndex = selectedBet.bettingPreview.outcomes.findIndex((outcome) =>
            outcome.racers.every((racer) => raceCombination.includes(String(racer))),
          );
          const race = selectedBet.bettingPreview.outcomes[raceIndex];
          const racerFromBet = bet.outcome?.value[0];

          const racerIndex = racerFromBet[0] === race.racers[0] ? 1 : 2;

          betData.value = { 1: raceIndex + 1, 2: racerIndex };
          break;
        }
      }
      const selectedBet = getters.localGameConfig.bettingInputs.find((input) => input.betId === id);
      const hasOddValidation = selectedBet.betValidations.find((validation) => validation.type === 'validOdd');
      if (hasOddValidation) {
        const outcomes = selectedBet.bettingPreview?.outcomes;
        const betOutcome = outcomes[betData.value['1'] - 1];
        if (!Number(betOutcome.odd)) {
          dispatch(
            'sendGGMessage',
            { message: rootGetters.getTranslation('invalid_bet_selected'), delay: 3000 },
            { root: true },
          );
          return;
        }
      }
      betData.betId = id;
      const newBet = betslipUtility.createRacersBet(betData);
      newBet[0].stake = bet.payin;
      newBets.push(...newBet);
    });

    for (let i = 0; i < newBets.length; i++) {
      await dispatch('gamesBetslip/addBet', newBets[i], { root: true });
    }

    if (getters.isBettingDisabled) dispatch('lockExpiredBets');
  },
};
