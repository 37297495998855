import { each } from 'lodash';
import store from '../store';

const getInputValidations = (validations) => {
  const data = {};
  each(validations, (validation) => {
    switch (validation.type) {
      case 'required':
        data.required = true;
        break;
      case 'length':
        data.maxLength = validation.max;
        data.minLength = validation.min;
        break;
      case 'range':
        data.minRangeValue = validation.min;
        data.maxRangeValue = validation.max;
        break;
      case 'pattern':
        data.expression = validation.expression;
        break;
      case 'oddsRange':
        data.minOddValue = validation.min;
        data.maxOddValue = validation.max;
        break;
      case 'allowedLengths':
        data.allowedLengths = validation.value;
        break;
      case 'validForecast':
        data.forecastValidation = true;
        break;
      case 'validTricast':
        data.tricastValidation = true;
        break;
      case 'validHeadToHead':
        data.headToHead = true;
        break;
      default:
    }
  });
  return data;
};

// const validateInput = (value, pattern) => pattern.test(value);
const validateOdd = function (odd) {
  const rules = store.getters.localGameConfig.oddRules;
  if (rules && odd < rules.minOdd) {
    return { isValid: false, rule: 'rule_min_odds', value: rules.minOdd };
  } else if (rules && odd >= rules.maxOdd) {
    return { isValid: false, rule: 'rule_max_odds', value: rules.maxOdd };
  }
  return {
    isValid: true,
    rule: false,
    value: NaN,
  };
};
/**
 * Function that validates input field
 */
const validateInput = function (inputValue, validations, inputId) {
  const rules = store.getters.localGameConfig.oddRules;
  const result = {
    isValid: true,
    value: null,
    rule: '',
    translationKey: '',
  };
  Object.entries(validations).forEach(([key, value]) => {
    // console.log(key, value);
    switch (key) {
      case 'required':
        if (!inputValue) {
          result.isValid = false;
          result.rule = key;
        }
        break;
      case 'maxLength': {
        if (inputValue.length > value) {
          result.isValid = false;
          result.rule = key;
        }
        break;
      }
      case 'minRangeValue':
        if (Number(inputValue) < value) {
          result.isValid = false;
          result.rule = key;
        }
        break;
      case 'maxRangeValue':
        if (Number(inputValue) > value) {
          result.isValid = false;
          result.rule = key;
        }
        break;
      case 'minOddValue': {
        if (Number(inputValue) < 1) {
          result.isValid = false;
          result.translationKey = 'rule_min_odds';
          result.value = rules.minOdd;
          result.rule = key;
        }
        break;
      }
      case 'maxOddValue': {
        if (Number(inputValue) >= rules.maxOdd) {
          result.isValid = false;
          result.translationKey = 'rule_max_odds';
          result.value = rules.maxOdd;
          result.rule = key;
        }
        break;
      }
      case 'expression':
        if (!value.test(inputValue)) {
          result.isValid = false;
          result.rule = key;
        }
        break;
      case 'headToHead':
        if (inputId === '2' && Number(inputValue) > 2) {
          result.isValid = false;
        }
        break;
    }
  });
  // console.log(result);
  return result;
};
/**
 * Function that validates inputs array
 */
const validateBet = function (inputs, validations) {
  const localGameConfig = store.getters.getGameGetter({ getter: 'localGameConfig' });
  const betOutcomes = store.getters.getGameGetter({ getter: 'selectedBet' }).bettingPreview?.outcomes;
  const result = {
    isValid: true,
    inputId: null,
    message: null,
  };
  for (const validation of validations) {
    const { type } = validation;
    switch (type) {
      case 'range':
        if (Object.values(inputs).some((input) => Number(input) < validation.min)) {
          result.isValid = false;
          result.message = store.getters.getTranslation(validation.message).supplant({ value: validation.min });
        }
        break;
      case 'unique': {
        const seenValues = new Set();
        for (const key in inputs) {
          const value = inputs[key];
          if (seenValues.has(value)) {
            result.inputId = key;
            result.isValid = false;
            break;
          } else {
            seenValues.add(value);
          }
        }
        break;
      }
      case 'length': {
        if (Object.values(inputs).length < validation.min) {
          result.isValid = false;
          break;
        }
        let counter = 0;
        for (const [key, value] of Object.entries(inputs)) {
          if (counter === validation.min) break;
          if (!value) {
            result.isValid = false;
            result.inputId = key;
            break;
          }
          counter++;
        }
        break;
      }
      case 'allowedLengths': {
        const numInputs = Object.values(inputs).filter((value) => value !== '').length;
        if (!validation.value.includes(numInputs)) {
          result.isValid = false;
          if (Object.values(inputs).length === validation.max)
            result.message = store.getters.getTranslation(validation.message);
        }
        break;
      }
      case 'validForecast': {
        const selectedBet = localGameConfig.bettingInputs.filter((bettingInput) => bettingInput.clientId === 11)[0];
        const { inputFields, inputRows } = selectedBet;
        const inputsInRow = inputFields / inputRows;
        // Split input to two arrays
        const firstInputRow = [];
        const secondInputRow = [];
        const validCombinations = [];
        // Loop through the expected keys based on the provided structure
        for (let i = 1; i <= inputFields; i++) {
          const key = i.toString();
          if (Object.prototype.hasOwnProperty.call(inputs, key)) {
            if (i <= inputsInRow) {
              firstInputRow.push(inputs[key]);
            } else {
              secondInputRow.push(inputs[key]);
            }
          }
        }

        // Check for duplicate inputs in each row
        const firstRow = new Set();
        const secondRow = new Set();

        for (const key in inputs) {
          if (Number(key) <= inputsInRow) {
            if (firstRow.has(inputs[key])) {
              result.isValid = false;
              result.inputId = key;
              break;
            }
            firstRow.add(inputs[key]);
          } else {
            if (secondRow.has(inputs[key])) {
              result.isValid = false;
              result.inputId = key;
              break;
            }
            secondRow.add(inputs[key]);
          }
        }

        firstInputRow.forEach((firstInput) => {
          secondInputRow.forEach((secondInput) => {
            const combination = firstInput + '-' + secondInput;
            if (
              firstInput === '' ||
              secondInput === '' ||
              firstInput === secondInput ||
              validCombinations.some((validCombination) => validCombination === combination)
            )
              return;
            validCombinations.push(combination);
          });
        });
        if (!validCombinations.length) {
          result.isValid = false;
          if (!firstInputRow.filter((value) => value !== '').length) {
            result.inputId = 1;
          } else if (firstInputRow.at(-1) === '' && firstInputRow.filter((value) => value !== '').length) {
            result.inputId = inputsInRow + 1;
          }
        }
        break;
      }
      case 'validTricast': {
        const selectedBet = localGameConfig.bettingInputs.filter((bettingInput) => bettingInput.clientId === 12)[0];
        const { inputFields, inputRows } = selectedBet;
        const inputsInRow = inputFields / inputRows;
        // Split input to two arrays
        const firstInputRow = [];
        const secondInputRow = [];
        const thirdInputRow = [];
        const validCombinations = [];
        // Loop through the expected keys based on the provided structure
        for (let i = 1; i <= inputFields; i++) {
          const key = i.toString();
          if (Object.prototype.hasOwnProperty.call(inputs, key)) {
            if (i <= inputsInRow) {
              firstInputRow.push(inputs[key]);
            } else if (i <= inputsInRow * 2) {
              secondInputRow.push(inputs[key]);
            } else {
              thirdInputRow.push(inputs[key]);
            }
          }
        }

        // Check for duplicate inputs in each row
        const firstRow = new Set();
        const secondRow = new Set();
        const thirdRow = new Set();

        for (const key in inputs) {
          if (Number(key) <= inputsInRow) {
            if (firstRow.has(inputs[key])) {
              result.isValid = false;
              result.inputId = key;
              break;
            }
            firstRow.add(inputs[key]);
          } else if (Number(key) <= inputsInRow * 2) {
            if (secondRow.has(inputs[key])) {
              result.isValid = false;
              result.inputId = key;
              break;
            }
            secondRow.add(inputs[key]);
          } else {
            if (thirdRow.has(inputs[key])) {
              result.isValid = false;
              result.inputId = key;
              break;
            }
            thirdRow.add(inputs[key]);
          }
        }

        firstInputRow.forEach((firstInput) => {
          secondInputRow.forEach((secondInput) => {
            thirdInputRow.forEach((thirdInput) => {
              const combination = firstInput + '-' + secondInput + '-' + thirdInput;
              if (
                firstInput === '' ||
                secondInput === '' ||
                thirdInput === '' ||
                firstInput === secondInput ||
                firstInput === thirdInput ||
                secondInput === thirdInput ||
                validCombinations.some((validCombination) => validCombination === combination)
              )
                return;
              validCombinations.push(combination);
            });
          });
        });
        if (!validCombinations.length) {
          result.isValid = false;
          if (!firstInputRow.filter((value) => value !== '').length) {
            result.inputId = 1;
          } else if (firstInputRow.at(-1) === '' && !secondInputRow.filter((value) => value !== '').length) {
            result.inputId = inputsInRow + 1;
          } else if (secondInputRow.at(-1) === '' && !thirdInputRow.filter((value) => value !== '').length) {
            result.inputId = inputsInRow * 2 + 1;
          }
        }
        break;
      }
      case 'validHeadToHead': {
        const { 1: raceIndex, 2: outcome } = inputs;

        const race = betOutcomes[raceIndex - 1];
        const odd = Number(race.odds[outcome - 1]);

        if (!odd) {
          result.isValid = false;
          result.message = store.getters.getTranslation('invalid_bet_selected');
        }
        break;
      }
      case 'validOdd': {
        const input = inputs[1];
        const odd = Number(betOutcomes[input - 1].odd);
        if (!odd) {
          result.isValid = false;
          result.message = store.getters.getTranslation('invalid_bet_selected');
        }
        break;
      }
    }
    if (!result.isValid) {
      break;
    }
  }
  return result;
};
export default {
  getInputValidations,
  validateInput,
  validateOdd,
  validateBet,
};
