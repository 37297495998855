import crashcash from './localGameConfigurations/crashcash';
import luckysix from './localGameConfigurations/luckysix';
import greyhoundraces from './localGameConfigurations/greyhoundraces';
import virtualgreyhoundraces from './localGameConfigurations/virtualgreyhoundraces';
import virtualhorseraces from './localGameConfigurations/virtualhorseraces';
import virtualmotorcyclespeedway from './localGameConfigurations/virtualmotorcyclespeedway';

export default {
  crashcash,
  luckysix,
  greyhoundraces,
  virtualgreyhoundraces,
  virtualhorseraces,
  virtualmotorcyclespeedway,
};
