import { mapValues } from 'lodash';

export default {
  name: (state) => state.name,
  adapters: (state) => state.localGameConfig.adapters,
  config: (state) => state.config,
  eventTime: (state) => state.eventTime,
  eventDisplayId: (state) => state.eventDisplayId,
  isBettingDisabled: (state) => state.bettingDisabled,
  betInputData: (state) => state.betInputData,
  shortcuts: (state, getters, rootState, rootGetters) => {
    const { shortcuts } = state.localGameConfig;
    return mapValues(shortcuts, (shortcut) => ({
      ...shortcut,
      name: rootGetters.getTranslation(shortcut.name) || shortcut.name,
    }));
  },
  selectedBet: (state) => state.selectedBet,
  betslipBets: (state) => state.betslipBets,
  isSystemBetActive: (state) => state.additionalInputs.filter((input) => input.betType === 'system')[0] ?? false,
  systemInput: (state) => state.additionalInputs.filter((input) => input.betType === 'system')[0] || {},
  defaultShortcut: (state) => {
    return Object.values(state.localGameConfig.shortcuts).filter((shortcut) => shortcut.action === 'ticket')[0];
  },
  localGameConfig: (state) => state.localGameConfig,
  activeNotifications: (state) => state.activeNotifications,
  betslipFooterValues: (state) => state.betslipFooterValues,
  connectionActive: (state) => state.connectionActive,
  configFromStatic: (state) => state.staticConfig,
  editBetModeFeatureAllowed: (state, getters) => getters.configFromStatic?.editBetModeFeature || false,
  quickBetslipModeActive: (state) => state.quickBetslipModeActive,
  quickBetslipModeAllowed: (state, getters) => getters.configFromStatic?.quickBetslipModeEnabled || false,
  quickBetslipModeShortcut: (state) => {
    return Object.values(state.localGameConfig.shortcuts).filter(
      (shortcut) => shortcut.action === 'quickBetslipMode',
    )[0];
  },
  autoTicketCheckerEnabled: (state, getters) => getters.configFromStatic?.autoTicketChecker,
};
