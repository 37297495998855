export default {
  // id: 0, // CLIENT GAME ID
  gameColor: '#178685',
  futureBet: false,
  systemBet: false,
  bettingType: 'other',
  additionalBetslipConfig: true,
  betslipConfiguration: {},
  adapters: [
    {
      name: 'UWSAdapter',
      options: { maxRetryAttempts: 10 },
      channels: [],
      type: 'Product',
    },
  ], // UWSAdapter support in games-bus-js  with options
  directPayin: false, // true: send ticket directly to product backend; false: use GG event 'Tickets.Pay' on 7Shop
  autoTicketCheckTimeout: 5000,
  ticketType: 'newTicket',
  betslipTypes: [
    // array of betslip types
    {
      type: 3,
      name: 'Single',
      value: 'single',
      active: true,
    },
  ],
  bettingInputs: [
    {
      content: 'bettingInput', // if we've type of component (like input, select, text display...)
      enabled: true,
      label: 'multiplier',
      betId: 1,
      clientId: 1,
      betType: 'normal',
      betDisplayName: 'ticket',
      shortcut: '+',
      inputType: 'text',
      inputFields: 1,
      additionalInputs: ['future'],
      // Validations for every input
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 8,
        },
        {
          type: 'range',
          min: 1,
          max: 10000,
        },
        {
          type: 'pattern',
          // regular expression pattern that will match partial input strings with 1 to 5 digits, with an optional decimal point followed by 1 or 2 digits
          expression: /^[1-9]\d{0,3}(\.\d{0,2})?$/, // only numbers, can be an integer or float
        },
        {
          type: 'oddsRange',
          min: 1,
          max: 9999.99,
        },
      ],
      // validation for every bet type
      // in this case there is only one bet type and only one input on that bet so the validation is basically the same
      // so there is no need to go through same validation again
      // for other games that wont be the case
      betValidations: [
        {
          type: 'length',
          min: 1,
        },
        {
          type: 'range',
          min: 1.01,
          max: 9999.99,
          message: 'rule_min_odds',
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
        {
          name: 'blur',
          handler: {},
        },
      ],
    },
  ],
  additionalBettingInputs: [
    {
      content: 'bettingInput',
      label: 'future_bet',
      clientId: 'future',
      enabled: true,
      betType: 'future',
      betDisplayName: 'future_bet',
      shortcut: 'f',
      inputType: 'text',
      inputFields: 1,
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 10,
        },
        {
          type: 'pattern',
          expression: /^[1-9]$|^10$/,
        },
      ],
      betValidations: [],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
        {
          name: 'blur',
          handler: {},
        },
      ],
    },
  ],
  shortcuts: {
    '+': {
      action: 'ticket',
      active: true,
      name: 'new_ticket',
      shortcut: '+',
      order: 1,
    },
    q: {
      action: 'results',
      active: true,
      name: 'results',
      shortcut: 'q',
      order: 2,
    },
    '/': {
      action: 'resetTicket',
      active: true,
      name: 'reset_ticket',
      shortcut: '/',
      order: 3,
    },
    f: {
      id: 'future',
      action: 'future',
      active: true,
      name: 'future_bet',
      shortcut: 'f',
      order: 4,
    },
    'ctrl + m': {
      action: 'focusFirstBet',
      active: true,
      name: 'focus_first_bet',
      shortcut: 'ctrl + m',
      order: 5,
    },
    'ctrl + s': {
      action: 'editStakePerBet',
      active: true,
      name: 'edit_stake_per_bet',
      shortcut: 'ctrl + s',
      order: 6,
    },
    'ctrl + f': {
      action: 'editFuturePerTicket',
      active: true,
      name: 'edit_future_per_ticket',
      shortcut: 'ctrl + f',
      order: 7,
    },
    'ctrl + b': {
      action: 'focusFirstInput',
      active: true,
      name: 'focus_first_input',
      shortcut: 'ctrl + b',
      order: 8,
    },
    'ctrl + e': {
      action: 'toggleEditMode',
      active: false,
      name: 'toggle_delete_bet_mode',
      shortcut: 'ctrl + e',
      order: 9,
    },
    delete: {
      action: 'deleteBet',
      active: false,
      name: 'delete_bet',
      shortcut: 'delete',
      order: 10,
    },
    m: {
      action: 'quickBetslipMode',
      active: false,
      name: 'enable_quick_betslip_mode',
      shortcut: 'm',
      order: 22,
    },
  },
  availableTicketActions: [
    {
      status: 'accepted',
      action: ['rebet', 'check', 'copy'],
    },
    {
      status: 'canceled',
      action: ['rebet'],
    },
    {
      status: 'paidout',
      action: ['rebet'],
    },
    {
      status: 'pending',
      action: ['checkStatus'],
    },
    {
      status: 'unknown',
      action: ['checkStatus'],
    },
    {
      status: 'failed',
      action: ['checkStatus'],
    },
    // 7Shop sets this local status, it should be paidout. This will be fixed in the future
    {
      status: 'payedout',
      action: ['rebet'],
    },
  ],
  // Ticket template layout name from CMS
  printLayoutType: {
    add: 'ticketCrashCash',
    payout: 'ticketCrashCashPayout',
    cancel: 'ticketCrashCashCancel',
    results: 'resultsCrashCash',
  },
  actionTypes: {
    add: 'TicketPayIn',
    payout: 'TicketPayout',
    cancel: 'TicketCancel',
    copy: 'TicketCopy',
  },
  oddRules: {
    minOdd: 1.01,
    maxOdd: 9999.99,
  },
};
