import { betslipUtility } from '.';
import store from '../store';
import { range, shuffle, sampleSize, isFinite } from 'lodash';

const getMacroShortcut = (input) => {
  const betInputValue = macroShortcutsBets[input] || {};
  // CHECK IF RANDOM SHOULD BE CALLED
  if (betInputValue.random) {
    betInputValue.value = getRandomValue(betInputValue.random);
  }
  const selectedBet = getSelectedBet(input);
  if (!selectedBet) return;
  const { betType, betSubType, betDisplayName, outcomes, betId } = selectedBet;
  return {
    ...betInputValue,
    betType,
    betSubType,
    betDisplayName,
    outcomes,
    betId,
  };
};
const getSelectedBet = (input) => {
  const selectedBetClientId = selectedBetIdMapper(Number(input));
  const localGameConfig = store.getters.getGameGetter({ specificGame: 'luckysix', getter: 'localGameConfig' });
  return localGameConfig.bettingInputs.filter((bettingInput) => bettingInput.clientId === selectedBetClientId)[0];
};
const getRandomValue = (randomValue) => {
  if (isFinite(randomValue)) {
    const numbersRange = range(1, 49);
    const numbersShuffle = shuffle(numbersRange);
    const numbersSample = sampleSize(numbersShuffle, randomValue);
    const betSelection = {};
    for (let i = 0; i < numbersSample.length; i += 1) {
      betSelection[i + 1] = String(numbersSample[i]);
    }
    return betSelection;
  } else if (randomValue === 'color') {
    const numbersRange = range(1, 8);
    const numbersShuffle = shuffle(numbersRange);
    const numbersSample = sampleSize(numbersShuffle, 1);
    return betslipUtility.createColorBet(numbersSample);
  } else {
    return { 1: '' };
  }
};
const selectedBetIdMapper = (input) => {
  if (input === 1 || (input >= 250 && input <= 258)) {
    return 2;
  } else if ((input >= 2 && input <= 11) || (input >= 280 && input <= 288)) {
    return 3;
  } else if (input === 12 || input === 13) {
    return 7;
  } else if (input === 14 || input === 15) {
    return 8;
  } else if (input === 16 || input === 17) {
    return 11;
  } else if (input === 18 || input === 19) {
    return 9;
  } else if ((input >= 20 && input <= 30) || (input >= 40 && input <= 135)) {
    return 6;
  } else if (input >= 200 && input <= 221) {
    return 1;
  } else if (input >= 270 && input <= 273) {
    return 5;
  } else if (input >= 303 && input <= 350) {
    return 10;
  }
};

const macroShortcutsBets = {
  1: {
    value: {
      1: '',
    },
    future: 1,
    random: 6,
  },
  2: {
    value: {
      1: '',
    },
    future: 1,
    random: 'color',
  },
  3: {
    value: {
      1: '1',
    },
    future: 1,
  },
  4: {
    value: {
      1: '2',
    },
    future: 1,
  },
  5: {
    value: {
      1: '3',
    },
    future: 1,
  },
  6: {
    value: {
      1: '4',
    },
    future: 1,
  },
  7: {
    value: {
      1: '5',
    },
    future: 1,
  },
  8: {
    value: {
      1: '6',
    },
    future: 1,
  },
  9: {
    value: {
      1: '7',
    },
    future: 1,
  },
  10: {
    value: {
      1: '8',
    },
    future: 1,
  },
  11: {
    value: {
      1: '9',
    },
    future: 1,
  },
  12: {
    value: {
      1: '1',
    },
    future: 1,
  },
  13: {
    value: {
      1: '2',
    },
    future: 1,
  },
  14: {
    // TODO THESE ARE REVERSED
    value: {
      1: '2',
    },
    future: 1,
  },
  15: {
    // TODO THESE ARE REVERSED
    value: {
      1: '1',
    },
    future: 1,
  },
  16: {
    // TODO THESE ARE REVERSED
    value: {
      1: '2',
    },
    future: 1,
  },
  17: {
    // TODO THESE ARE REVERSED
    value: {
      1: '1',
    },
    future: 1,
  },
  18: {
    value: {
      1: '1',
    },
    future: 1,
  },
  19: {
    value: {
      1: '2',
    },
    future: 1,
  },
  20: {
    value: {
      1: '1',
    },
    future: 1,
  },
  21: {
    value: {
      1: '2',
    },
    future: 1,
  },
  22: {
    value: {
      1: '3',
    },
    future: 1,
  },
  23: {
    value: {
      1: '4',
    },
    future: 1,
  },
  24: {
    value: {
      1: '5',
    },
    future: 1,
  },
  25: {
    value: {
      1: '6',
    },
    future: 1,
  },
  26: {
    value: {
      1: '7',
    },
    future: 1,
  },
  27: {
    value: {
      1: '8',
    },
    future: 1,
  },
  28: {
    value: {
      1: '1',
      2: '2',
    },
    future: 1,
  },
  29: {
    value: {
      1: '1',
      2: '3',
    },
    future: 1,
  },
  30: {
    value: {
      1: '1',
      2: '4',
    },
    future: 1,
  },
  40: {
    value: {
      1: '1',
      2: '5',
    },
    future: 1,
  },
  41: {
    value: {
      1: '1',
      2: '6',
    },
    future: 1,
  },
  42: {
    value: {
      1: '1',
      2: '7',
    },
    future: 1,
  },
  43: {
    value: {
      1: '1',
      2: '8',
    },
    future: 1,
  },
  45: {
    value: {
      1: '2',
      2: '3',
    },
    future: 1,
  },
  46: {
    value: {
      1: '2',
      2: '4',
    },
    future: 1,
  },
  47: {
    value: {
      1: '2',
      2: '5',
    },
    future: 1,
  },
  48: {
    value: {
      1: '2',
      2: '6',
    },
    future: 1,
  },
  49: {
    value: {
      1: '2',
      2: '7',
    },
    future: 1,
  },
  50: {
    value: {
      1: '2',
      2: '8',
    },
    future: 1,
  },
  51: {
    value: {
      1: '3',
      2: '4',
    },
    future: 1,
  },
  52: {
    value: {
      1: '3',
      2: '5',
    },
    future: 1,
  },
  53: {
    value: {
      1: '3',
      2: '6',
    },
    future: 1,
  },
  54: {
    value: {
      1: '3',
      2: '7',
    },
    future: 1,
  },
  55: {
    value: {
      1: '3',
      2: '8',
    },
    future: 1,
  },
  56: {
    value: {
      1: '4',
      2: '5',
    },
    future: 1,
  },
  57: {
    value: {
      1: '4',
      2: '6',
    },
    future: 1,
  },
  58: {
    value: {
      1: '4',
      2: '7',
    },
    future: 1,
  },
  59: {
    value: {
      1: '4',
      2: '8',
    },
    future: 1,
  },
  60: {
    value: {
      1: '5',
      2: '6',
    },
    future: 1,
  },
  61: {
    value: {
      1: '5',
      2: '7',
    },
    future: 1,
  },
  62: {
    value: {
      1: '5',
      2: '8',
    },
    future: 1,
  },
  63: {
    value: {
      1: '6',
      2: '7',
    },
    future: 1,
  },
  64: {
    value: {
      1: '6',
      2: '8',
    },
    future: 1,
  },
  65: {
    value: {
      1: '7',
      2: '8',
    },
    future: 1,
  },
  66: {
    value: {
      1: '1',
      2: '2',
      3: '3',
      4: '4',
    },
    future: 1,
  },
  67: {
    value: {
      1: '1',
      2: '2',
      3: '3',
      4: '5',
    },
    future: 1,
  },
  68: {
    value: {
      1: '1',
      2: '2',
      3: '3',
      4: '6',
    },
    future: 1,
  },
  69: {
    value: {
      1: '1',
      2: '2',
      3: '3',
      4: '7',
    },
    future: 1,
  },
  70: {
    value: {
      1: '1',
      2: '2',
      3: '3',
      4: '8',
    },
    future: 1,
  },
  71: {
    value: {
      1: '1',
      2: '2',
      3: '4',
      4: '5',
    },
    future: 1,
  },
  72: {
    value: {
      1: '1',
      2: '2',
      3: '4',
      4: '6',
    },
    future: 1,
  },
  73: {
    value: {
      1: '1',
      2: '2',
      3: '4',
      4: '7',
    },
    future: 1,
  },
  74: {
    value: {
      1: '1',
      2: '2',
      3: '4',
      4: '8',
    },
    future: 1,
  },
  75: {
    value: {
      1: '1',
      2: '2',
      3: '5',
      4: '6',
    },
    future: 1,
  },
  76: {
    value: {
      1: '1',
      2: '2',
      3: '5',
      4: '7',
    },
    future: 1,
  },
  77: {
    value: {
      1: '1',
      2: '2',
      3: '5',
      4: '8',
    },
    future: 1,
  },
  78: {
    value: {
      1: '1',
      2: '2',
      3: '6',
      4: '7',
    },
    future: 1,
  },
  79: {
    value: {
      1: '1',
      2: '2',
      3: '6',
      4: '8',
    },
    future: 1,
  },
  80: {
    value: {
      1: '1',
      2: '2',
      3: '7',
      4: '8',
    },
    future: 1,
  },
  81: {
    value: {
      1: '1',
      2: '3',
      3: '4',
      4: '5',
    },
    future: 1,
  },
  82: {
    value: {
      1: '1',
      2: '3',
      3: '4',
      4: '6',
    },
    future: 1,
  },
  83: {
    value: {
      1: '1',
      2: '3',
      3: '4',
      4: '7',
    },
    future: 1,
  },
  84: {
    value: {
      1: '1',
      2: '3',
      3: '4',
      4: '8',
    },
    future: 1,
  },
  85: {
    value: {
      1: '1',
      2: '3',
      3: '5',
      4: '6',
    },
    future: 1,
  },
  86: {
    value: {
      1: '1',
      2: '3',
      3: '5',
      4: '7',
    },
    future: 1,
  },
  87: {
    value: {
      1: '1',
      2: '3',
      3: '5',
      4: '8',
    },
    future: 1,
  },
  88: {
    value: {
      1: '1',
      2: '3',
      3: '6',
      4: '7',
    },
    future: 1,
  },
  89: {
    value: {
      1: '1',
      2: '3',
      3: '6',
      4: '8',
    },
    future: 1,
  },
  90: {
    value: {
      1: '1',
      2: '3',
      3: '7',
      4: '8',
    },
    future: 1,
  },
  91: {
    value: {
      1: '1',
      2: '4',
      3: '5',
      4: '6',
    },
    future: 1,
  },
  92: {
    value: {
      1: '1',
      2: '4',
      3: '5',
      4: '7',
    },
    future: 1,
  },
  93: {
    value: {
      1: '1',
      2: '4',
      3: '5',
      4: '8',
    },
    future: 1,
  },
  94: {
    value: {
      1: '1',
      2: '4',
      3: '6',
      4: '7',
    },
    future: 1,
  },
  95: {
    value: {
      1: '1',
      2: '4',
      3: '6',
      4: '8',
    },
    future: 1,
  },
  96: {
    value: {
      1: '1',
      2: '4',
      3: '7',
      4: '8',
    },
    future: 1,
  },
  97: {
    value: {
      1: '1',
      2: '5',
      3: '6',
      4: '7',
    },
    future: 1,
  },
  98: {
    value: {
      1: '1',
      2: '5',
      3: '6',
      4: '8',
    },
    future: 1,
  },
  99: {
    value: {
      1: '1',
      2: '5',
      3: '7',
      4: '8',
    },
    future: 1,
  },
  100: {
    value: {
      1: '1',
      2: '6',
      3: '7',
      4: '8',
    },
    future: 1,
  },
  101: {
    value: {
      1: '2',
      2: '3',
      3: '4',
      4: '5',
    },
    future: 1,
  },
  102: {
    value: {
      1: '2',
      2: '3',
      3: '4',
      4: '6',
    },
    future: 1,
  },
  103: {
    value: {
      1: '2',
      2: '3',
      3: '4',
      4: '7',
    },
    future: 1,
  },
  104: {
    value: {
      1: '2',
      2: '3',
      3: '4',
      4: '8',
    },
    future: 1,
  },
  105: {
    value: {
      1: '2',
      2: '3',
      3: '5',
      4: '6',
    },
    future: 1,
  },
  106: {
    value: {
      1: '2',
      2: '3',
      3: '5',
      4: '7',
    },
    future: 1,
  },
  107: {
    value: {
      1: '2',
      2: '3',
      3: '5',
      4: '8',
    },
    future: 1,
  },
  108: {
    value: {
      1: '2',
      2: '3',
      3: '6',
      4: '7',
    },
    future: 1,
  },
  109: {
    value: {
      1: '2',
      2: '3',
      3: '6',
      4: '8',
    },
    future: 1,
  },
  110: {
    value: {
      1: '2',
      2: '3',
      3: '7',
      4: '8',
    },
    future: 1,
  },
  111: {
    value: {
      1: '2',
      2: '4',
      3: '5',
      4: '6',
    },
    future: 1,
  },
  112: {
    value: {
      1: '2',
      2: '4',
      3: '5',
      4: '7',
    },
    future: 1,
  },
  113: {
    value: {
      1: '2',
      2: '4',
      3: '5',
      4: '8',
    },
    future: 1,
  },
  114: {
    value: {
      1: '2',
      2: '4',
      3: '6',
      4: '7',
    },
    future: 1,
  },
  115: {
    value: {
      1: '2',
      2: '4',
      3: '6',
      4: '8',
    },
    future: 1,
  },
  116: {
    value: {
      1: '2',
      2: '4',
      3: '7',
      4: '8',
    },
    future: 1,
  },
  117: {
    value: {
      1: '2',
      2: '5',
      3: '6',
      4: '7',
    },
    future: 1,
  },
  118: {
    value: {
      1: '2',
      2: '5',
      3: '6',
      4: '8',
    },
    future: 1,
  },
  119: {
    value: {
      1: '2',
      2: '5',
      3: '7',
      4: '8',
    },
    future: 1,
  },
  120: {
    value: {
      1: '2',
      2: '6',
      3: '7',
      4: '8',
    },
    future: 1,
  },
  121: {
    value: {
      1: '3',
      2: '4',
      3: '5',
      4: '6',
    },
    future: 1,
  },
  122: {
    value: {
      1: '3',
      2: '4',
      3: '5',
      4: '7',
    },
    future: 1,
  },
  123: {
    value: {
      1: '3',
      2: '4',
      3: '5',
      4: '8',
    },
    future: 1,
  },
  124: {
    value: {
      1: '3',
      2: '4',
      3: '6',
      4: '7',
    },
    future: 1,
  },
  125: {
    value: {
      1: '3',
      2: '4',
      3: '6',
      4: '8',
    },
    future: 1,
  },
  126: {
    value: {
      1: '3',
      2: '4',
      3: '7',
      4: '8',
    },
    future: 1,
  },
  127: {
    value: {
      1: '3',
      2: '5',
      3: '6',
      4: '7',
    },
    future: 1,
  },
  128: {
    value: {
      1: '3',
      2: '5',
      3: '6',
      4: '8',
    },
    future: 1,
  },
  129: {
    value: {
      1: '3',
      2: '5',
      3: '7',
      4: '8',
    },
    future: 1,
  },
  130: {
    value: {
      1: '3',
      2: '6',
      3: '7',
      4: '8',
    },
    future: 1,
  },
  131: {
    value: {
      1: '4',
      2: '5',
      3: '6',
      4: '7',
    },
    future: 1,
  },
  132: {
    value: {
      1: '4',
      2: '5',
      3: '6',
      4: '8',
    },
    future: 1,
  },
  133: {
    value: {
      1: '4',
      2: '5',
      3: '7',
      4: '8',
    },
    future: 1,
  },
  134: {
    value: {
      1: '4',
      2: '6',
      3: '7',
      4: '8',
    },
    future: 1,
  },
  135: {
    value: {
      1: '5',
      2: '6',
      3: '7',
      4: '8',
    },
    future: 1,
  },
  200: {
    value: {
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
    },
    future: 1,
  },
  201: {
    value: {
      1: '6',
      2: '7',
      3: '8',
      4: '9',
      5: '10',
      6: '11',
    },
    future: 1,
  },
  202: {
    value: {
      1: '11',
      2: '12',
      3: '13',
      4: '14',
      5: '15',
      6: '16',
    },
    future: 1,
  },
  203: {
    value: {
      1: '16',
      2: '17',
      3: '18',
      4: '19',
      5: '20',
      6: '21',
    },
    future: 1,
  },
  204: {
    value: {
      1: '21',
      2: '22',
      3: '23',
      4: '24',
      5: '25',
      6: '26',
    },
    future: 1,
  },
  205: {
    value: {
      1: '26',
      2: '27',
      3: '28',
      4: '29',
      5: '30',
      6: '31',
    },
    future: 1,
  },
  206: {
    value: {
      1: '31',
      2: '32',
      3: '33',
      4: '34',
      5: '35',
      6: '36',
    },
    future: 1,
  },
  207: {
    value: {
      1: '36',
      2: '37',
      3: '38',
      4: '39',
      5: '40',
      6: '41',
    },
    future: 1,
  },
  208: {
    value: {
      1: '41',
      2: '42',
      3: '43',
      4: '44',
      5: '45',
      6: '46',
    },
    future: 1,
  },
  209: {
    value: {
      1: '43',
      2: '44',
      3: '45',
      4: '46',
      5: '47',
      6: '48',
    },
    future: 1,
  },
  210: {
    value: {
      1: '5',
      2: '10',
      3: '15',
      4: '20',
      5: '25',
      6: '30',
    },
    future: 1,
  },
  211: {
    value: {
      1: '20',
      2: '25',
      3: '30',
      4: '35',
      5: '40',
      6: '45',
    },
    future: 1,
  },
  212: {
    value: {
      1: '1',
      2: '3',
      3: '5',
      4: '7',
      5: '9',
      6: '11',
    },
    future: 1,
  },
  213: {
    value: {
      1: '11',
      2: '13',
      3: '15',
      4: '17',
      5: '19',
      6: '21',
    },
    future: 1,
  },
  214: {
    value: {
      1: '21',
      2: '23',
      3: '25',
      4: '27',
      5: '29',
      6: '31',
    },
    future: 1,
  },
  215: {
    value: {
      1: '31',
      2: '33',
      3: '35',
      4: '37',
      5: '39',
      6: '41',
    },
    future: 1,
  },
  216: {
    value: {
      1: '37',
      2: '39',
      3: '41',
      4: '43',
      5: '45',
      6: '47',
    },
    future: 1,
  },
  217: {
    value: {
      1: '2',
      2: '4',
      3: '6',
      4: '8',
      5: '10',
      6: '12',
    },
    future: 1,
  },
  218: {
    value: {
      1: '12',
      2: '14',
      3: '16',
      4: '18',
      5: '20',
      6: '22',
    },
    future: 1,
  },
  219: {
    value: {
      1: '22',
      2: '24',
      3: '26',
      4: '28',
      5: '30',
      6: '32',
    },
    future: 1,
  },
  220: {
    value: {
      1: '32',
      2: '34',
      3: '36',
      4: '38',
      5: '40',
      6: '42',
    },
    future: 1,
  },
  221: {
    value: {
      1: '38',
      2: '40',
      3: '42',
      4: '44',
      5: '46',
      6: '48',
    },
    future: 1,
  },
  250: {
    value: {
      1: '',
    },
    future: 10,
    random: 6,
  },
  251: {
    value: {
      1: '',
    },
    future: 9,
    random: 6,
  },
  252: {
    value: {
      1: '',
    },
    future: 8,
    random: 6,
  },
  253: {
    value: {
      1: '',
    },
    future: 7,
    random: 6,
  },
  254: {
    value: {
      1: '',
    },
    future: 6,
    random: 6,
  },
  255: {
    value: {
      1: '',
    },
    future: 5,
    random: 6,
  },
  256: {
    value: {
      1: '',
    },
    future: 4,
    random: 6,
  },
  257: {
    value: {
      1: '',
    },
    future: 3,
    random: 6,
  },
  258: {
    value: {
      1: '',
    },
    future: 2,
    random: 6,
  },
  270: {
    value: {
      1: '',
    },
    future: 1,
    random: 7,
    betType: 'system',
  },
  271: {
    value: {
      1: '',
    },
    future: 1,
    random: 8,
    betType: 'system',
  },
  272: {
    value: {
      1: '',
    },
    future: 1,
    random: 9,
    betType: 'system',
  },
  273: {
    value: {
      1: '',
    },
    future: 1,
    random: 10,
    betType: 'system',
  },
  280: {
    value: {
      1: '',
    },
    future: 2,
    random: 'color',
  },
  281: {
    value: {
      1: '',
    },
    future: 3,
    random: 'color',
  },
  282: {
    value: {
      1: '',
    },
    future: 4,
    random: 'color',
  },
  283: {
    value: {
      1: '',
    },
    future: 5,
    random: 'color',
  },
  284: {
    value: {
      1: '',
    },
    future: 6,
    random: 'color',
  },
  285: {
    value: {
      1: '',
    },
    future: 7,
    random: 'color',
  },
  286: {
    value: {
      1: '',
    },
    future: 8,
    random: 'color',
  },
  287: {
    value: {
      1: '',
    },
    future: 9,
    random: 'color',
  },
  288: {
    value: {
      1: '',
    },
    future: 10,
    random: 'color',
  },
  303: {
    value: {
      1: '1',
    },
    future: 1,
  },
  304: {
    value: {
      1: '2',
    },
    future: 1,
  },
  305: {
    value: {
      1: '3',
    },
    future: 1,
  },
  306: {
    value: {
      1: '4',
    },
    future: 1,
  },
  307: {
    value: {
      1: '5',
    },
    future: 1,
  },
  308: {
    value: {
      1: '6',
    },
    future: 1,
  },
  309: {
    value: {
      1: '7',
    },
    future: 1,
  },
  310: {
    value: {
      1: '8',
    },
    future: 1,
  },
  311: {
    value: {
      1: '9',
    },
    future: 1,
  },
  312: {
    value: {
      1: '10',
    },
    future: 1,
  },
  313: {
    value: {
      1: '11',
    },
    future: 1,
  },
  314: {
    value: {
      1: '12',
    },
    future: 1,
  },
  315: {
    value: {
      1: '13',
    },
    future: 1,
  },
  316: {
    value: {
      1: '14',
    },
    future: 1,
  },
  317: {
    value: {
      1: '15',
    },
    future: 1,
  },
  318: {
    value: {
      1: '16',
    },
    future: 1,
  },
  319: {
    value: {
      1: '17',
    },
    future: 1,
  },
  320: {
    value: {
      1: '18',
    },
    future: 1,
  },
  321: {
    value: {
      1: '19',
    },
    future: 1,
  },
  322: {
    value: {
      1: '20',
    },
    future: 1,
  },
  323: {
    value: {
      1: '21',
    },
    future: 1,
  },
  324: {
    value: {
      1: '22',
    },
    future: 1,
  },
  325: {
    value: {
      1: '23',
    },
    future: 1,
  },
  326: {
    value: {
      1: '24',
    },
    future: 1,
  },
  327: {
    value: {
      1: '25',
    },
    future: 1,
  },
  328: {
    value: {
      1: '26',
    },
    future: 1,
  },
  329: {
    value: {
      1: '27',
    },
    future: 1,
  },
  330: {
    value: {
      1: '28',
    },
    future: 1,
  },
  331: {
    value: {
      1: '29',
    },
    future: 1,
  },
  332: {
    value: {
      1: '30',
    },
    future: 1,
  },
  333: {
    value: {
      1: '31',
    },
    future: 1,
  },
  334: {
    value: {
      1: '32',
    },
    future: 1,
  },
  335: {
    value: {
      1: '33',
    },
    future: 1,
  },
  336: {
    value: {
      1: '34',
    },
    future: 1,
  },
  337: {
    value: {
      1: '35',
    },
    future: 1,
  },
  338: {
    value: {
      1: '36',
    },
    future: 1,
  },
  339: {
    value: {
      1: '37',
    },
    future: 1,
  },
  340: {
    value: {
      1: '38',
    },
    future: 1,
  },
  341: {
    value: {
      1: '39',
    },
    future: 1,
  },
  342: {
    value: {
      1: '40',
    },
    future: 1,
  },
  343: {
    value: {
      1: '41',
    },
    future: 1,
  },
  344: {
    value: {
      1: '42',
    },
    future: 1,
  },
  345: {
    value: {
      1: '43',
    },
    future: 1,
  },
  346: {
    value: {
      1: '44',
    },
    future: 1,
  },
  347: {
    value: {
      1: '45',
    },
    future: 1,
  },
  348: {
    value: {
      1: '46',
    },
    future: 1,
  },
  349: {
    value: {
      1: '47',
    },
    future: 1,
  },
  350: {
    value: {
      1: '48',
    },
    future: 1,
  },
};

export { getMacroShortcut };
