import { createStore } from 'vuex';
import { assign } from 'lodash';
import storeConfig from './storeConfig';
import gamesBetslip from '@nsftx/games-sdk-js/src/store/modules/gamesBetslip';
import notifications from '@nsftx/games-sdk-js/src/store/modules/notifications';
import user from '@nsftx/games-sdk-js/src/store/modules/user';
import translations from '@nsftx/games-sdk-js/src/store/modules/translations';
import rules from '@nsftx/games-sdk-js/src/store/modules/rules';

export default createStore(
  assign(storeConfig, {
    modules: { gamesBetslip, notifications, user, translations, rules },
  }),
);
