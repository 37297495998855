export default {
  eventInProgress: (state) => {
    const { eventName } = state;
    const events = ['new', 'StartEvent', 'bettingDisabled', 'StopBetting'];
    return eventName && events.indexOf(eventName) < 0;
  },
  isFutureBetActive: () => false,
  offerList: (state) => state.offerList,
  eventId: (state) => state.eventId,
  activeOffer: (state) => state.activeOffer,
  odds: (state) => state.activeOffer.odds,
};
