export default {
  gameColor: '#911717',
  futureBet: false,
  systemBet: false,
  additionalBetslipConfig: true,
  betslipConfiguration: {},
  adapters: [
    {
      name: 'GcmAdapter',
      type: 'Product',
      channels: [],
    },
  ],
  bettingType: 'races',
  ticketType: 'ngsTicket',
  bettingInputs: [
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 1,
      betId: 0,
      betType: 'normal',
      betDisplayName: 'winner',
      shortcut: '+',
      inputType: 'text',
      inputFields: 6,
      betName: 'winner',
      additionalInputs: [],
      outcomeShortcuts: [
        {
          outcome: null,
          shortcut: 'd',
          action: {
            name: 'auto_fill',
          },
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 6,
        },
        {
          type: 'pattern',
          expression: /^[1-6]$/,
        },
      ],
      betValidations: [
        {
          type: 'unique',
        },
        {
          type: 'length',
          max: 6,
          min: 1,
        },
        {
          type: 'validOdd',
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 2,
      betId: 1,
      betType: 'special',
      betDisplayName: 'show',
      shortcut: 'i',
      inputType: 'text',
      inputFields: 1,
      betName: 'show',
      additionalInputs: [],
      outcomeShortcuts: [
        {
          outcome: 1,
          shortcut: '1',
        },
        {
          outcome: 2,
          shortcut: '2',
        },
        {
          outcome: 3,
          shortcut: '3',
        },
        {
          outcome: 4,
          shortcut: '4',
        },
        {
          outcome: 5,
          shortcut: '5',
        },
        {
          outcome: 6,
          shortcut: '6',
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 6,
        },
        {
          type: 'pattern',
          expression: /^[1-6]$/,
        },
      ],
      betValidations: [
        {
          type: 'length',
          min: 1,
        },
        {
          type: 'validOdd',
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 3,
      betId: 2,
      betType: 'special',
      betDisplayName: 'place',
      shortcut: 't',
      inputType: 'text',
      inputFields: 1,
      betName: 'place',
      additionalInputs: [],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 6,
        },
        {
          type: 'pattern',
          expression: /^[1-6]$/,
        },
      ],
      outcomeShortcuts: [
        {
          outcome: 1,
          shortcut: '1',
        },
        {
          outcome: 2,
          shortcut: '2',
        },
        {
          outcome: 3,
          shortcut: '3',
        },
        {
          outcome: 4,
          shortcut: '4',
        },
        {
          outcome: 5,
          shortcut: '5',
        },
        {
          outcome: 6,
          shortcut: '6',
        },
      ],
      betValidations: [
        {
          type: 'length',
          min: 1,
        },
        {
          type: 'validOdd',
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 4,
      betId: 3,
      betType: 'special',
      betDisplayName: 'sum_first_three',
      limit: 10.5,
      outcomes: ['under', 'over'],
      shortcut: 'w',
      inputType: 'text',
      inputFields: 1,
      betName: 'sum3',
      additionalInputs: [],
      outcomeShortcuts: [
        {
          outcome: 1,
          shortcut: '1',
        },
        {
          outcome: 2,
          shortcut: '2',
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 2,
        },
        {
          type: 'pattern',
          expression: /^[1,2]$/,
        },
      ],
      betValidations: [
        {
          type: 'length',
          min: 1,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 5,
      betId: 4,
      betType: 'special',
      betDisplayName: 'sum_first_two',
      limit: 7.5,
      outcomes: ['under', 'over'],
      shortcut: 'e',
      inputType: 'text',
      inputFields: 1,
      betName: 'sum2',
      additionalInputs: [],
      outcomeShortcuts: [
        {
          outcome: 1,
          shortcut: '1',
        },
        {
          outcome: 2,
          shortcut: '2',
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 2,
        },
        {
          type: 'pattern',
          expression: /^[1,2]$/,
        },
      ],
      betValidations: [
        {
          type: 'length',
          min: 1,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 6,
      betId: 5,
      betType: 'special',
      betDisplayName: 'not_winner',
      shortcut: 'o',
      inputType: 'text',
      inputFields: 1,
      betName: 'notWinner',
      additionalInputs: [],
      outcomeShortcuts: [
        {
          outcome: 1,
          shortcut: '1',
        },
        {
          outcome: 2,
          shortcut: '2',
        },
        {
          outcome: 3,
          shortcut: '3',
        },
        {
          outcome: 4,
          shortcut: '4',
        },
        {
          outcome: 5,
          shortcut: '5',
        },
        {
          outcome: 6,
          shortcut: '6',
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 6,
        },
        {
          type: 'pattern',
          expression: /^[1-6]$/,
        },
      ],
      betValidations: [
        {
          type: 'length',
          min: 1,
        },
        {
          type: 'validOdd',
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 7,
      betId: 6,
      betType: 'special',
      betDisplayName: 'not_place',
      shortcut: 'g',
      inputType: 'text',
      inputFields: 1,
      betName: 'out2',
      additionalInputs: [],
      outcomeShortcuts: [
        {
          outcome: 1,
          shortcut: '1',
        },
        {
          outcome: 2,
          shortcut: '2',
        },
        {
          outcome: 3,
          shortcut: '3',
        },
        {
          outcome: 4,
          shortcut: '4',
        },
        {
          outcome: 5,
          shortcut: '5',
        },
        {
          outcome: 6,
          shortcut: '6',
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 6,
        },
        {
          type: 'pattern',
          expression: /^[1-6]$/,
        },
      ],
      betValidations: [
        {
          type: 'length',
          min: 1,
        },
        {
          type: 'validOdd',
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 8,
      betId: 7,
      betType: 'special',
      betDisplayName: 'not_show',
      shortcut: 'h',
      inputType: 'text',
      inputFields: 1,
      betName: 'out3',
      additionalInputs: [],
      outcomeShortcuts: [
        {
          outcome: 1,
          shortcut: '1',
        },
        {
          outcome: 2,
          shortcut: '2',
        },
        {
          outcome: 3,
          shortcut: '3',
        },
        {
          outcome: 4,
          shortcut: '4',
        },
        {
          outcome: 5,
          shortcut: '5',
        },
        {
          outcome: 6,
          shortcut: '6',
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 6,
        },
        {
          type: 'pattern',
          expression: /^[1-6]$/,
        },
      ],
      betValidations: [
        {
          type: 'length',
          min: 1,
        },
        {
          type: 'validOdd',
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 9,
      betId: 8,
      betType: 'special',
      betDisplayName: 'winner_even_odd',
      outcomes: ['even', 'odd'],
      shortcut: 'p',
      inputType: 'text',
      inputFields: 1,
      betName: 'evenOdd',
      additionalInputs: [],
      outcomeShortcuts: [
        {
          outcome: 1,
          shortcut: '1',
        },
        {
          outcome: 2,
          shortcut: '2',
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 2,
        },
        {
          type: 'pattern',
          expression: /^[1,2]$/,
        },
      ],
      betValidations: [
        {
          type: 'length',
          min: 1,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 10,
      betId: 9,
      betType: 'special',
      betDisplayName: 'winner_under_over',
      outcomes: ['under', 'over'],
      shortcut: 'r',
      inputType: 'text',
      inputFields: 1,
      betName: 'higherLower',
      additionalInputs: [],
      outcomeShortcuts: [
        {
          outcome: 1,
          shortcut: '1',
        },
        {
          outcome: 2,
          shortcut: '2',
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 2,
        },
        {
          type: 'pattern',
          expression: /^[1,2]$/,
        },
      ],
      betValidations: [
        {
          type: 'length',
          min: 1,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 11,
      betId: 10,
      betType: 'normal',
      betDisplayName: 'forecast',
      shortcut: 'f',
      inputType: 'text',
      inputFields: 12,
      betName: 'forecast',
      inputRows: 2,
      additionalInputs: [],
      outcomeShortcuts: [
        {
          outcome: null,
          shortcut: 'd',
          action: {
            name: 'auto_fill',
          },
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 6,
        },
        {
          type: 'pattern',
          expression: /^[1-6]$/,
        },
      ],
      betValidations: [
        {
          type: 'validForecast',
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 12,
      betId: 11,
      betType: 'special',
      betDisplayName: 'tricast',
      shortcut: 'j',
      inputType: 'text',
      inputFields: 18,
      betName: 'tricast',
      inputRows: 3,
      additionalInputs: [],
      outcomeShortcuts: [
        {
          outcome: null,
          shortcut: 'd',
          action: {
            name: 'auto_fill',
          },
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 6,
        },
        {
          type: 'pattern',
          expression: /^[1-6]$/,
        },
      ],
      betValidations: [
        {
          type: 'validTricast',
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 13,
      betId: 12,
      betType: 'special',
      betDisplayName: 'reverse_forecast',
      shortcut: 's',
      inputType: 'text',
      inputFields: 6,
      additionalInputs: [],
      outcomeShortcuts: [
        {
          outcome: null,
          shortcut: 'd',
          action: {
            name: 'auto_fill',
          },
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 6,
        },
        {
          type: 'pattern',
          expression: /^[1-6]$/,
        },
      ],
      betValidations: [
        {
          type: 'unique',
        },
        {
          type: 'length',
          max: 6,
          min: 2,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 14,
      betId: 13,
      betType: 'special',
      betDisplayName: 'reverse_tricast',
      shortcut: 'k',
      inputType: 'text',
      inputFields: 6,
      additionalInputs: [],
      outcomeShortcuts: [
        {
          outcome: null,
          shortcut: 'd',
          action: {
            name: 'auto_fill',
          },
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 6,
        },
        {
          type: 'pattern',
          expression: /^[1-6]$/,
        },
      ],
      betValidations: [
        {
          type: 'unique',
        },
        {
          type: 'length',
          max: 6,
          min: 3,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: false,
      label: '',
      clientId: 15,
      betId: 0,
      betType: 'custom',
      betDisplayName: 'winner',
      shortcut: '+',
      inputType: 'text',
      inputFields: 1,
      betName: 'winner',
      additionalInputs: ['system'],
      outcomeShortcuts: [
        {
          outcome: null,
          shortcut: 'd',
          action: {
            name: 'auto_fill',
          },
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'pattern',
          expression: /^(?!.*(.).*\1)[1-6]{1,6}$/,
        },
      ],
      betValidations: [
        {
          type: 'unique',
        },
        {
          type: 'length',
          max: 6,
          min: 1,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
  ],
  additionalBettingInputs: [
    {
      content: 'bettingInput',
      enabled: true,
      label: 'system',
      clientId: 'system',
      betId: 0,
      betType: 'system',
      betDisplayName: 'system',
      shortcut: 's',
      inputType: 'text',
      inputFields: 1,
      betName: 'system',
      outcomeShortcuts: [
        {
          outcome: null,
          shortcut: 'd',
          action: {
            name: 'auto_fill',
          },
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'pattern',
          expression: /^(?!.*(.).*\1)[1-6]{1,6}$/,
        },
      ],
      betValidations: [
        {
          type: 'validForecast',
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
  ],
  shortcuts: {
    '+': {
      id: 1,
      action: 'ticket',
      active: true,
      name: 'winner',
      shortcut: '+',
      order: 1,
    },
    '+ ': {
      id: 15,
      action: 'ticket',
      active: false,
      name: 'winner',
      shortcut: '+ ',
      order: 2,
    },
    '/': {
      action: 'resetTicket',
      active: true,
      name: 'reset_ticket',
      shortcut: '/',
      order: 3,
    },
    q: {
      action: 'results',
      active: true,
      name: 'results',
      shortcut: 'q',
      order: 4,
    },
    's ': {
      id: 15,
      action: 'system',
      active: false,
      name: 'system',
      shortcut: 's ',
      order: 5,
    },
    d: {
      action: 'auto_fill',
      active: true,
      name: 'auto_fill',
      shortcut: 'd',
      order: 6,
    },
    'ctrl + m': {
      action: 'focusFirstBet',
      active: true,
      name: 'focus_first_bet',
      shortcut: 'ctrl + m',
      order: 7,
    },
    'ctrl + s': {
      action: 'editStakePerBet',
      active: true,
      name: 'edit_stake_per_bet',
      shortcut: 'ctrl + s',
      order: 8,
    },
    'ctrl + b': {
      action: 'focusFirstInput',
      active: true,
      name: 'focus_first_input',
      shortcut: 'ctrl + b',
      order: 9,
    },
    'ctrl + e': {
      action: 'toggleEditMode',
      active: false,
      name: 'toggle_delete_bet_mode',
      shortcut: 'ctrl + e',
      order: 10,
    },
    delete: {
      action: 'deleteBet',
      active: false,
      name: 'delete_bet',
      shortcut: 'delete',
      order: 11,
    },
    i: {
      id: 2,
      action: 'show',
      active: true,
      name: 'show',
      shortcut: 'i',
      order: 12,
    },
    t: {
      id: 3,
      action: 'place',
      active: true,
      name: 'place',
      shortcut: 't',
      order: 13,
    },
    w: {
      id: 4,
      action: 'sumFirstThree',
      active: true,
      name: 'sum_first_three',
      shortcut: 'w',
      order: 14,
    },
    e: {
      id: 5,
      action: 'sumFirstTwo',
      active: true,
      name: 'sum_first_two',
      shortcut: 'e',
      order: 15,
    },
    o: {
      id: 6,
      action: 'notWinner',
      active: true,
      name: 'not_winner',
      shortcut: 'o',
      order: 16,
    },
    g: {
      id: 7,
      action: 'notPlace',
      active: true,
      name: 'not_place',
      shortcut: 'g',
      order: 17,
    },
    h: {
      id: 8,
      action: 'notShow',
      active: true,
      name: 'not_show',
      shortcut: 'h',
      order: 18,
    },
    p: {
      id: 9,
      action: 'winnerEvenOdd',
      active: true,
      name: 'winner_even_odd',
      shortcut: 'p',
      order: 19,
    },
    r: {
      id: 10,
      action: 'winnerHighLow',
      active: true,
      name: 'winner_under_over',
      shortcut: 'r',
      order: 20,
    },
    f: {
      id: 11,
      action: 'forecast',
      active: true,
      name: 'forecast',
      shortcut: 'f',
      order: 21,
    },
    j: {
      id: 12,
      action: 'tricast',
      active: true,
      name: 'tricast',
      shortcut: 'j',
      order: 22,
    },
    s: {
      id: 13,
      action: 'reverseForecast',
      active: true,
      name: 'reverse_forecast',
      shortcut: 's',
      order: 23,
    },
    k: {
      id: 14,
      action: 'reverseTricast',
      active: true,
      name: 'reverse_tricast',
      shortcut: 'k',
      order: 24,
    },
    m: {
      action: 'quickBetslipMode',
      active: false,
      name: 'enable_quick_betslip_mode',
      shortcut: 'm',
      order: 22,
    },
  },
  availableTicketActions: [
    {
      status: 'accepted',
      action: ['check', 'copy'],
    },
    {
      status: 'canceled',
      action: [],
    },
    {
      status: 'paidout',
      action: [],
    },
    {
      status: 'pending',
      action: ['checkStatus'],
    },
    {
      status: 'unknown',
      action: ['checkStatus'],
    },
    {
      status: 'failed',
      action: ['checkStatus'],
    },
    {
      status: 'payedout',
      action: [],
    },
  ],
  actionTypes: {
    add: 'TicketPayIn',
    payout: 'TicketPayout',
    cancel: 'TicketCancel',
    copy: 'TicketCopy',
  },
  printLayoutType: {
    add: 'ticketVirtualGreyhoundRaces',
    payout: 'ticketVirtualGreyhoundRacesPayout',
    cancel: 'ticketCancel',
    results: 'resultsVirtualGreyhoundRaces',
  },
};
