import types from './mutationTypes';

export default {
  [types.SET_SYSTEM_VALUE](state, systemValue) {
    state.betInputData.system = systemValue;
  },
  [types.SET_OFFER_LIST](state, offerList) {
    state.offerList = offerList;
  },
  [types.SET_ACTIVE_OFFER](state, activeOffer) {
    state.activeOffer = activeOffer;
  },
  [types.SET_EVENT_ID](state, eventId) {
    state.eventId = eventId;
  },
};
