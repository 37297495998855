import types from './mutationTypes';

export default {
  [types.UPDATE_FUTURE](state, future) {
    state.betInputData.future = future;
  },
  [types.UPDATE_BETSLIP_TICKET](state, roundNumber) {
    state.betslipBets.forEach((bet) => {
      bet.roundNumber = roundNumber;
    });
  },
};
