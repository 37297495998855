export default {
  SET_EVENT_TIME: 'SET_EVENT_TIME',
  SET_EVENT_DISPLAY_ID: 'SET_EVENT_DISPLAY_ID',
  SET_EVENT_NAME: 'SET_EVENT_NAME',
  SET_BETTING_DISABLED: 'SET_BETTING_DISABLED',
  SET_SINGLE_BET_INPUT_VALUE: 'SET_SINGLE_BET_INPUT_VALUE',
  SET_BET_INPUT_VALUE: 'SET_BET_INPUT_VALUE',
  RESET_BET_INPUT_VALUE: 'RESET_BET_INPUT_VALUE',
  SET_SELECTED_BET: 'SET_SELECTED_BET',
  STORE_BETSLIP_TICKET: 'STORE_BETSLIP_TICKET',
  SET_ADDITIONAL_INPUT: 'SET_ADDITIONAL_INPUT',
  CLEAR_ADDITIONAL_INPUTS: 'CLEAR_ADDITIONAL_INPUTS',
  ADD_ACTIVE_NOTIFICATION: 'ADD_ACTIVE_NOTIFICATION',
  REMOVE_ACTIVE_NOTIFICATION: 'REMOVE_ACTIVE_NOTIFICATION',
  SET_FOOTER_VALUES: 'SET_FOOTER_VALUES',
  SET_CONNECTION_STATUS: 'SET_CONNECTION_STATUS',
  CLEAR_BETSLIP_TICKET: 'CLEAR_BETSLIP_TICKET',
  SET_COUNTDOWN_INTERVAL: 'SET_COUNTDOWN_INTERVAL',
  SET_COUNTDOWN_WORKER: 'SET_COUNTDOWN_WORKER',
  TOGGLE_QUICK_BETSLIP_MODE: 'TOGGLE_QUICK_BETSLIP_MODE',
};
