import { eventBus, betslipUtility, capitalizeFirstLetter, ticketCheck } from '../utility';
import store from '../store';
import { isEmpty } from 'lodash';

const successTicketHandler = function (data) {
  // console.log(' successTicketHandler ', data);
  eventBus.$emit('resetInput');
  store.dispatch('clearBetslip');
  const ticket = data.productTicket || data;
  const ticketVersion = ticketCheck.getTicketVersion(ticket);

  // Send message over GGateway event 'Tickets.Update' to update state of ticket
  const ticketData = {
    action: capitalizeFirstLetter(store.state.ticketAction), // available values: Add, Cancel, Payout
    ticket: ticketVersion === '2' ? betslipUtility.prepareSevenTicketData(ticket) : ticket,
  };
  // console.log(' ticketData ', ticketData);
  eventBus.$emit('ticketUpdate', ticketData);
  // 8693v40hz
  if (store.state.ticketAction === 'Cancel' && data.status.value !== 'canceled') return;
  if (store.state.ticketAction === 'Payout' && data.status.value !== 'paidout') return;
  if (store.state.ticketActionSuccessful && data.status.value !== 'invalid') {
    eventBus.$emit('printTemplate', ticketData);
  }
};

// TODO WHOLE ERROR HANDLING SHOULD BE REFACTORED (UNREADABLE AND BUGGY)
const errorTicketHandler = (error, ticket = {}) => {
  // Filter out axios network errors
  if (error.code === 'ERR_NETWORK' || error.code === 'ECONNABORTED') {
    console.warn('NETWORK ERROR', error);
    return;
  }
  // console.log(' errorTicketHandler ', error);
  // console.log(' errorTicketHandler ticket ', ticket);
  const errorMessage = store.getters.getTranslation(
    error.response?.data?.message || error.response?.data?.error || error.message,
  );

  const ticketData = {
    ticket: {
      status: { value: 'REJECTED' },
    },
  };

  if (error.code === 400404) ticketData.showMessage = false;

  if (!isEmpty(ticket)) {
    ticket.action = capitalizeFirstLetter(ticket.action);
    ticketData.action = capitalizeFirstLetter(ticket.action);
    ticketData.ticket.payin = store.getters.config.rules?.minBetAmount.value;
    ticketData.ticket.requestUuid = ticket.requestUuid;

    // Handling axios request creation errors
    // If error isn't from backend don't send ticket update
    // Covers case where ticketUpdate and ticketCheck responses are missing on ticket payin action
    if (error.response?.status === 404) {
      eventBus.$emit('ticketUpdate', ticketData);
      return;
    }
  } else {
    if (error.status?.value === 'REJECTED') {
      ticketData.ticket.requestUuid = error.requestUuid;
    } else {
      // For case where the ticket is neither rejected nor paid in
      ticketData.ticket.id = error.id ? error.id : error.code;
      ticketData.ticket.requestUuid = error.requestId;
    }

    ticketData.action = error.action === 'PayIn' ? 'Add' : error.action; // Available actions are: Add, Cancel Payout
    ticketData.ticket.product = error.product;

    if (ticketData.action) eventBus.$emit('ticketUpdate', ticketData);
  }

  store.dispatch('sendGGMessage', { message: errorMessage, delay: 5000 });
};

const successTicketCheckHandler = function (data) {
  eventBus.$emit('resetInput');
  store.dispatch('clearBetslip');
  const ticket = data.productTicket || data;
  const ticketVersion = ticketCheck.getTicketVersion(ticket);

  // Send message over GGateway event 'Tickets.Update' to update state of ticket
  const ticketData = {
    action: capitalizeFirstLetter(store.state.ticketAction), // available values: Add, Cancel, Payout
    ticket: ticketVersion === '2' ? betslipUtility.prepareSevenTicketData(ticket) : ticket,
  };
  eventBus.$emit('ticketUpdate', ticketData);
  // 8693v40hz
  if (store.state.ticketAction === 'Cancel' && data.status.value !== 'canceled') return;
  if (store.state.ticketAction === 'Payout' && data.status.value !== 'paidout') return;
  if (store.state.ticketActionSuccessful && data.status.value !== 'invalid') {
    const ticket = {
      id: data.id,
      requestUuid: data.requestUuid,
    };
    const { ticketsPrintedByTicketUpdate, printedTicketsByTicketCheck } = store.getters;
    // Check if in the meantime the ticket update caused ticket print
    if (ticketsPrintedByTicketUpdate.some((printedTicket) => printedTicket.requestUuid === ticket.requestUuid)) return;
    if (printedTicketsByTicketCheck.some((printedTicket) => printedTicket.requestUuid === ticket.requestUuid)) return;
    store.commit('SET_PRINTED_TICKET_BY_TICKET_CHECK', ticket);
    eventBus.$emit('printTemplate', ticketData);
  }
};

const errorHandler = (error) => {
  let errorMessage;
  if (error.response) {
    // The server responded with a status code outside the 2xx range
    errorMessage = store.getters.getTranslation(
      error.response?.data?.message || error.response?.data?.error || error.message,
    );
  } else if (error.request) {
    errorMessage = 'Network error';
    // The request was made but no response was received
    console.log('Request got no response', error);
  } else {
    errorMessage = 'Network error';
    console.log('Request setup error', error);
    // Something happened in setting up the request that triggered an error
  }
  store.dispatch('sendGGMessage', { message: errorMessage, delay: 5000 });
};

export { successTicketHandler, successTicketCheckHandler, errorTicketHandler, errorHandler };
