import types from './mutationTypes';

export default {
  [types.SET_EVENT_TIME](state, payload) {
    state.eventTime = payload;
  },
  [types.SET_EVENT_DISPLAY_ID](state, payload) {
    state.eventDisplayId = payload;
  },
  [types.SET_EVENT_NAME](state, payload) {
    state.eventName = payload;
  },
  [types.SET_BETTING_DISABLED](state, bettingDisabled) {
    state.bettingDisabled = bettingDisabled;
  },
  [types.SET_SINGLE_BET_INPUT_VALUE](state, { inputId, inputValue }) {
    state.betInputData.value[inputId] = inputValue;
  },
  [types.SET_BET_INPUT_VALUE](state, inputValue) {
    state.betInputData.value = inputValue;
  },
  [types.RESET_BET_INPUT_VALUE](state) {
    state.betInputData.value = {};
  },
  [types.CLEAR_BETSLIP_TICKET](state) {
    state.betslipBets = [];
  },
  [types.SET_SELECTED_BET](state, selectedBet) {
    state.selectedBet = selectedBet;
  },
  [types.STORE_BETSLIP_TICKET](state, ticket) {
    state.betslipBets = ticket;
  },
  [types.SET_ADDITIONAL_INPUT](state, additionalInput) {
    const index = state.additionalInputs.findIndex((item) => item.clientId === additionalInput.clientId);

    if (index !== -1) {
      state.additionalInputs.splice(index, 1);
    } else {
      state.additionalInputs.push(additionalInput);
    }
  },
  [types.CLEAR_ADDITIONAL_INPUTS](state) {
    state.additionalInputs = [];
  },
  [types.ADD_ACTIVE_NOTIFICATION](state, notification) {
    state.activeNotifications.push(notification);
  },
  [types.REMOVE_ACTIVE_NOTIFICATION](state, id) {
    state.activeNotifications = state.activeNotifications.filter((activeNotification) => activeNotification.id !== id);
  },
  [types.SET_FOOTER_VALUES](state, payload) {
    state.betslipFooterValues = payload;
  },
  [types.SET_CONNECTION_STATUS](state, payload) {
    state.connectionActive = payload;
  },
  [types.SET_COUNTDOWN_INTERVAL](state, countdownInterval) {
    state.countdownInterval = countdownInterval;
  },
  [types.SET_COUNTDOWN_WORKER](state, countdownWorker) {
    state.countdownWorker = countdownWorker;
  },
  [types.TOGGLE_QUICK_BETSLIP_MODE](state) {
    state.quickBetslipModeActive = !state.quickBetslipModeActive;
  },
};
