import types from './mutationTypes';

export default {
  [types.UPDATE_ODDS_RULES](state, maxPossibleMultiplier) {
    state.localGameConfig.oddRules.maxOdd = maxPossibleMultiplier;
  },
  [types.UPDATE_BETSLIP_TICKET](state, roundNumber) {
    state.betslipBets.forEach((bet) => {
      bet.roundNumber = roundNumber;
    });
  },
  [types.UPDATE_FUTURE](state, future) {
    state.betInputData.future = future;
  },
  [types.SET_UPCOMING_EVENTS](state, upcomingEvents) {
    state.upcomingEvents = upcomingEvents;
  },
  [types.SET_RECONNECT_STATE](state, value) {
    state.connectionReconnectActive = value;
  },
  [types.SET_SOCKET_REINIT_IN_PROGRESS](state, value) {
    state.socketReInitInProgress = value;
  },
};
