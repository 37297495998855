import { merge, cloneDeep } from 'lodash';
import { busService } from '../utility';
import types from './mutationTypes';

export default {
  [types.SET_TICKET_PREVIEW_DATA](state, ticketPreviewData) {
    state.ticketPreviewData = ticketPreviewData || null;
  },
  [types.SET_TICKET_PREVIEW_STATUS](state, payload) {
    state.ticketPreviewActive = payload;
  },
  [types.SET_TICKET_PREVIEW_TICKET](state, { ticket, ticketVersion }) {
    state.ticketPreviewTicket = ticket;
    state.ticketPreviewTicketVersion = ticketVersion;
  },
  [types.SEND_GG_MESSAGE](state, payload) {
    const { action, data } = payload;
    busService.sendMessage(action, cloneDeep(data));
  },
  [types.SET_TICKET_ACTION](state, payload) {
    state.ticketAction = payload;
  },
  [types.SET_TRANSLATIONS](state, payload) {
    state.translations = merge({}, state.translations, payload);
  },
  [types.SET_TICKET_ACTION_SUCCESSFUL](state, payload) {
    state.ticketActionSuccessful = payload;
  },
  [types.SET_AUTO_PAYOUT](state, autoPayout) {
    state.autoPayout = autoPayout;
  },
  [types.SET_PAYOUT_CONFIRMATION](state, payoutConfirmation) {
    state.payoutConfirmation = payoutConfirmation;
  },
  [types.SET_PAYOUT_CONFIRMATION_REPRINT](state, payoutConfirmationReprint) {
    state.payoutConfirmationReprint = payoutConfirmationReprint?.enabled ?? true;
  },
  [types.SET_DEVICE_UUID](state, device) {
    state.deviceId = device.uuid;
  },
  [types.SET_USER_TOKEN](state, token) {
    if (token) state.token = token;
  },
  [types.SET_PRINTED_TICKET_BY_TICKET_CHECK](state, ticket) {
    state.printedTicketsByTicketCheck.push(ticket);
  },
  [types.REMOVE_PRINTED_TICKET_BY_TICKET_CHECK](state, requestUuid) {
    const newArr = state.printedTicketsByTicketCheck.filter((ticket) => ticket.requestUuid !== requestUuid);
    state.printedTicketsByTicketCheck = newArr;
  },
  [types.SET_TICKET_TO_BE_CHECKED](state, ticket) {
    state.ticketsScheduledToBeChecked.push(ticket);
  },
  [types.REMOVE_TICKET_TO_BE_CHECKED](state, requestUuid) {
    const newArr = state.ticketsScheduledToBeChecked.filter((ticket) => ticket.requestUuid !== requestUuid);
    state.ticketsScheduledToBeChecked = newArr;
  },
  [types.ADD_PRINTED_TICKET_BY_TICKET_UPDATE](state, ticket) {
    state.ticketsPrintedByTicketUpdate.push(ticket);
  },
  [types.UPDATE_PRINTED_TICKETS_ARRAY_BY_TICKET_UPDATE](state, ticketsArray) {
    state.ticketsPrintedByTicketUpdate = ticketsArray;
  },
  [types.SET_TICKET_CHECK_IN_PROGRESS](state, ticket) {
    state.ticketChecksInProgress.push(ticket);
  },
  [types.REMOVE_TICKET_CHECK_IN_PROGRESS](state, requestUuid) {
    const newArr = state.printedTicketsByTicketCheck.filter((ticket) => ticket.requestUuid !== requestUuid);
    state.ticketChecksInProgress = newArr;
  },
  //* COMMON MUTATIONS
  [types.SET_ACTIVE_GAME](state, currentActiveGame) {
    state.currentActiveGame = currentActiveGame;
  },
  [types.SET_BETTING_CONFIG](state, config) {
    state[state.currentActiveGame].localGameConfig = config;
  },
  [types.SET_GAME_NOT_ACTIVE](state, { errorMessage, game }) {
    state[game].isGameActive = false;
    state[game].errorMessage = errorMessage;
  },
  [types.SET_APP_SHOULD_CONTINUE_INIT](state, shouldContinueInit) {
    state[state.currentActiveGame].appShouldCountinueInit = shouldContinueInit;
  },
  [types.ADD_ACTIVE_ADAPTER](state, adapter) {
    state.activeBusAdapters.push(adapter);
  },
  [types.SET_PRODUCT_CONFIG](state, { config, game }) {
    state[game].config = config;
  },
  [types.CLEAR_GAMES_BETSLIP_TICKET](state) {
    state.gamesBetslip.ticket = [];
  },
  [types.SET_BETSLIP_FOOTER_VALUES](state, payload) {
    state.gamesBetslip.futureRounds = payload.futureRounds;
    state.gamesBetslip.paymentPerBet = payload.paymentPerBet;
    state.gamesBetslip.totalPayment = payload.totalPayment;
  },
  [types.SET_BETSLIP_TICKET](state, ticket) {
    state.gamesBetslip.ticket = ticket;
  },
  [types.SET_BETSHOP_UUID](state, id) {
    state.betshopId = id;
  },
  [types.SET_DEVICE_TOKEN](state, deviceToken) {
    state.deviceToken = deviceToken;
  },
  [types.SET_COMPANY_ID](state, companyId) {
    state.companyId = companyId;
  },
  [types.SET_COMPANY_NAME](state, companyName) {
    state.companyName = companyName;
  },
  [types.SET_APP_LOADED](state, appLoaded) {
    state.isAppLoaded = appLoaded;
  },
  [types.SET_CLIENT_APP_MODULES](state, clientAppModules) {
    state.clientAppModules = clientAppModules;
  },
  [types.SET_ACTIVE_PRODUCTS](state, activeProducts) {
    state.activeProducts = activeProducts;
  },
  [types.SET_PREVENT_DEFAULT_KEYS_ARRAY](state, keysArray) {
    state.preventDefaultKeys = keysArray;
  },
  [types.SET_STATIC_CONFIG](state, config) {
    state.staticConfig = config;
    state.staticConfigFetched = true;
  },
  [types.SET_STATIC_CONFIG_PER_GAME](state, { game, config }) {
    state[game].staticConfig = config;
  },
  [types.SET_GAMES_STATE](state, activeProducts) {
    activeProducts.forEach(({ productName }) => {
      state.gamesState[productName] = {
        loading: true,
        configLoaded: false,
        busLoaded: false,
        retryAttempts: 0,
      };
    });
  },
  [types.SET_GAME_LOADING](state, { game, loading }) {
    state.gamesState[game].loading = loading;
  },
  [types.SET_CONFIG_LOADED](state, { game, configLoaded }) {
    state.gamesState[game].configLoaded = configLoaded;
  },
  [types.SET_BUS_LOADED](state, { game, busLoaded }) {
    state.gamesState[game].busLoaded = busLoaded;
    state.gamesState[game].loading = false;
  },
  [types.CONFIG_RETRY_ATTEMPT_INCREMENT](state, game) {
    state.gamesState[game].retryAttempts += 1;
  },
  [types.SET_CMS_WIDGET_SETTINGS](state, widgetSettings) {
    state.widgetSettings = widgetSettings;
  },
  [types.SET_EDIT_BET_MODE_ACTIVE](state, value) {
    state.editBetModeActive = value;
  },
  [types.SET_BET_EDIT_BET](state, bet) {
    state.betEditBet = bet;
  },
  [types.CLEAR_BET_EDIT_BET](state) {
    state.betEditBet = {};
    state.betEditInputId = null;
  },
  [types.SET_BET_EDIT_INPUT_ID](state, betInputId) {
    state.betEditInputId = betInputId;
  },
  [types.SET_OPERATOR_PERMISSIONS](state) {
    state.activeProducts.forEach(({ productName }) => {
      const { actions } = state.clientAppModules.find((module) => module.id.toLowerCase() === productName);
      const canPrintCopy = actions?.some((action) => action.id === 'ticketReprint');
      state.operatorPermissions[productName] = {
        canPrintCopy,
      };
    });
  },
  [types.SET_AFTERLOAD_ACTIVE_GAME](state, game) {
    state.afterloadActiveGame = game;
  },
};
