export default {
  eventInProgress: (state) => {
    const { eventName } = state;
    const events = ['countdown', 'StartCountdown', 'bettingDisabled', 'StopBetting'];
    return eventName && events.indexOf(eventName) < 0;
  },
  isFutureBetActive: (state) => state.additionalInputs.filter((input) => input.betType === 'future')[0] ?? false,
  futureInput: (state) => state.additionalInputs.filter((input) => input.betType === 'future')[0] || {},
  futureValue: (state) => state.betInputData.future,
  keepValidInputOnBetChange: (state, getters) => getters.configFromStatic?.keepValidInputOnBetChange || false,
  disableFutureIfMultiticket: (state, getters) => getters.configFromStatic?.disableFutureIfMultiticket || false,
  macroShortcutsEnabled: (state, getters) => getters.configFromStatic?.macroShortcutsEnabled || false,
  colorBetSelectionOnlyAction: (state, getters) => getters.configFromStatic?.colorBetSelectionOnlyAction || false,
};
