export default {
  config: {},
  //  TODO: support games/products mapper and details
  productList: ['crashcash', 'virtualsoccer', 'greyhoundraces', 'luckysix', 'gamestest'],
  ticketPreviewData: null,
  ticketPreviewActive: false,
  ticketIcons: {
    positive: 'n-i-check-a',
    neutral: 'n-i-clock',
    negative: 'n-i-close',
  },
  ticketAction: '',
  eventInProgress: true,
  ticketActionSuccessful: true,
  autoPayout: false,
  payoutConfirmation: {},
  payoutConfirmationReprint: true,
  deviceId: '',
  printedTicketsByTicketCheck: [],
  ticketsPrintedByTicketUpdate: [],
  ticketChecksInProgress: [],
  ticketsScheduledToBeChecked: [],
  bettingDisabled: false,
  //* COMMON STATE
  currentActiveGame: '',
  activeProducts: [],
  supportedActiveProducts: [
    'luckysix',
    'greyhoundraces',
    'crashcash',
    'virtualgreyhoundraces',
    'virtualhorseraces',
    'virtualmotorcyclespeedway',
  ],
  sevenProductsMapper: {
    greyhoundraces: 'GreyhoundRaces',
    luckysix: 'LuckySix',
    crashcash: 'CrashCash',
    virtualgreyhoundraces: 'VirtualGreyhoundRaces',
    virtualhorseraces: 'VirtualHorseRaces',
    virtualmotorcyclespeedway: 'VirtualMotorcycleSpeedway',
  },
  activeBusAdapters: [],
  globalEvents: [
    'UI.Show',
    'Slave.Unmute',
    'Tickets.GetActions',
    'Tickets.ReCheck',
    'Tickets.Checked',
    'Widget.Event',
    'Master.Event',
    'Tickets.PayingSentSuccess',
    'Tickets.PayingFailed',
    'Tickets.ReBet',
    'Tickets.PrintCopy',
    'Betslip.Blocked',
    'Betslip.Unblocked',
  ],
  isAppLoaded: false,
  staticConfigFetched: false,
  staticConfig: {},
  gamesState: {},
  editBetModeActive: false,
  betEditBet: {},
  betEditInputId: null,
  operatorPermissions: {},
  socketReInitInProgress: false,
  afterloadActiveGame: null, // Used for showing current game when others are still loading
};
