/**
 * Calculating countdown delay depending on event start timestamp and current timestamp
 */
const getTimeDelay = (startTime, currentTime, defaultDelay) => {
  const startTS = parseInt(startTime / 1000, 10);
  const currentTS = parseInt(currentTime / 1000, 10);
  const timeDelay = startTS + defaultDelay - currentTS;

  return timeDelay;
};

export default getTimeDelay;
