export default {
  eventInProgress: (state) => {
    const { eventName } = state;
    const events = ['idle'];
    return eventName && events.indexOf(eventName) < 0;
  },
  isFutureBetActive: (state) => state.additionalInputs.filter((input) => input.betType === 'future')[0] ?? false,
  futureInput: (state) => state.additionalInputs.filter((input) => input.betType === 'future')[0] || {},
  upcomingEvents: (state) => state.upcomingEvents,
  connectionReconnectActive: (state) => state.connectionReconnectActive,
  socketReInitInProgress: (state) => state.socketReInitInProgress,
};
