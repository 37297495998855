export default {
  SET_TICKET_PREVIEW_DATA: 'SET_TICKET_PREVIEW_DATA',
  SET_TICKET_PREVIEW_STATUS: 'SET_TICKET_PREVIEW_STATUS',
  SEND_GG_MESSAGE: 'SEND_GG_MESSAGE',
  SET_TICKET_ACTION: 'SET_TICKET_ACTION',
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  SET_TICKET_ACTION_SUCCESSFUL: 'SET_TICKET_ACTION_SUCCESSFUL',
  SET_AUTO_PAYOUT: 'SET_AUTO_PAYOUT',
  SET_PAYOUT_CONFIRMATION: 'SET_PAYOUT_CONFIRMATION',
  SET_PAYOUT_CONFIRMATION_REPRINT: 'SET_PAYOUT_CONFIRMATION_REPRINT',
  SET_DEVICE_UUID: 'SET_DEVICE_UUID',
  SET_USER_TOKEN: 'SET_USER_TOKEN',
  SET_PRINTED_TICKET_BY_TICKET_CHECK: 'SET_PRINTED_TICKET_BY_TICKET_CHECK',
  REMOVE_PRINTED_TICKET_BY_TICKET_CHECK: 'REMOVE_PRINTED_TICKET_BY_TICKET_CHECK',
  SET_TICKET_CHECK_IN_PROGRESS: 'SET_TICKET_CHECK_IN_PROGRESS',
  REMOVE_TICKET_CHECK_IN_PROGRESS: 'REMOVE_TICKET_CHECK_IN_PROGRESS',
  //* COMMON MUTATION TYPES
  SET_ACTIVE_GAME: 'SET_ACTIVE_GAME',
  SET_BETTING_CONFIG: 'SET_BETTING_CONFIG',
  SET_BETSHOP_UUID: 'SET_BETSHOP_UUID',
  UPDATE_ODDS_RULES: 'UPDATE_ODDS_RULES',
  SET_GAME_NOT_ACTIVE: 'SET_GAME_NOT_ACTIVE',
  SET_APP_SHOULD_CONTINUE_INIT: 'SET_APP_SHOULD_CONTINUE_INIT',
  ADD_ACTIVE_ADAPTER: 'ADD_ACTIVE_ADAPTER',
  SET_PRODUCT_CONFIG: 'SET_PRODUCT_CONFIG',
  CLEAR_GAMES_BETSLIP_TICKET: 'CLEAR_GAMES_BETSLIP_TICKET',
  SET_BETSLIP_TICKET: 'SET_BETSLIP_TICKET',
  SET_DEVICE_TOKEN: 'SET_DEVICE_TOKEN',
  SET_COMPANY_ID: 'SET_COMPANY_ID',
  SET_COMPANY_NAME: 'SET_COMPANY_NAME',
  SET_TICKET_PREVIEW_TICKET: 'SET_TICKET_PREVIEW_TICKET',
  SET_APP_LOADED: 'SET_APP_LOADED',
  SET_CLIENT_APP_MODULES: 'SET_CLIENT_APP_MODULES',
  SET_ACTIVE_PRODUCTS: 'SET_ACTIVE_PRODUCTS',
  SET_STATIC_CONFIG: 'SET_STATIC_CONFIG',
  SET_BETSLIP_FOOTER_VALUES: 'SET_BETSLIP_FOOTER_VALUES',
  SET_GAMES_STATE: 'SET_GAMES_STATE',
  SET_GAME_LOADING: 'SET_GAME_LOADING',
  SET_CONFIG_LOADED: 'SET_CONFIG_LOADED',
  SET_BUS_LOADED: 'SET_BUS_LOADED',
  CONFIG_RETRY_ATTEMPT_INCREMENT: 'CONFIG_RETRY_ATTEMPT_INCREMENT',
  SET_PREVENT_DEFAULT_KEYS_ARRAY: 'SET_PREVENT_DEFAULT_KEYS_ARRAY',
  SET_CMS_WIDGET_SETTINGS: 'SET_CMS_WIDGET_SETTINGS',
  SET_STATIC_CONFIG_PER_GAME: 'SET_STATIC_CONFIG_PER_GAME',
  SET_EDIT_BET_MODE_ACTIVE: 'SET_EDIT_BET_MODE_ACTIVE',
  SET_BET_EDIT_BET: 'SET_BET_EDIT_BET',
  CLEAR_BET_EDIT_BET: 'CLEAR_BET_EDIT_BET',
  SET_BET_EDIT_INPUT_ID: 'SET_BET_EDIT_INPUT_ID',
  SET_OPERATOR_PERMISSIONS: 'SET_OPERATOR_PERMISSIONS',
  SET_TICKET_TO_BE_CHECKED: 'SET_TICKET_TO_BE_CHECKED',
  REMOVE_TICKET_TO_BE_CHECKED: 'REMOVE_TICKET_TO_BE_CHECKED',
  ADD_PRINTED_TICKET_BY_TICKET_UPDATE: 'ADD_PRINTED_TICKET_BY_TICKET_UPDATE',
  UPDATE_PRINTED_TICKETS_ARRAY_BY_TICKET_UPDATE: 'UPDATE_PRINTED_TICKETS_ARRAY_BY_TICKET_UPDATE',
  SET_AFTERLOAD_ACTIVE_GAME: 'SET_AFTERLOAD_ACTIVE_GAME',
};
