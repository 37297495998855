import { BusService } from '@nsftx/games-bus';
import { isEmpty, isNil } from 'lodash';
import store from '../store';
import { eventBus, ticketCheck } from '../utility';
import { ws } from '../api';

let gcmBusService;
let busService;
let busServiceInstanceArray = [];
let skipClientAdapters = false;
const activeAdapterNames = new Set();
/**
 *
 * @param {Object} adapter
 */
const checkAdapterConnection = function (adapter) {
  return new Promise((resolve) => {
    const checkConnection = () => {
      if (adapter.instance.connected) {
        resolve();
      } else {
        setTimeout(checkConnection, 200);
      }
    };
    checkConnection();
  });
};

/**
 *
 * @param {Array} channel
 */
const addDeviceId = function (channel) {
  const { deviceId } = store.state;
  const { config } = store.getters;
  return {
    ...channel,
    name: deviceId || config.deviceId,
  };
};
/**
 *
 * @param {Array} channels
 */
const formatChannels = function (channels) {
  return channels.map((channel) => {
    if (channel.type === 'Device') {
      return addDeviceId(channel);
    }
    return channel;
  });
};
/**
 * Start Bus service
 */
const init = async function (game) {
  const { productId, productName } = game;
  const { deviceId } = store.state;

  const config = store.getters.getGameGetter({ specificGame: productName, getter: 'config' });

  if (isEmpty(config)) {
    store.dispatch('handleGameInitError', { game: productName, reason: 'config_error' });
    return;
  }

  // Get adapters for specified game
  const adapters = store.getters.getGameGetter({ specificGame: productName, getter: 'adapters' });

  // Filter active and inactive adapters
  const { activeAdapters, inactiveAdapters } = adapters.reduce(
    (acc, adapter) => {
      if (activeAdapterNames.has(adapter.name)) {
        acc.activeAdapters.push(adapter);
      } else {
        acc.inactiveAdapters.push(adapter);
      }
      return acc;
    },
    { activeAdapters: [], inactiveAdapters: [] },
  );

  try {
    // For every active adapter, subscribe to desired channel
    for (const adapter of activeAdapters) {
      const { type, name } = adapter;
      // Currently only GCM has multiple games streaming
      // In future other adapters could be added so this should be adjusted
      if (name === 'GcmAdapter') {
        const initiatedAdapter = gcmBusService.adapters.find((adapter) => adapter.name === name);
        // Subscribe to channel only if the connection is alive
        await checkAdapterConnection(initiatedAdapter);
        gcmBusService.addChannel(type, productId);
      }
    }

    // For every inactive adapter, create new instance
    for (const adapter of inactiveAdapters) {
      activeAdapterNames.add(adapter.name);

      if (productName === 'crashcash' && adapter.name === 'UWSAdapter') {
        const channels = `NSoft.InstanceId.${config.productInstanceUuid},NSoft.SubscriptionId.${config.productId},NSoft.Device.${deviceId}`;
        const headers = {
          'X-NSFT-NGS-CHANNELS': channels,
          'X-NSFT-NGS-SUBSCRIPTIONID': `${config.productId}`,
          'X-NSFT-NGS-DEVICEID': `${deviceId}`,
        };
        let grantCode;
        try {
          store.dispatch('crashcash/setSocketReInitInProgress', true);
          const { code } = await ws.getCodes(headers);
          grantCode = code;
        } catch (error) {
          store.dispatch('crashcash/setSocketReInitInProgress', false);
          console.warn('ERROR in getting WS grant code', error);
          return;
        }
        adapter.options.grantCode = grantCode;
      }

      const settings = {
        environment: config.environment,
        platform: config.platform,
        platformName: config.platformName,
        productName: config.productName,
        productId: config.productId,
        tenantId: config.tenantId,
        locale: config.locale,
        applicationName: config.applicationName,
        useCommonEventName: true,
        adapters: [adapter],
        clientMessageProtocol: config.messageProtocol,
        subChannels: {
          Product: {
            deviceUuid: deviceId,
          },
        },
        productInstanceId: config.productInstanceUuid,
        skipClientAdapters,
      };

      busService = new BusService(settings);
      busService.start();
      busServiceInstanceArray.push(busService);
      skipClientAdapters = true;
      // TODO: If new adapters are introduced, handle them individually
      if (adapter.name === 'GcmAdapter') {
        gcmBusService = busService;
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Bus service error!', error);
    store.dispatch('handleGameInitError', { game: productName, reason: 'socket_service_error' });
  }
};

/**
 * Send message to bus service
 * @param {string} action
 * @param {object} data
 */
const sendMessage = function (action, data) {
  busServiceInstanceArray.forEach((busInstance) => {
    if (busInstance.clientAdapter?.handler) {
      // eslint-disable-next-line no-console
      console.log(' sendMessage ', action);
      busInstance?.sendMessage(
        {
          action,
          data,
          enforceEvent: data.enforceEvent,
        },
        true,
      );
    }
  });
};
const sendMessageAsync = function (action, data) {
  busServiceInstanceArray.forEach((busInstance) => {
    if (busInstance.clientAdapter?.handler) {
      // eslint-disable-next-line no-console
      console.log(' sendMessageAsync ', action);
      try {
        if (window.__TEST__) {
          sendMessage(action, data);
          return;
        }
        busInstance?.clientAdapter?.handler
          .emitAsync({
            action,
            data,
          })
          .then((response) => {
            // eslint-disable-next-line no-console
            console.log(' emitAsync response ', response);
            const { ticket } = response;
            store.dispatch('setTicketAction', ticket.action);
            if (ticket.localStatus === 'PENDING') {
              const checkerEnabled = store.getters.getGameGetter({
                getter: 'autoTicketCheckerEnabled',
                specificGame: ticket.product.toLowerCase(),
              });
              if (checkerEnabled) ticketCheck.startTicketChecker(ticket);
            } else {
              ticketCheck.stopTicketChecker(ticket);
            }
            store.dispatch('setTicketActionSuccessful', true);
          })
          .catch((error) => {
            console.log(' emitAsync error ', error);
            const errorCodes = ['S_TAX_ABORTED', 'ERR_TICKET_VALIDATE_PAY', '412'];

            if (!isEmpty(error) && !errorCodes.includes(error.code)) {
              store.dispatch('closeGGMessage');
              const message = store.getters.getTranslation(error.message) ?? error.message;
              store.dispatch('sendGGMessage', { message: message });
            }

            switch (error.code) {
              // Handle foreign roTax payout ticket, don't show that error
              // #869301c24
              case '412':
                store.dispatch('setTicketActionSuccessful', false);
                break;
              // Focus total payment on payin confirmation rejection
              case 'ERR_TICKET_VALIDATE_PAY':
                eventBus.$emit('focusTotalPayment');
                break;
              default:
            }
          });
      } catch (error) {
        // 8694zura1
        // console.warn(error);
      }
    }
  });
};
const stop = async function (game, message) {
  const { productName } = game;

  // Get adapters for specified game
  const adapters = store.getters.getGameGetter({ specificGame: productName, getter: 'adapters' });
  // For some reason sometimes adapters becomes undefined and forEach loop makes an Type error.
  // If there are no adapters registered we don't need to execute the code below.
  if (!adapters || !busService) return;

  adapters.forEach((adapter) => {
    const busAdapter = busService.getAdapter(adapter.name);
    if (!isNil(busAdapter)) {
      busAdapter.disconnect(message);
    }
    if (activeAdapterNames.has(adapter.name)) {
      activeAdapterNames.delete(adapter.name);
    }
  });
};
export default {
  init,
  sendMessage,
  sendMessageAsync,
  formatChannels,
  stop,
};
