export default {
  gameColor: '#6c00ff',
  futureBet: false,
  systemBet: false,
  additionalBetslipConfig: true,
  betslipConfiguration: {},
  adapters: [
    {
      name: 'GcmAdapter',
      type: 'Product',
      channels: [],
    },
  ],
  bettingType: 'races',
  ticketType: 'ngsTicket',
  bettingInputs: [
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 1,
      betId: 0,
      betType: 'normal',
      betDisplayName: 'winner',
      shortcut: '+',
      inputType: 'text',
      inputFields: 4,
      betName: 'winner',
      additionalInputs: [],
      outcomeShortcuts: [
        {
          outcome: null,
          shortcut: 'd',
          action: {
            name: 'auto_fill',
          },
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 4,
        },
        {
          type: 'pattern',
          expression: /^[1-4]$/,
        },
      ],
      betValidations: [
        {
          type: 'unique',
        },
        {
          type: 'length',
          max: 4,
          min: 1,
        },
        {
          type: 'validOdd',
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 3,
      betId: 2,
      betType: 'special',
      betDisplayName: 'place',
      shortcut: 't',
      inputType: 'text',
      inputFields: 1,
      betName: 'place',
      additionalInputs: [],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 4,
        },
        {
          type: 'pattern',
          expression: /^[1-4]$/,
        },
      ],
      outcomeShortcuts: [
        {
          outcome: 1,
          shortcut: '1',
        },
        {
          outcome: 2,
          shortcut: '2',
        },
        {
          outcome: 3,
          shortcut: '3',
        },
        {
          outcome: 4,
          shortcut: '4',
        },
      ],
      betValidations: [
        {
          type: 'length',
          min: 1,
        },
        {
          type: 'validOdd',
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 11,
      betId: 10,
      betType: 'normal',
      betDisplayName: 'forecast',
      shortcut: 'f',
      inputType: 'text',
      inputFields: 8,
      betName: 'forecast',
      inputRows: 2,
      additionalInputs: [],
      outcomeShortcuts: [
        {
          outcome: null,
          shortcut: 'd',
          action: {
            name: 'auto_fill',
          },
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 4,
        },
        {
          type: 'pattern',
          expression: /^[1-4]$/,
        },
      ],
      betValidations: [
        {
          type: 'validForecast',
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 13,
      betId: 12,
      betType: 'special',
      betDisplayName: 'reverse_forecast',
      shortcut: 's',
      inputType: 'text',
      inputFields: 4,
      additionalInputs: [],
      outcomeShortcuts: [
        {
          outcome: null,
          shortcut: 'd',
          action: {
            name: 'auto_fill',
          },
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 2,
        },
        {
          type: 'range',
          min: 1,
          max: 4,
        },
        {
          type: 'pattern',
          expression: /^[1-4]$/,
        },
      ],
      betValidations: [
        {
          type: 'unique',
        },
        {
          type: 'length',
          max: 4,
          min: 2,
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
    {
      content: 'bettingInput',
      enabled: true,
      label: '',
      clientId: 16,
      betId: 14,
      betType: 'headToHead',
      betDisplayName: 'head_to_head',
      shortcut: 'h',
      inputType: 'text',
      inputFields: 2,
      betName: 'headToHead',
      inputRows: 2,
      additionalInputs: [],
      bettingPreview: {
        h2h: true,
      },
      outcomeShortcuts: [],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'length',
          max: 1,
        },
        {
          type: 'range',
          min: 1,
          max: 6,
        },
        {
          type: 'pattern',
          expression: /^[1-6]$/,
        },
        {
          type: 'validHeadToHead',
        },
      ],
      betValidations: [
        {
          type: 'length',
          max: 2,
          min: 2,
        },
        {
          type: 'validHeadToHead',
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
        {
          name: 'blur',
          handler: {},
        },
      ],
    },
  ],
  additionalBettingInputs: [
    {
      content: 'bettingInput',
      enabled: true,
      label: 'system',
      clientId: 'system',
      betId: 0,
      betType: 'system',
      betDisplayName: 'system',
      shortcut: 's',
      inputType: 'text',
      inputFields: 1,
      betName: 'system',
      outcomeShortcuts: [
        {
          outcome: null,
          shortcut: 'd',
          action: {
            name: 'auto_fill',
          },
        },
      ],
      validations: [
        {
          type: 'required',
        },
        {
          type: 'pattern',
          expression: /^(?!.*(.).*\1)[1-4]{1,4}$/,
        },
      ],
      betValidations: [
        {
          type: 'validForecast',
        },
      ],
      events: [
        {
          name: 'input',
          handler: {},
        },
        {
          name: 'keydown',
          handler: {},
        },
        {
          name: 'focus',
          handler: {},
        },
      ],
    },
  ],
  shortcuts: {
    '+': {
      id: 1,
      action: 'ticket',
      active: true,
      name: 'winner',
      shortcut: '+',
      order: 1,
    },
    '+ ': {
      id: 15,
      action: 'ticket',
      active: false,
      name: 'winner',
      shortcut: '+ ',
      order: 2,
    },
    '/': {
      action: 'resetTicket',
      active: true,
      name: 'reset_ticket',
      shortcut: '/',
      order: 3,
    },
    q: {
      action: 'results',
      active: true,
      name: 'results',
      shortcut: 'q',
      order: 4,
    },
    's ': {
      id: 15,
      action: 'system',
      active: false,
      name: 'system',
      shortcut: 's ',
      order: 5,
    },
    d: {
      action: 'auto_fill',
      active: true,
      name: 'auto_fill',
      shortcut: 'd',
      order: 6,
    },
    'ctrl + m': {
      action: 'focusFirstBet',
      active: true,
      name: 'focus_first_bet',
      shortcut: 'ctrl + m',
      order: 7,
    },
    'ctrl + s': {
      action: 'editStakePerBet',
      active: true,
      name: 'edit_stake_per_bet',
      shortcut: 'ctrl + s',
      order: 8,
    },
    'ctrl + b': {
      action: 'focusFirstInput',
      active: true,
      name: 'focus_first_input',
      shortcut: 'ctrl + b',
      order: 9,
    },
    'ctrl + e': {
      action: 'toggleEditMode',
      active: false,
      name: 'toggle_delete_bet_mode',
      shortcut: 'ctrl + e',
      order: 10,
    },
    delete: {
      action: 'deleteBet',
      active: false,
      name: 'delete_bet',
      shortcut: 'delete',
      order: 11,
    },
    t: {
      id: 3,
      action: 'place',
      active: true,
      name: 'place',
      shortcut: 't',
      order: 13,
    },
    f: {
      id: 11,
      action: 'forecast',
      active: true,
      name: 'forecast',
      shortcut: 'f',
      order: 21,
    },
    s: {
      id: 13,
      action: 'reverseForecast',
      active: true,
      name: 'reverse_forecast',
      shortcut: 's',
      order: 23,
    },
    h: {
      id: 16,
      action: 'headToHead',
      active: true,
      name: 'head_to_head',
      shortcut: 'h',
      order: 24,
    },
    m: {
      action: 'quickBetslipMode',
      active: false,
      name: 'enable_quick_betslip_mode',
      shortcut: 'm',
      order: 22,
    },
  },
  availableTicketActions: [
    {
      status: 'accepted',
      action: ['check', 'copy'],
    },
    {
      status: 'canceled',
      action: [],
    },
    {
      status: 'paidout',
      action: [],
    },
    {
      status: 'pending',
      action: ['checkStatus'],
    },
    {
      status: 'unknown',
      action: ['checkStatus'],
    },
    {
      status: 'failed',
      action: ['checkStatus'],
    },
    {
      status: 'payedout',
      action: [],
    },
  ],
  actionTypes: {
    add: 'TicketPayIn',
    payout: 'TicketPayout',
    cancel: 'TicketCancel',
    copy: 'TicketCopy',
  },
  printLayoutType: {
    add: 'ticketVirtualMotorcycleSpeedway',
    payout: 'ticketVirtualMotorcycleSpeedwayPayout',
    cancel: 'ticketCancel',
    results: 'resultsVirtualMotorcycleSpeedway',
  },
};
