import types from './mutationTypes';
import { isNil } from 'lodash';

export default {
  setEventTime({ commit }, eventTime) {
    commit(types.SET_EVENT_TIME, eventTime);
  },
  setEventDisplayId({ commit }, eventId) {
    if (eventId) commit(types.SET_EVENT_DISPLAY_ID, eventId);
  },
  setEventName({ commit }, eventName) {
    commit(types.SET_EVENT_NAME, eventName);
  },
  setBettingDisabled({ commit }, bettingDisabled) {
    commit(types.SET_BETTING_DISABLED, bettingDisabled);
  },
  setSingleBetInputValue({ commit }, { inputId, inputValue }) {
    if (inputId === 'future') return;
    commit(types.SET_SINGLE_BET_INPUT_VALUE, { inputId, inputValue });
  },
  setBetInputValue({ commit }, inputValue) {
    commit(types.SET_BET_INPUT_VALUE, inputValue);
  },
  resetBetInputValue({ commit }) {
    commit(types.RESET_BET_INPUT_VALUE);
  },
  setSelectedBet({ commit }, selectedBet) {
    commit(types.SET_SELECTED_BET, selectedBet);
  },
  storeBetslipTicket({ commit }, ticket) {
    commit(types.STORE_BETSLIP_TICKET, ticket);
  },
  clearBetslipTicket({ commit }) {
    commit(types.CLEAR_BETSLIP_TICKET);
  },
  setAdditionalInput({ commit }, additionalInput) {
    commit(types.SET_ADDITIONAL_INPUT, additionalInput);
  },
  clearAdditionalInputs({ commit }) {
    commit(types.CLEAR_ADDITIONAL_INPUTS);
  },
  addActiveNotification({ commit }, notification) {
    commit(types.ADD_ACTIVE_NOTIFICATION, notification);
  },
  removeActiveNotification({ commit }, notification) {
    commit(types.REMOVE_ACTIVE_NOTIFICATION, notification);
  },
  setBetslipFooterValues({ commit }, payload) {
    commit(types.SET_FOOTER_VALUES, payload);
  },
  setConnectionStatus({ commit }, payload) {
    commit(types.SET_CONNECTION_STATUS, payload);
  },
  setCountdownInterval({ state, commit, dispatch }) {
    if (state.countdownInterval) clearInterval(state.countdownInterval);
    if (window.Worker) {
      if (!isNil(state.countdownWorker)) {
        state.countdownWorker.terminate();
      }
      commit(types.SET_COUNTDOWN_WORKER, new Worker('./webWorkerCountdown.js'));
      state.countdownWorker.postMessage(state.eventTime);
      state.countdownWorker.onmessage = (e) => {
        const time = e.data || 0;

        dispatch('setEventTime', time);
        if (time <= 0) {
          state.countdownWorker.terminate();
        }
      };
    } else {
      commit(
        types.SET_COUNTDOWN_INTERVAL,
        setInterval(() => {
          let time = state.eventTime;

          if (state.countdownInterval && state.eventTime <= 0) {
            clearInterval(state.countdownInterval);
            time = 0;
          }

          dispatch('setEventTime', time);
        }, 1000),
      );
    }
  },
  toggleQuickBetslipMode({ commit }) {
    commit(types.TOGGLE_QUICK_BETSLIP_MODE);
  },
};
