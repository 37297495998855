import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';

import baseActions from '../actions';
import baseGetters from '../getters';
import baseMutations from '../mutations';
import baseState from '../state';

export default {
  namespaced: true,
  state: { ...baseState, ...state },
  getters: { ...baseGetters, ...getters },
  actions: { ...baseActions, ...actions },
  mutations: { ...baseMutations, ...mutations },
};
