export default {
  name: 'greyhoundraces',
  maxRacerNumber: 6,
  eventId: '-',
  betInputData: {
    value: {},
    system: '',
  },
  offerList: [],
  activeOffer: {},
  editBetModeFeatureAllowed: false,
};
