import store from '../store';
import {
  busService,
  forecastCombinations,
  reverseForecastCombinations,
  tricastCombinations,
  reverseTricastCombinations,
  splitForecastCombinations,
  splitTricastCombinations,
  capitalizeFirstLetter,
} from '../utility';
import { helpers } from '@nsftx/games-sdk-js/src/utility/index';
import { each, isNil } from 'lodash';

const betStructure = {
  market: '',
  outcome: '',
  roundNumber: null,
  odd: null,
  numEvents: 1,
};

/**
 * Prepare bet for new betslip (v2)
 * @returns {object} return new bet
 */
const createBet = (data) => {
  const { bettingType } = store.getters.localGameConfig;
  const { currentActiveGame } = store.state;
  // races games have same bet types
  if (bettingType === 'races') {
    return createRacersBet(data);
  } else if (bettingType === 'draw') {
    // draw games have different bet types
    switch (currentActiveGame) {
      case 'luckysix': {
        return createLuckySixBet(data);
      }
      case 'luckyx': {
        console.log('luckyx');
        break;
      }
      case 'keno': {
        console.log('keno');
        break;
      }
    }
  } else {
    return createCrashCashBet(data);
  }
};
/**
 * Prepare bet for racers type games
 * @returns {object} return new bet
 */
const createRacersBet = (data) => {
  let selectedBet = store.getters.getGameGetter({ getter: 'selectedBet' });
  const eventDisplayId = store.getters.getGameGetter({ getter: 'eventDisplayId' });
  const roundNumber = store.getters.getGameGetter({ specificGame: null, getter: 'eventInProgress' })
    ? eventDisplayId + 1
    : eventDisplayId;
  const eventId = store.getters.getGameGetter({ getter: 'eventId' }) || '-';
  const betId = !isNil(data.betId) ? data.betId : selectedBet.betId;
  if (data.betId) {
    selectedBet = store.getters.localGameConfig.bettingInputs.find((input) => input.betId === data.betId);
  }
  let betDisplayName = store.getters.getTranslation(data.betDisplayName || selectedBet.betDisplayName);
  const bettingPreviewOutcomes = selectedBet.bettingPreview?.outcomes;
  let outcome;
  let selection;
  let combinations;
  let outcomeValue;
  let racers;
  let odd;

  switch (betId) {
    case 3:
    case 4:
    case 8:
    case 9: {
      selection = Object.values(data.value).filter((value) => value !== '');
      outcome = store.getters.getTranslation(selectedBet.outcomes[selection[0] - 1]);
      outcomeValue = selection[0] - 1;
      break;
    }
    case 1:
    case 2:
    case 5:
    case 6:
    case 7: {
      selection = Object.values(data.value).filter((value) => value !== '');
      outcome = selection.join(' ');
      odd = bettingPreviewOutcomes[Number(outcome) - 1].odd;
      break;
    }
    case 10: {
      // SELECTION 2 ARRAYS FOR ROWS
      const validCombinations = forecastCombinations(data.value);
      combinations = validCombinations.length;
      const [firstInputRow, secondInputRow] = splitForecastCombinations(validCombinations);
      outcome = `1: ${firstInputRow}\n 2: ${secondInputRow}`;
      outcomeValue = [firstInputRow, secondInputRow];
      betDisplayName += ` ${store.getters.getTranslation('combinations')} ( ${combinations} )`;

      break;
    }
    case 11: {
      // SELECTION 2 ARRAYS FOR ROWS
      const validCombinations = tricastCombinations(data.value);
      combinations = validCombinations.length;
      const [firstInputRow, secondInputRow, thirdInputRow] = splitTricastCombinations(validCombinations);
      outcome = `1: ${firstInputRow}\n 2: ${secondInputRow}\n 3: ${thirdInputRow}`;
      outcomeValue = [firstInputRow, secondInputRow, thirdInputRow];
      betDisplayName += ` ${store.getters.getTranslation('combinations')} ( ${combinations} )`;

      break;
    }
    case 12: {
      combinations = reverseForecastCombinations(data.value).length;
      selection = Object.values(data.value).filter((value) => value !== '');
      outcome = selection.join(' ');
      betDisplayName += ` ${store.getters.getTranslation('combinations')} ( ${combinations} )`;
      break;
    }
    case 13: {
      combinations = reverseTricastCombinations(data.value).length;
      selection = Object.values(data.value).filter((value) => value !== '');
      outcome = selection.join(' ');
      betDisplayName += ` ${store.getters.getTranslation('combinations')} ( ${combinations} )`;

      break;
    }
    case 14: {
      const raceCombinations = selectedBet.bettingPreview?.outcomes;
      const raceIndex = Number(data.value[1]) - 1;
      const winnerIndex = Number(data.value[2]) - 1;

      const race = raceCombinations[raceIndex];

      const firstRacer = race.racers[winnerIndex];
      const secondRacer = winnerIndex === 0 ? race.racers[1] : race.racers[0];

      racers = [[firstRacer], [secondRacer]];
      outcome = `Racer ${firstRacer}`;
      betDisplayName = `Racer ${race.racers[0]} - Racer ${race.racers[1]}`;
      odd = race.odds[winnerIndex];
      break;
    }
    case 0: {
      selection = Object.values(data.value).filter((value) => value !== '');
      const arrayOfBets = [];
      for (let i = 0; i < selection.length; i += 1) {
        outcome = String(selection[i]);
        arrayOfBets.push({
          ...betStructure,
          outcome,
          market: betDisplayName,
          roundNumber,
          betId,
          combinations,
          eventId,
          betType: selectedBet.betType,
        });
      }
      return arrayOfBets;
    }
  }

  const limit = selectedBet.bettingPreview?.betLimit;
  if (limit) betDisplayName += ` (- ${limit} +)`;

  return [
    {
      ...betStructure,
      outcome,
      market: betDisplayName,
      roundNumber,
      betId,
      combinations,
      outcomeValue,
      eventId,
      racers,
      betType: selectedBet.betType,
      odd: Number(odd) || null,
    },
  ];
};
/**
 * Prepare bet for lucky six
 * @returns {object} return new bet
 */
const createLuckySixBet = (data) => {
  const selectedBet = data.selectedBet || store.getters.getGameGetter({ getter: 'selectedBet' });
  const selection = Object.values(data.value).filter((value) => value !== '');
  const eventDisplayId = store.getters.getGameGetter({ getter: 'eventDisplayId' });
  const roundNumber = store.getters.getGameGetter({ specificGame: null, getter: 'eventInProgress' })
    ? eventDisplayId + 1
    : eventDisplayId;
  let betId = data.betId ?? selectedBet.betId;
  const maxNumberOfSelection = betId === 0 ? 6 : 10;
  const betType = data.betType || selectedBet.betType;
  const betSubType = data.betSubType || selectedBet.betSubType;
  const numEvents = Number(data.future);
  const payment = data?.payment;
  const outcomes = data.outcomes || selectedBet.outcomes;
  let betDisplayName = store.getters.getTranslation(data.betDisplayName || selectedBet.betDisplayName);
  let outcome;
  let combinations;
  let outcomeValue;
  let odd;
  switch (betType) {
    case 'normal': {
      if (betSubType === 'color') {
        const colorIndex = selection[0];
        if (selection[0] === '9') {
          const arrayOfBets = [];
          for (let i = 1; i < 9; i += 1) {
            outcome = createColorBet(i);
            betDisplayName = store.getters.getTranslation(outcomes[i - 1]);
            arrayOfBets.push({
              ...betStructure,
              outcome,
              market: betDisplayName,
              roundNumber,
              betId,
              numEvents,
              eventId: roundNumber,
              betType: selectedBet.betType,
            });
          }
          return arrayOfBets;
        } else {
          outcome = createColorBet(colorIndex);
          betDisplayName = store.getters.getTranslation(outcomes[colorIndex - 1]);
          return [
            {
              ...betStructure,
              outcome,
              market: betDisplayName,
              roundNumber,
              betId,
              numEvents,
              eventId: roundNumber,
              betType: selectedBet.betType,
            },
          ];
        }
      } else {
        // When keepValidInputOnBetChange is active it can lead to having normal bet with more than six inputs selected
        let formatedSelection = selection;
        if (selection.length > maxNumberOfSelection) {
          formatedSelection = selection.slice(0, 6);
        }
        outcome = formatedSelection.sort((a, b) => a - b).join(' ');
      }
      break;
    }
    case 'special': {
      if (betId === 5) {
        outcome = '';
        outcomeValue = 0;
        const sortedSelection = selection.map((input) => Number(input)).sort((a, b) => a - b);
        sortedSelection.forEach((input) => {
          outcome = String(outcome) + capitalizeFirstLetter(store.getters.getTranslation(outcomes[input - 1])) + ' ';
          if (input === 8) input = 0;
          outcomeValue += 2 ** input;
        });
      } else if (betId === 10) {
        outcome = selection.join(' ');
        odd = selectedBet.bettingPreview?.outcomes[Number(outcomeValue) - 1].odd;
      } else {
        outcome = store.getters.getTranslation(outcomes[selection[0] - 1]);
        outcomeValue = String(selection[0] - 1);
        odd = selectedBet.bettingPreview?.outcomes[Number(outcomeValue)].odd;
      }
      break;
    }
    case 'system': {
      outcome = selection.sort((a, b) => a - b).join(' ');
      betId = selection.length - 6;
      betDisplayName = betId
        ? `${store.getters.getTranslation('system')} 6/${selection.length}`
        : store.getters.getTranslation('standard');
      combinations = helpers.getCombinations(selection.length, 6);
    }
  }

  if (selectedBet.limit) betDisplayName += ` (- ${selectedBet.limit} +)`;

  return [
    {
      ...betStructure,
      outcome,
      market: betDisplayName,
      roundNumber,
      betId,
      numEvents,
      combinations,
      outcomeValue,
      eventId: roundNumber,
      stake: payment,
      betType: selectedBet.betType,
      odd,
    },
  ];
};

const createColorBet = (index) => {
  const numbers = [];
  for (let i = 1; i <= 48; i++) {
    if (i % 8 === index % 8) {
      numbers.push(i);
    }
  }
  return numbers.join(' ');
};
/**
 * @param {Array} bets Bets Array from back end where future bets are separated
 * @returns {Array} Array of bets where future bets are grouped
 */
const groupFutureBets = (bets) => {
  const filteredBets = [];
  bets.forEach((bet) => {
    bet.numEvents = 1;
    if (!filteredBets.length) {
      filteredBets.push(bet);
      return;
    }
    const lastFilteredBet = filteredBets.at(-1);
    if (
      lastFilteredBet.type === bet.type &&
      lastFilteredBet.value === bet.value &&
      lastFilteredBet.eventId < bet.eventId
    ) {
      lastFilteredBet.numEvents += 1;
    } else {
      filteredBets.push(bet);
    }
  });
  return filteredBets;
};

/**
 * @param {Array} bets Bets Array from back end where future bets are separated
 * @returns {Array} Array of bets where future bets are grouped
 */
const mapBetsFromBackendForCreateBet = (bets) => {
  const { bettingInputs } = store.getters.localGameConfig;
  const rebetBetsInputArray = [];
  bets.forEach((bet) => {
    let selectedBet;
    if (bet.type > 0 && bet.type < 5) {
      selectedBet = bettingInputs.filter((bettingInput) => bettingInput.clientId === 5)[0];
    } else if (bet.type === 0 && isColorBet(bet.value)) {
      selectedBet = bettingInputs.filter((bettingInput) => bettingInput.clientId === 3)[0];
    } else {
      selectedBet = bettingInputs.filter((bettingInput) => bettingInput.betId === bet.type)[0];
    }
    let value;
    switch (bet.type) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 10: {
        const parsedValue = bet.value.split(',').map((item) => item.trim());
        value = Object.fromEntries(parsedValue.map((value, index) => [index + 1, value]));
        break;
      }
      case 6:
      case 7:
      case 8:
      case 11: {
        value = { 1: String(Number(bet.value) + 1) };
        break;
      }
      case 5: {
        const betValue = !isNaN(Number(bet.value.trim())) ? bet.value : bet.cleanValue;
        value = getDisplayValueForFirstBallColor(betValue);
      }
    }
    rebetBetsInputArray.push({
      value,
      payment: bet.payin,
      future: bet.numEvents || 1,
      selectedBet,
    });
  });
  return rebetBetsInputArray;
};

/**
 * @param {Array} bets Bets Array from back end where future bets are separated
 * @returns {Array} Array of bets where future bets are grouped
 */
const mapBetsFromBetslipForCreateBet = (bet) => {
  const { currentActiveGame } = store.state;

  if (currentActiveGame === 'luckysix') {
    switch (bet.betId) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 10: {
        const parsedValue = bet.outcome.split(' ').map((item) => item.trim());
        return Object.fromEntries(parsedValue.map((value, index) => [index + 1, value]));
      }
      case 6:
      case 7:
      case 8:
      case 11: {
        return { 1: String(Number(bet.outcomeValue) + 1) };
      }
      case 5: {
        return getDisplayValueForFirstBallColor(bet.outcomeValue);
      }
      default:
        return {};
    }
  } else if (currentActiveGame === 'crashcash') {
    return { 1: bet.odd };
  } else {
    // For races
    switch (bet.betId) {
      case 0:
      case 1:
      case 2:
      case 5:
      case 6:
      case 7:
        return { 1: bet.outcome };
      case 3:
      case 4:
      case 8:
      case 9:
        return { 1: String(Number(bet.outcomeValue) + 1) };
      case 10:
      case 11: {
        const parsedValue = bet.outcome
          .split('\n')
          .map((line) => line.split(': ')[1])
          .flatMap((numbers) => numbers.split(',').map(Number));
        return Object.fromEntries(parsedValue.map((value, index) => [index + 1, value]));
      }
      case 12:
      case 13: {
        const outcome = bet.outcome.split(' ');
        return Object.fromEntries(outcome.map((value, index) => [index + 1, value]));
      }
      case 14: {
        // Head to head mapping
        const raceOutcomes = store.getters.getGameGetter({ getter: 'selectedBet' }).bettingPreview?.outcomes;
        const raceIndex = raceOutcomes.findIndex(
          (outcome) => outcome.racers.sort().toString() === bet.racers.sort().toString(),
        );
        const racer = parseInt(bet.outcome.match(/\d+/)[0], 10);
        const racerIndex = bet.racers.findIndex((r) => r[0] === racer);

        return { 1: raceIndex + 1, 2: racerIndex + 1 };
      }
      default:
        return {};
    }
  }
};

/**
 * Prepare bet for crash cash
 * @returns {object} return new bet
 */
const createCrashCashBet = (data) => {
  const selectedBet = data.selectedBet || store.getters.getGameGetter({ getter: 'selectedBet' });
  const eventDisplayId = store.getters.getGameGetter({ specificGame: '', getter: 'eventDisplayId' });
  const roundNumber = store.getters.getGameGetter({ specificGame: '', getter: 'eventInProgress' })
    ? +eventDisplayId + 1
    : +eventDisplayId;

  let betId = data.betId ?? selectedBet.betId;
  const outcome = data.value[1];
  const payment = data.payment ?? store.getters.minBetAmount;
  return [
    {
      outcome: store.getters.getTranslation('crash_odds'),
      market: store.getters.getTranslation('multiplier'),
      odd: +outcome,
      roundNumber,
      betType: 1,
      betId,
      numEvents: Number(data.future),
      stake: +payment,
    },
  ];
};

/**
 * Prepare bets for ticket payin action
 * @param bets {Array}
 * @param payin {Number}
 * @returns {Array}
 */
const prepareBets = () => {
  const localGameConfig = store.getters.getGameGetter({ getter: 'localGameConfig' });
  if (localGameConfig.ticketType === 'newTicket') {
    return prepareNewBets();
  } else if (localGameConfig.ticketType === 'ngsTicket') {
    return prepareNgsBets();
  }
};
/**
 * Prepare bets for ticket payin action (L6, GR)
 * @param bets {Array}
 * @param payin {Number}
 * @returns {Array}
 */
const prepareNewBets = () => {
  const bets = store.getters['gamesBetslip/ticket'];
  const newBets = [];

  for (let i = 0; i < bets.length; i++) {
    const { betType, odd, numEvents } = bets[i];

    for (let j = 0; j < numEvents; j++) {
      const upcomingEvent = store.getters.getGameGetter({ getter: 'upcomingEvents' })[j];
      newBets.push({
        payIn: {
          real: +bets[i].stake,
          bonuses: [],
        },
        selections: [
          {
            marketId: betType,
            outcomeId: odd.toString(),
            eventId: upcomingEvent?.eventId,
            displayId: upcomingEvent?.displayId,
          },
        ],
        selectedSystem: 1,
      });
    }
  }

  return newBets;
};
/**
 * Prepare bets for ticket payin action (L6, GR)
 * @param bets {Array}
 * @param payin {Number}
 * @returns {Array}
 */
const prepareNgsBets = () => {
  const bets = store.getters['gamesBetslip/ticket'];
  return bets.map((bet) => {
    const { betId, numEvents, stake, eventId } = bet;
    const value = formatOutcomeValue(bet);
    return {
      type: betId,
      numEvents,
      payin: stake,
      value,
      eventId,
    };
  });
};
/**
 * Formats outcome value from value that is represented on betslip to value that is sent to backend
 * @reutrns {String}
 */
const formatOutcomeValue = (bet) => {
  const { outcome, betId, outcomeValue } = bet;
  const { currentActiveGame } = store.state;
  if (currentActiveGame === 'luckysix') {
    switch (betId) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 10: {
        return outcome.split(' ').join(',');
      }
      case 5:
      case 6:
      case 7:
      case 8:
      case 11: {
        return String(outcomeValue);
      }
    }
  } else if (
    currentActiveGame === 'greyhoundraces' ||
    currentActiveGame === 'virtualgreyhoundraces' ||
    currentActiveGame === 'virtualhorseraces' ||
    currentActiveGame === 'virtualmotorcyclespeedway'
  ) {
    switch (betId) {
      case 0: {
        return Array(outcome);
      }
      case 1:
      case 2:
      case 5:
      case 6:
      case 7: {
        return Number(outcome);
      }
      case 3:
      case 4:
      case 8:
      case 9: {
        return Number(outcomeValue);
      }
      case 10:
      case 11: {
        return outcomeValue;
      }
      case 12: {
        return outcome.split(' ');
      }
      case 13: {
        return outcome.split(' ').map((value) => Number(value));
      }
      case 14: {
        return bet.racers;
      }
    }
  }
};
/**
 *
 * @param {String} value
 * @returns Object representing first ball color input
 */
const getDisplayValueForFirstBallColor = (value) => {
  const inputArray = [];
  let colorIndex = '';
  each([0, 1, 2, 3, 4, 5, 6, 7, 8], (n) => {
    if (parseInt(value, 10) & (2 ** n)) {
      colorIndex = n === 0 ? 8 : n;
      inputArray.push(colorIndex);
    }
    inputArray.sort((a, b) => a - b);
  });
  const inputObject = Object.fromEntries(inputArray.map((value, index) => [index + 1, value]));
  return inputObject;
};
/**
 *
 * @param {Array} betValue
 * @returns Boolean do all numbers have the same color
 */
const isColorBet = (value) => {
  const numbers = value.split(',').map((item) => item.trim());
  return numbers.every((number) => number % 8 === numbers[0] % 8);
};
/**
 * Prepares the ticket data
 * @returns {object} return ticket data object
 */
const prepareTicketData = () => {
  const totalPayment = +store.getters['gamesBetslip/totalPayment'];
  const bets = prepareBets();
  return {
    totalPayment,
    bets,
  };
};
/**
 * Create 'seven' ticket data for payin action
 * @returns {Object} return new ticket data object
 */
const createSevenTicket = () => {
  const { bets, totalPayment } = prepareTicketData();

  const ticket = {
    stake: totalPayment,
    payin: totalPayment,
    bets,
  };
  return ticket;
};
/**
 *
 */
const ticketPayin = () => {
  store.dispatch('closeGGMessage');
  const ticket = createSevenTicket();
  const ticketPayinData = {
    action: 'Tickets.Pay',
    data: {
      ticket,
    },
  };
  // console.log(' ticketPayinData ', ticketPayinData);
  store.dispatch('gamesBetslip/setIsPayinButtonDisabled', true);
  busService.sendMessageAsync(ticketPayinData.action, ticketPayinData.data);
};

/**
 * Prepare an array of bet selections based on the 'selectionRefs'
 * @returns {Array}
 */
const prepareBetSelections = (ticket) => {
  const config = store.getters.getGameGetter({ getter: 'config' });
  const { selections, bets } = ticket;

  bets.forEach((bet) => {
    const betSelections = bet.selectionRefs.map(({ selectionIndex }) => {
      const selection = selections[selectionIndex] || {};
      return selection;
    });
    // eslint-disable-next-line no-param-reassign
    bet.selections = betSelections;
    const maxPossibleWin = bet.winnings.maxPossible;
    bet.cappedWinnings =
      config.rawData.maxPossibleWin < maxPossibleWin ? config.rawData.maxPossibleWin : maxPossibleWin;
    bet.maxPossiblePayoutTax = bet.maximumPossiblePayout?.taxes[0]?.value || 0;
  });
  return bets;
};
/**
 * Prepare an object of 'seven' ticket response data from new ticket ( Crash Cash )
 * @returns {Object}
 */
const prepareSevenTicketData = (data) => {
  // eslint-disable-next-line no-console
  const config = store.getters.getGameGetter({ getter: 'config' });
  console.log(' prepareSevenTicketData ', data);
  const { totals, codes, productName, requestId, createdAt, status, bets } = data;
  const { taxes, payment, payout, stake, maximumPossiblePayout } = totals;
  const payinTaxes = taxes?.filter((tax) => tax.type === 'PAYIN');
  const payinTax = payinTaxes.reduce((sum, tax) => sum + tax.value, 0);
  const payoutTaxes = taxes?.filter((tax) => tax.type === 'PAYOUT');
  const payoutTax = payoutTaxes.reduce((sum, tax) => sum + tax.value, 0);

  const winnings = bets.reduce((totalWinnings, bet) => totalWinnings + (bet.winnings?.total.value || 0), 0);
  const ticketPin = data.ticketPin || data.additionalDetails?.ticketPin || null;
  const luckyMultipliers = data.meta?.luckyMultipliers || null;
  const luckyMultiplier = luckyMultipliers ? JSON.parse(luckyMultipliers)[0]?.luckyMultiplier : null;
  const id = codes?.find((code) => code.type === 'barcode')?.id;
  const maxPossibleWin = totals.winnings.maxPossible || null;
  const maxPossiblePayout = maximumPossiblePayout.real.value || null;
  const maxPossiblePayoutTax = maximumPossiblePayout.taxes.reduce((acc, tax) => {
    return tax.type === 'PAYOUT' ? acc + tax.value : acc;
  }, 0);
  const cappedWinnings = config.rawData.maxPossibleWin * bets.length;
  const totalCappedWinnings = cappedWinnings < maxPossibleWin ? cappedWinnings : maxPossibleWin;

  return {
    id,
    paymentId: '',
    product: productName,
    payin: payment.real.value,
    payout: payout.real.value,
    payoutTax,
    payinTax,
    possiblePayout: payout.real.value,
    possiblePayoutTax: undefined,
    requestUuid: requestId,
    createdAt: createdAt,
    stake: stake.real.value,
    status: {
      value: status,
    },
    ticketPin,
    winnings: winnings,
    maxPossibleWin,
    luckyMultiplier,
    bets: prepareBetSelections(data),
    isCopy: data.isCopy || '',
    maxPossiblePayout,
    maxPossiblePayoutTax,
    totalCappedWinnings,
  };
};

/**
 * Extracts and formats NGS bet outcomes for Seven Ticket Preview application (ticket check)
 * Outcome for each product is extracted based on bet type
 * @returns {String}
 */
const getOutcome = (bet, product) => {
  const getTranslation = store.getters.getTranslation;

  const productHandlers = {
    LuckySix: (bet) => {
      if ([7, 11].includes(bet.type)) {
        return getTranslation(bet.value === '0' ? 'even' : 'odd');
      }
      if ([6, 8].includes(bet.type)) {
        return getTranslation(bet.value === '0' ? 'under' : 'over');
      }
      return bet.value;
    },
    Races: (bet) => {
      if ([0, 1, 2, 5, 6, 7].includes(bet.type)) {
        return `${getTranslation('racer')} - ${bet.value}`;
      }
      if ([3, 4, 9].includes(bet.type)) {
        return getTranslation(bet.value === '0' ? 'under' : 'over');
      }
      if (bet.type === 8) {
        return getTranslation(bet.value === '0' ? 'even' : 'odd');
      }
      if ([10, 11].includes(bet.type)) {
        return bet.value.map((arr) => arr.join(',')).join(' - ');
      }
      if ([12, 13].includes(bet.type)) {
        return bet.value.join(',');
      }
      if (bet.type === 14) {
        const outcomes = bet.value.flat().map(Number);
        return `${getTranslation('racer')} ${outcomes[0]} - ${getTranslation('racer')} ${outcomes[1]}`;
      }
      return bet.value;
    },
  };

  // Default to Races
  return (productHandlers[product] || productHandlers.Races)(bet);
};

/**
 * Maps all data from ticket required for Seven Ticket Preview (ticket check)
 * @returns {Object}
 */
const mapTicketForPreview = (ticket) => {
  if (ticket.productName === 'CrashCash') {
    const { productName, totals } = ticket;
    const title = productName.split(/(?=[A-Z])/).join(' ');
    const bets = prepareBetSelections(ticket);

    const mappedBets = bets.map((bet, index) => {
      let status = bet.status;
      const selection = bet.selections[0];

      // Map status to match seven ticket preview status color
      if (status === 'ACCEPTED') status = 'OPEN';
      if (status === 'PAIDOUT') status = 'WON';
      if (status === 'CANCELED') status = 'LOST';

      return {
        betId: index + 1,
        betStartTime: `${selection.eventDisplayId}`,
        betEventName: store.getters.getTranslation('product_crash_cash'),
        betName: store.getters.getTranslation(selection.marketName),
        betOutcomeName: String(Number(selection.outcomeId).toFixed(2)),
        betOutcomeOdd: selection.odds ?? null,
        result: bet.status === 'CANCELED' ? 'CLOSED' : selection.status,
        payment: bet.payment.real.value,
        resolutionStatus: status,
      };
    });

    const payinTaxes = totals.taxes?.filter((tax) => tax.type === 'PAYIN');
    const payinTax = payinTaxes.reduce((sum, tax) => sum + tax.value, 0);
    return {
      title: title,
      productId: productName,
      channel: '7ShopTickets',
      action: 'Checked',
      fullEventName: 'Tickets.Checked',
      ticket: {
        bets: mappedBets,
        payment: totals.payment.real.value,
        paymentTax: payinTax,
        stake: totals.stake.real.value,
        maxWinning: totals.winnings.maxPossible,
        minWinning: totals.winnings.minPossible,
        bonus: null,
        bonusPercentage: 1,
      },
    };
  } else {
    const { bets, product, translation, payin, payinTax, maxPossibleWin, minPossibleWin } = ticket;
    const mappedBets = bets.map((bet, index) => {
      let status = bet.status.value;

      // Map status to match seven ticket preview status color
      if (status === 'PAYEDOUT') status = 'WON';
      if (status === 'CLOSED') status = 'LOST';

      // Extract odd for single combination forecast
      let betOdd = bet.odd;
      if (bet.combinations && bet.combinations.length === 1) betOdd = bet.combinations[0].odd;

      return {
        betId: index + 1,
        betStartTime: `${bet.eventId}`,
        betEventName: bet.typeValue ?? bet.bet.title,
        betName: bet.category,
        betOutcomeName: getOutcome(bet, product),
        betOutcomeOdd: betOdd ?? null,
        result: bet.status.value,
        payment: bet.payin,
        resolutionStatus: status,
      };
    });

    return {
      title: translation,
      productId: product,
      channel: '7ShopTickets',
      action: 'Checked',
      fullEventName: 'Tickets.Checked',
      ticket: {
        bets: mappedBets,
        payment: payin,
        paymentTax: payinTax,
        stake: payin - payinTax,
        maxWinning: maxPossibleWin,
        minWinning: minPossibleWin,
        bonus: ticket.superBonus?.amount,
        bonusPercentage: 1,
      },
    };
  }
};

/**
 * Maps all data from betslip required for Seven Ticket Preview (bet preview)
 * @returns {Object}
 */
const mapBetsForPreview = () => {
  const { currentActiveGame } = store.state;

  let totalPayment = 0;
  const bets = store.getters['gamesBetslip/ticket'];

  const productId = store.state.sevenProductsMapper[currentActiveGame];
  const title = productId.split(/(?=[A-Z])/).join(' ');

  const mappedBets = bets.map((bet) => {
    let betStartTime = `${store.getters.getTranslation('round')} ${bet.roundNumber}`;
    if (bet.numEvents > 1) betStartTime += ` - ${bet.roundNumber + bet.numEvents - 1}`;

    totalPayment += bet.stake * bet.numEvents;

    return {
      betId: bet.betId,
      betStartTime,
      betEventName: bet.market,
      betName: capitalizeFirstLetter(String(bet.betType)),
      betOutcomeName: bet.outcome,
      betOutcomeOdd: bet.odd ?? null,
      payment: bet.stake,
      locked: bet.permanentlyLock,
    };
  });

  return {
    title,
    productId,
    channel: '7ShopBetslip',
    ticket: {
      bets: mappedBets,
      payment: totalPayment,
      stake: totalPayment,
      maxWinning: null,
      bonus: null,
      bonusPercentage: 0,
    },
  };
};

export default {
  createBet,
  ticketPayin,
  prepareBetSelections,
  prepareSevenTicketData,
  createColorBet,
  createRacersBet,
  isColorBet,
  getDisplayValueForFirstBallColor,
  groupFutureBets,
  mapBetsFromBackendForCreateBet,
  mapBetsFromBetslipForCreateBet,
  mapBetsForPreview,
  mapTicketForPreview,
};
