import {
  getTimeDelay,
  eventBus,
  ticketCheck,
  errorTicketHandler,
  busService,
  betslipUtility,
  getMacroShortcut,
  capitalizeFirstLetter,
} from '../../../utility';
import types from './mutationTypes';
import { ticketAPI } from '@/api';
import { cloneDeep, isNil, range, shuffle, sampleSize } from 'lodash';

let lastShortcutPressed = '';

export default {
  /**
   * @param {Object} config
   *
   * If any additional config per game is required it can be set here.
   */
  // eslint-disable-next-line no-empty-pattern
  setAdditionalGameConfig({ dispatch }) {
    dispatch('setBettingPreviewOdds');
    dispatch('checkSystemBetConfig');
  },
  async handleBusMessages({ dispatch, state, getters, commit, rootState }, eventData) {
    // eslint-disable-next-line no-console
    // console.log('handleBusMessages LUCKY SIX ::: ', eventData);
    // handle state
    // handle ball
    // handle results
    // handle countdown
    const { eventName, eventId } = eventData;
    const eventTime = eventData.time;
    const sentTime = eventData.sentTime || Date.now();
    let countdownTime;
    const eventType = eventData.type;
    switch (eventName) {
      case 'SetState':
        await dispatch(
          'setBusLoaded',
          {
            game: state.name,
            busLoaded: true,
          },
          { root: true },
        );
        dispatch('setEventDisplayId', eventId);
        dispatch('setEventName', eventType);
        switch (eventType) {
          case 'StartCountdown':
          case 'countdown':
          case 'bettingDisabled':
          case 'StopBetting': {
            const delay = eventData.delay;
            countdownTime = getTimeDelay(eventTime, sentTime, delay);
            dispatch('setEventTime', countdownTime);
            dispatch('setCountdownInterval');
            if (eventType === 'bettingDisabled' || eventType === 'StopBetting') {
              dispatch('setBettingDisabled', true);
            }
            break;
          }
          case 'results':
          case 'SetResults': {
            break;
          }
          case 'ball':
          case 'DrawResult': {
            break;
          }
          case 'new':
          case 'StartEvent': {
            dispatch('setBettingDisabled', false);
            break;
          }
        }
        break;
      case 'new':
      case 'StartEvent': {
        dispatch('setBettingDisabled', false);
        dispatch('setEventName', eventName);
        dispatch('setEventName', eventName);
        dispatch('updateBetEvent', { game: state.name, roundNumber: +getters.eventDisplayId + 1 }, { root: true });
        break;
      }
      case 'results':
      case 'SetResults': {
        dispatch('setEventName', eventName);
        break;
      }
      case 'countdown':
      case 'StartCountdown': {
        dispatch('setEventName', eventName);
        countdownTime = eventData.delay;
        dispatch('setEventDisplayId', eventId);
        dispatch('setEventTime', countdownTime);
        dispatch('setCountdownInterval');
        break;
      }
      case 'ball':
      case 'DrawResult': {
        break;
      }
      case 'bettingDisabled':
      case 'StopBetting': {
        dispatch('setEventName', eventName);
        dispatch('setBettingDisabled', true);
        break;
      }
      case 'TicketUpdate': {
        if (!eventData.code && !eventData.httpCode) {
          ticketCheck.stopTicketChecker(eventData);
          // TODO: Refactor for serbian taxId which will be on ticket level
          // TODO: IMPLMENENT BET GUIDS
          // if (data.ticket.bets[0].additionalDetails?.taxId) await dispatch('mapBetGuids', data);
          // dispatch('betslip/disablePayinButton', false, { root: true });

          // Send message over GGateway event 'Tickets.Update' to update state of ticket on platform
          const ticketData = {
            action: capitalizeFirstLetter(eventData.action), // available values: Add, Cancel, Payout
            ticket: eventData,
          };
          eventBus.$emit('ticketUpdate', ticketData);
          // CHECK IF THE TICKET SHOULD BE PRINTED
          if (
            rootState.ticketChecksInProgress.some(
              (ticket) => ticket.id === eventData.id || ticket.requestUuid === eventData.requestUuid,
            )
          ) {
            commit('REMOVE_TICKET_CHECK_IN_PROGRESS', eventData.id, { root: true });
          } else if (rootState.printedTicketsByTicketCheck.some((ticket) => ticket.id === eventData.id)) {
            commit('REMOVE_PRINTED_TICKET_BY_TICKET_CHECK', eventData.id, { root: true });
          } else {
            eventBus.$emit('printTemplate', ticketData);
          }
        } else {
          errorTicketHandler(eventData);
          ticketCheck.stopTicketChecker({ requestId: eventData.requestUuid });
        }
        break;
      }
      case 'game.deactivated':
        dispatch('setConnectionStatus', false);
        break;
      case 'game.activated':
        dispatch('setConnectionStatus', true);
        break;
    }
  },
  async handleGameShortcuts({ state, dispatch, getters, rootGetters }, payload) {
    const { bettingInputs, additionalBettingInputs } = state.localGameConfig;
    const { defaultShortcut, quickBetslipModeAllowed, quickBetslipModeShortcut } = getters;
    // TOGGLE QUICK BETSLIP MODE
    if (
      quickBetslipModeAllowed &&
      !payload.ctrlKey &&
      payload.key.toLowerCase() === quickBetslipModeShortcut.shortcut.toLowerCase()
    ) {
      dispatch('toggleQuickBetslipMode');
    }
    // FIND IF A THE GAME HAS A BET WITH THE SHORTCUT THAT IS CALLED
    const newBet = bettingInputs.filter(
      (bettingInput) =>
        bettingInput.shortcut === String(payload.key.trim()).toLowerCase() && !payload.ctrlKey && bettingInput.enabled,
    );
    if (newBet.length) {
      const bet = newBet[0];
      // Client ids of bets that support keeping valid input on change
      const supportedClientIds = [0, 1, 2, 4, 5];
      const resetRequired =
        !getters.keepValidInputOnBetChange ||
        (!supportedClientIds.includes(getters.selectedBet.clientId) && getters.selectedBet.clientId !== 3) ||
        !supportedClientIds.includes(bet.clientId) ||
        (lastShortcutPressed === defaultShortcut.shortcut && payload.key === defaultShortcut.shortcut);

      // To reset when switching from bet which does not support keeping input
      if (resetRequired) {
        await dispatch('resetBetInputValue');
      }

      await dispatch('setSelectedBet', bet);
      await dispatch('clearAdditionalInputs');

      // Handle random if betting is enabled
      if (bet.id === 1 && !getters.isBettingDisabled) {
        eventBus.$emit('resetInput');
        await dispatch('setRandomSelection', 6);
        setTimeout(() => eventBus.$emit('focusLastBettingInput'), 0);
      } else if (!rootGetters.editBetModeActive) {
        // TODO THIS WAS DONE TO LAST SECOND TO FIX A BUG WITH FOCUS ON SYSTEM BETS
        if (bet.betType === 'system') {
          eventBus.$emit('focusLastBettingInput');
        } else {
          eventBus.$emit('focusFirstBettingInput');
        }
        // Shortcut pressed and focus should be moved to the last available and valid input
      }
    }
    // FIND IF THERE ARE ANY ADDITIONAL INPUTS THAT SHOULD BE SHOWN ON SHORTCUT ( Future, system )
    const additionalInput = additionalBettingInputs.filter(
      (bettingInput) =>
        bettingInput.shortcut === String(payload.key.trim()).toLowerCase() && bettingInput.enabled && !payload.ctrlKey,
    );
    const isAdditionalInputAllowed =
      additionalInput[0] && getters.selectedBet.additionalInputs?.includes(additionalInput[0].clientId);
    if (additionalInput.length && isAdditionalInputAllowed && !getters.isBettingDisabled) {
      // disableFutureIfMultiticket: forbid future if multiticket
      if (getters.disableFutureIfMultiticket) {
        // Blur focused input to prevent value from being deleted
        document.activeElement.blur();
        const isValid = await dispatch('handleFutureBetsOnMultiticket', null, { root: true });
        if (isValid) {
          dispatch('setAdditionalInput', additionalInput[0]);
        }
      } else {
        dispatch('setAdditionalInput', additionalInput[0]);
      }
    }
    // Check if there are any outcome shortcuts
    if (getters.selectedBet.outcomeShortcuts) {
      await dispatch('handleOutcomeShortcuts', { data: payload });
    }
    lastShortcutPressed = payload.key.toLowerCase();
  },
  async handleOutcomeShortcuts({ getters, dispatch }, payload) {
    const { key } = payload.data;
    const { outcomeShortcuts } = getters.selectedBet;
    const outcomeShortcut = outcomeShortcuts.filter((shortcuts) => shortcuts.shortcut === key)[0];
    const { quickBetslipModeActive, isBettingDisabled, colorBetSelectionOnlyAction } = getters;
    // Outcome shortcuts shouldn't be activated on any additional input fields
    const shortcutClasses = ['input-field', 'shortcut', 'games-client-shop'];
    const activeElementClasses = Array.from(document.activeElement.classList);
    const activateShortcut =
      shortcutClasses.some((item) => activeElementClasses.includes(item)) && !activeElementClasses.includes('future');
    if (!outcomeShortcut || isBettingDisabled || !activateShortcut) return;
    // activate these shortcuts only if future is not focused
    if (outcomeShortcut?.action?.name === 'random') {
      document.activeElement.blur();
      await dispatch('setRandomSelection', outcomeShortcut?.action?.value);
      //* If bet isn't added to the betslip focus should be moved to the last input
      if (!quickBetslipModeActive) {
        setTimeout(() => {
          eventBus.$emit('focusLastBettingInput');
        }, 0);
      }
    } else if (outcomeShortcut?.action?.name === 'color' && colorBetSelectionOnlyAction) {
      if (outcomeShortcut.action.value === 9) return;
      // const defaultShortcut = rootGetters.getGameGetter({ getter: 'defaultShortcut' });
      // dispatch(
      //   'runAction',
      //   {
      //     action: 'handleGameShortcuts',
      //     payload: { key: defaultShortcut?.shortcut },
      //   },
      //   { root: true },
      // );
      await dispatch('handleColorBetSelectionOnly', outcomeShortcut.action.value);
    } else if (quickBetslipModeActive) {
      dispatch('setSingleBetInputValue', { inputId: 1, inputValue: outcomeShortcut.shortcut });
    }
    if (!quickBetslipModeActive) return;

    setTimeout(async () => {
      if (outcomeShortcut?.action?.name === 'color' && colorBetSelectionOnlyAction) return;
      await dispatch('addToBetslip', null, { root: true });
      eventBus.$emit('focusTotalPayment');
    }, 0);
  },
  handleColorBetSelectionOnly({ dispatch }, colorValue) {
    const colorBet = betslipUtility.createColorBet(colorValue).split(' ');
    const betSelection = {};
    for (let i = 0; i < colorBet.length; i += 1) {
      betSelection[i + 1] = colorBet[i];
    }
    dispatch('setBetInputValue', betSelection);
  },
  toggleQuickBetslipMode({ commit }) {
    commit(types.TOGGLE_QUICK_BETSLIP_MODE);
  },
  updateFuture({ commit }, value) {
    commit(types.UPDATE_FUTURE, value);
  },
  setRandomSelection({ dispatch }, numberOfSelections) {
    const numbersRange = range(1, 49);
    const numbersShuffle = shuffle(numbersRange);
    const numbersSample = sampleSize(numbersShuffle, numberOfSelections);
    const betSelection = {};
    const sortedNumSample = numbersSample.sort((a, b) => a - b);
    for (let i = 0; i < sortedNumSample.length; i += 1) {
      betSelection[i + 1] = String(sortedNumSample[i]);
    }
    dispatch('setBetInputValue', betSelection);
  },
  updateBetslipTicket({ commit }, payload) {
    commit(types.UPDATE_BETSLIP_TICKET, payload.roundNumber);
  },
  setBettingPreviewOdds({ state }) {
    const bets = Object.values(state.config.bets);
    const { bettingInputs } = state.localGameConfig;

    for (let i = 0; i < bets.length; i++) {
      const bet = bets[i];

      const bettingInput = bettingInputs.find((input) => input.betId === bet.id);

      if (!bettingInput) continue;

      let { betDisplayName, betType } = bettingInput;
      let outcomes = [];
      let betLimit;

      switch (bet.id) {
        case 6:
        case 8:
        case 7:
        case 11:
          if (bet.limit) betLimit = bet.limit;
          outcomes = [
            { outcome: bet.outcomes[0], odd: bet.odds, shortcut: '1' },
            { outcome: bet.outcomes[1], odd: bet.odds, shortcut: '2' },
          ];
          break;
        default:
          continue;
      }

      bettingInput.bettingPreview = {
        betDisplayName,
        betType,
        outcomes,
        betLimit,
      };
    }
  },
  async handleTicketsCheckEvent({ dispatch, getters, rootState }, payload) {
    if (payload.data?.ticket?.bets) {
      // MAP BOTH TICKETS
      dispatch('showTicketPreview', { ticket: payload.data?.ticket, ticketVersion: '1' }, { root: true });
      busService.sendMessage('UI.Hide', { name: ['All'] });
    } else {
      // 'slave' (new) strategy for ticket check
      const ticketId = payload.data?.ticket?.id;
      const { ticketType } = getters.localGameConfig;
      const ticketCheckTicket = await ticketAPI
        .checkTicketBarcode(ticketId, ticketType)
        .then((response) => response)
        .catch((error) => {
          errorTicketHandler(error);
        });
      // add ticket pin if it exist
      if (isNil(ticketCheckTicket)) return;
      ticketCheckTicket.ticketPin = payload.data?.ticket.ticketPin ? payload.data?.ticket.ticketPin : null;
      if (
        (ticketCheckTicket.status.value.toLowerCase() === 'won' ||
          ticketCheckTicket.superBonus?.status?.value.toLowerCase() === 'won') &&
        rootState.autoPayout
      ) {
        const action = 'Tickets.Payout';
        const ticketData = {
          action,
          data: {
            ticket: ticketCheckTicket,
          },
        };
        busService.sendMessageAsync(ticketData.action, ticketData.data);
      } else {
        dispatch('showTicketPreview', { ticket: ticketCheckTicket, ticketVersion: '1' }, { root: true });
        busService.sendMessage('UI.Hide', { name: ['All'] });
      }
    }
    // Checked the ticket and forwards ticket data to product ticket preview (popup)
  },
  rebetTicket({ dispatch }, bets) {
    const filteredBets = betslipUtility.groupFutureBets(cloneDeep(bets));
    const rebetBetsInputArray = betslipUtility.mapBetsFromBackendForCreateBet(filteredBets);

    rebetBetsInputArray.forEach((data) => {
      const bets = betslipUtility.createBet(data);
      for (let i = 0; i < bets.length; i++) {
        dispatch('gamesBetslip/addBet', bets[i], { root: true });
      }
    });
  },
  async runMacroShortcut({ state, dispatch, rootGetters, getters }) {
    const defaultShortcut = rootGetters.getGameGetter({ getter: 'defaultShortcut' });
    const input = rootGetters.getGameGetter({ getter: 'betInputData', specificGame: 'luckysix' }).value[1];
    const betInputData = getMacroShortcut(input);
    if (!betInputData) {
      dispatch(
        'sendGGMessage',
        { message: rootGetters.getTranslation('invalid_shortcut'), delay: 3000 },
        { root: true },
      );
      return;
    }
    // Check if it's allowed to play that bet
    // System bets have multiple ids
    const { bettingInputs } = state.localGameConfig;
    const betId = betInputData.betType === 'system' ? 1 : betInputData.betId;
    const bettingInput = bettingInputs.filter((bettingInput) => bettingInput.betId === betId)[0];

    if (betInputData && bettingInput && bettingInput.enabled) {
      const bets = betslipUtility.createBet(betInputData);
      for (let i = 0; i < bets.length; i++) {
        await dispatch('gamesBetslip/addBet', bets[i], { root: true });
      }
      await dispatch('handleGameShortcuts', { key: defaultShortcut?.shortcut });
      if (getters.quickBetslipModeActive) {
        eventBus.$emit('focusTotalPayment');
      }
    } else {
      dispatch(
        'sendGGMessage',
        { message: rootGetters.getTranslation('invalid_shortcut'), delay: 3000 },
        { root: true },
      );
    }
  },
  /**
   * Check if system and random system bet views should be disabled
   * System bets have only one view and 4 bet ids.
   * If any one of the system types is enabled the view should be enabled.
   * Only in the case that every bet is disabled the bet view should be disabled.
   */
  checkSystemBetConfig({ state }) {
    const { bettingInputs } = state.localGameConfig;
    const systemBettingInput = bettingInputs.filter((bettingInput) => bettingInput.clientId === 4)[0];
    const randomSystemBettingInput = bettingInputs.filter((bettingInput) => bettingInput.clientId === 5)[0];
    const { bets } = state.config;
    const systembetIds = [1, 2, 3, 4];
    const systemBets = [];
    let systemBetsEnabled = false;

    systembetIds.forEach((id) => {
      systemBets.push(bets[id]);
    });

    systemBets.forEach((systemBet) => {
      if (systemBet.value) {
        systemBetsEnabled = true;
        return;
      }
    });

    systemBettingInput.enabled = systemBetsEnabled;
    randomSystemBettingInput.enabled = systemBetsEnabled;
  },
};
