export default {
  name: 'crashcash',
  upcomingEvents: [],
  connectionReconnectActive: false,
  editBetModeFeatureAllowed: false,
  // Available values on 7platform: Add, Cancel, Payout
  ticketActionMap: {
    PayIn: 'Add',
  },
};
