import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';
import greyhoundRacesModule from '../greyhoundraces';

export default {
  namespaced: true,
  state: { ...greyhoundRacesModule.state, ...state },
  getters: { ...greyhoundRacesModule.getters, ...getters },
  actions: { ...greyhoundRacesModule.actions, ...actions },
  mutations: { ...greyhoundRacesModule.mutations, ...mutations },
};
